import React, { useState, useEffect } from 'react'
import './VisualProgram.css'
import { Link, useHistory } from 'react-router-dom'
import cacheAssets from '../../../utils/cacheAssets'
import packageJson from '../../../../package.json'
import renderPrgImage from '../../../source/programImg'
import VisualPrgm from '../../ReusableComponents/PrgmSlider/VisualPrgm/VisualPrgm'
import MainHeader from '../../ReusableComponents/Header/MainHeader'
import { generateDeviceDataKey } from '../../ReusableComponents/ByteTransfer/utils'

const blockBaseLink = {
    ACE0: 'https://blockbased.plode.org',
    ACE1: 'https://blockbased.plode.org/?pc1.0',
    HUMANOID0: 'https://blockbased.plode.org/?zing0.1',
    HUMANOID1: 'https://blockbased.plode.org/?zing1.0',
    HUMANOID2: 'https://blockbased.plode.org/?zing1.0s3',
    HEXAPOD1: 'https://blockbased.plode.org/?hexapod',
    HEXAPOD2: 'https://blockbased.plode.org/?hexapod1.0s3',
    ROBOKI1: 'https://blockbased.plode.org/?roboki',
}

function VisualProgram() {
    const version = sessionStorage.getItem('deviceVersion')
    const device = sessionStorage.getItem('connectedDevice')
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const item1Styl = {
        backgroundImage: `url("${renderPrgImage(
            'flowchartbasedgroupbutton'
        )}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'all',
        opacity: ['Hexapod', 'Roboki', 'Klaw'].includes(device) ? 0.5 : 1,

        // cursor: ['Hexapod', 'Roboki'].includes(device) ? 'default' : 'pointer',
    }
    const item2Styl = {
        backgroundImage: `url("${renderPrgImage('projectbased')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    }
    const item3Styl = {
        backgroundImage: `url("${renderPrgImage('blockbased')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        opacity: ['Klaw'].includes(device)
            ? //  ||`${device}` === '0.5'
              0.5
            : 1,
    }
    const item4Styl = {
        backgroundImage: `url("${renderPrgImage('Cgroupbutton')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        /* The above code is setting the value of the CSS `cursor` property based on the conditions
       specified. If the value of the `version` variable is not equal to '1.0.03' or the value of the
       `device` variable is 'Humanoid', then the cursor will be set to 'not-allowed'. Otherwise, the
       cursor will be set to 'pointer'. */
        cursor:
            !version?.startsWith('1') ||
            device === 'Humanoid' ||
            device === 'Roboki'
                ? 'not-allowed'
                : 'pointer',
        pointerEvents:
            !version?.startsWith('1') ||
            device === 'Humanoid' ||
            device === 'Roboki'
                ? 'none'
                : 'all',
        opacity:
            ['Hexapod', 'Humanoid', 'Roboki', 'Klaw'].includes(device) ||
            !version?.startsWith('1')
                ? '0.5'
                : '1',
        // cursor: 'pointer',
    }
    const item5Styl = {
        backgroundImage: `url("${renderPrgImage('pythoncodingbutton')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        cursor:
            device === 'Humanoid' ||
            (device === 'Ace' &&
                (version.startsWith('1.') || version[0] == '0'))
                ? 'pointer'
                : 'not-allowed',
        pointerEvents:
            device === 'Humanoid' ||
            (device === 'Ace' &&
                (version.startsWith('1.') || version[0] == '0'))
                ? 'all'
                : 'none',
        opacity:
            device === 'Humanoid' ||
            (device === 'Ace' &&
                (version.startsWith('1.') || version[0] == '0'))
                ? 1
                : 0.5,
    }
    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }
    function blockbasedbtn() {
        if (['Klaw'].includes(device)) return
        sessionStorage.removeItem('slideDirection')

        const blockBaseLinkKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersion
        )

        window.location.assign(blockBaseLink[blockBaseLinkKey])
        // if (device === 'Humanoid') {
        //     if (
        //         ['0.1.1', '0.1.10'].includes(
        //             sessionStorage.getItem('deviceVersion')
        //         ) &&
        //         ['Humanoid'].includes(device)
        //     ) {
        //         window.location.assign('https://blockbased.plode.org/?zing0.1')
        //     } else {
        //         window.location.assign('https://blockbased.plode.org/?zing1.0')
        //     }
        // } else {
        //     // if (['1.0.0', '1.0.03'].includes(version))
        //     if (version[0] === '1')
        //         window.location.assign('https://blockbased.plode.org/?pc1.0')
        //     else window.location.assign('https://blockbased.plode.org')
        // }
    }

    const downloadSoftware = () => {
        sessionStorage.removeItem('slideDirection')
        const download = setTimeout(() => {
            if (
                JSON.parse(sessionStorage.getItem('isWindowOnFocus')) === true
            ) {
                const a = document.createElement('a')
                a.href = 'https://downloads.plode.org/zingFirmwareTool.exe'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                clearTimeout(download)
            }
        }, 500)
    }

    const history = useHistory()

    // Caching Internal Components assets

    useEffect(() => {
        let assetsToBeCached = [
            renderPrgImage('newfilegroupbutton'),
            renderPrgImage('yourprojectsgroupbutton'),
            renderPrgImage('newactiongroupbutton'),
            renderPrgImage('youractiongroupbutton'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const handlePythonPopup = () => {
        let ele = document.getElementById('popup-text')
        ele.className = 'show'
        console.log('python click', ele)
        setTimeout(function () {
            ele.className = ele.className.replace('show', '')
        }, 3000)
    }

    let mycroPythonLink =
        sessionStorage.getItem('connectedDevice') === 'Ace' &&
        version?.startsWith('1.')
            ? 'https://edu.plode.org/python/?device=peecee'
            : 'https://edu.plode.org/python/?device=zing'
    return (
        <>
            <div className="visualProgram-container">
                {/* <img
                    src={renderPrgImage('backBtn')}
                    className="iconBtnSize VP-backbtn"
                    onClick={() => {
                        history.push('/Selection')
                    }}
                />
                {isHelp == false ? (
                    <img
                        className="iconBtnSize helpiconBtnSize"
                        src={renderPrgImage('helpBtnInActive')}
                        style={{ marginRight: '25%' }}
                        onClick={handleHelpBtn}
                    ></img>
                ) : (
                    <div className="S_slide">
                        {' '}
                        <VisualPrgm />{' '}
                    </div>
                )}
                {isHelp ? (
                    <img
                        className="helpClose"
                        src={renderPrgImage('closBtn')}
                        onClick={handleHelpBtn}
                    ></img>
                ) : null}

                <img
                    src={renderPrgImage('programmenucard')}
                    className="VP-programmenucard"
                /> */}
                <MainHeader
                    title="Code"
                    titlePosition="left"
                    help_btnPosition="right"
                    helper={<VisualPrgm />}
                    isGoBack={false}
                    goBack={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/selection')
                    }}
                />
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    } `}
                    style={{ position: 'relative' }}
                >
                    <p className="VP-txt-Heading"> Visual Programming</p>

                    <hr className="VP-hr" />

                    <p className="VP-txt-Heading2"> Script Programming</p>

                    <hr className="VP-hr2" />
                    <div>
                        {/* ITEM - 1 */}

                        {/* <Link to="/flow"> */}
                        <div
                            className="VP-flowchartbased vp-item1"
                            style={item1Styl}
                            onClick={() => {
                                if (
                                    ['Hexapod', 'Roboki', 'Klaw'].includes(
                                        device
                                    )
                                )
                                    return
                                sessionStorage.removeItem('slideDirection')
                                sessionStorage.setItem(
                                    'programMode',
                                    'flowchartBased'
                                )
                                history.push('/flow')
                                window.location.reload()
                            }}
                        >
                            <div
                                className="VP-sub1"
                                style={{
                                    cursor: [
                                        'Hexapod',
                                        'Roboki',
                                        'Klaw',
                                    ].includes(device)
                                        ? 'default'
                                        : 'pointer',
                                }}
                            >
                                <p className="VP-sub-txt">
                                    Flowchart <br />
                                    Based
                                </p>
                            </div>
                        </div>
                        {/* </Link> */}

                        {/* ITEM - 2 */}
                        <div
                            // to="/code/project"

                            onClick={() => {
                                sessionStorage.removeItem('slideDirection')
                                sessionStorage.setItem(
                                    'programMode',
                                    'projectBased'
                                )
                                history.push('/code/project')
                                window.location.reload()
                            }}
                        >
                            <div
                                className="VP-programbased vp-item2"
                                style={item2Styl}
                            >
                                <div
                                    className="VP-sub2"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <p className="VP-sub2-txt">
                                        Project <br />
                                        Based
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Item 3*/}

                        <div
                            className="VP-blockbased vp-item3"
                            style={item3Styl}
                            onClick={() => {
                                if (['Klaw'].includes(device)) return
                                blockbasedbtn()
                            }}
                        >
                            <div
                                className="VP-sub3"
                                style={{
                                    cursor: ['Klaw'].includes(device)
                                        ? 'default'
                                        : 'pointer',
                                }}
                            >
                                <p className="VP-sub3-txt">
                                    Block <br />
                                    Based
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            backgroundColor: '#8ACDEA',
                            opacity: '10%',
                        }}
                    ></div>
                    {/* Item 4 */}
                    <div
                        className="VP-CCoding vp-item4"
                        style={item4Styl}
                        onClick={() => {
                            if (
                                !['Hexapod', 'Klaw'].includes(connectedDevice)
                            ) {
                                sessionStorage.removeItem('slideDirection')
                                window.location.assign(
                                    'https://edu.plode.org/c'
                                )
                            }
                        }}
                    >
                        <div className="VP-sub4">
                            <p className="VP-sub4-txt">
                                C <br />
                                Coding
                            </p>
                        </div>
                    </div>

                    {/*Item 5 */}
                    <>
                        {sessionStorage.getItem('connectedDevice') ===
                            'Humanoid' ||
                        (device === 'Ace' && version?.startsWith('1')) ? (
                            <a
                                href
                                onClick={() => {
                                    sessionStorage.removeItem('slideDirection')
                                    window.location.assign(mycroPythonLink)
                                }}
                                target="_self"
                            >
                                <div
                                    className="VP-pythoncodingbutton vp-item5"
                                    style={item5Styl}
                                >
                                    <div className="VP-sub5">
                                        <p className="VP-sub5-txt">
                                            Python <br />
                                            Coding
                                        </p>
                                    </div>
                                </div>
                            </a>
                        ) : (
                            <div
                                className="VP-pythoncodingbutton vp-item5"
                                style={item5Styl}
                                onClick={() => {
                                    if (
                                        !['Hexapod', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                    ) {
                                        sessionStorage.removeItem(
                                            'slideDirection'
                                        )
                                        window.location.assign(
                                            'http://micropython.plode.org'
                                        )
                                    }
                                }}
                            >
                                <div
                                    className="VP-sub5"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <p className="VP-sub5-txt">
                                        Python <br />
                                        Coding
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </div>
            <div id="popup-text">
                update the PeeCee firmware to 1.0.04 version to enable python
                coding
            </div>
        </>
    )
}

export default VisualProgram
