import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import unicodeToChar from '../../../../utils/unicodeToChar'
import renderImage from '../../../../source/importImg'
import MuscSlider from '../../../ReusableComponents/MuscSlider/MuscSlider'
import MainHeader from '../../../ReusableComponents/Header/MainHeader'
import './pianoo.css'
import { sendBytes } from '../../../ReusableComponents/ByteTransfer'
import cacheAssets from '../../../../utils/cacheAssets'
let count = 0

function Music(props) {
    let history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const [isPianoKey, setPianoKey] = useState(true)
    const [isPcPiano, setPcPiano] = useState(false)
    const [loop, setLoop] = useState(true)

    const devices = {
        Humanoid: {
            htmlc: <>Zing </>,
            name: 'Zing',
            image: 'Zing',
            style: {
                top: '50%',
                left: '70%',
            },
            style2: {},
        },
        Hexapod: {
            htmlc: <>Crawle </>,
            name: 'Crawle',
            image: 'Hexapod',
            style: {
                top: '50%',
                left: '70%',
            },
            style2: {
                width: '40%',
                top: '50%',
                left: '45%',
            },
        },
        Klaw: {
            htmlc: <>Klaw </>,
            name: 'Klaw',
            image: 'Klaw',
            style: {
                width: '25%',
            },
            style2: {},
        },
        Ace: {
            htmlc: deviceVersion?.startsWith('1') ? (
                <>PeeCee Beta </>
            ) : (
                <>PeeCee Alpha </>
            ),
            name: deviceVersion?.startsWith('1')
                ? 'PeeCee Beta'
                : 'PeeCee Alpha',
            image: deviceVersion?.startsWith('1') ? 'PCv1' : 'PC',
            style: deviceVersion?.startsWith('1')
                ? { top: '50%', left: '70%' }
                : {},
            style2: {},
        },
    }
    const M1 = () => {
        let data = ['M'.charCodeAt(), '0'.charCodeAt()]
        writePort(data)
    }
    const M2 = () => {
        let data = ['M'.charCodeAt(), '1'.charCodeAt()]
        writePort(data)
    }
    const M3 = () => {
        let data = ['M'.charCodeAt(), '2'.charCodeAt()]
        writePort(data)
    }
    const M4 = () => {
        let data = ['M'.charCodeAt(), '3'.charCodeAt()]
        writePort(data)
    }
    const M5 = () => {
        let data = ['M'.charCodeAt(), '4'.charCodeAt()]
        writePort(data)
    }
    const M6 = () => {
        let data = ['M'.charCodeAt(), '5'.charCodeAt()]
        writePort(data)
    }
    const M7 = () => {
        let data = ['M'.charCodeAt(), '6'.charCodeAt()]
        writePort(data)
    }
    const M8 = () => {
        let data = ['M'.charCodeAt(), '7'.charCodeAt()]
        writePort(data)
    }

    ////////////////Do not chnage the position of the useEffect //////////
    useEffect(() => {
        if (!props.webSerial.isConnected) {
            sessionStorage.removeItem('exitPiano')
        }
    }, [props.webSerial.isConnected])
    //////////////////////////////////////////////////////////////////////

    useEffect(() => {
        if (
            sessionStorage.getItem('isDeviceConnected') === 'true' &&
            count === 0
        ) {
            sendPlayBytes()
            count = count + 1
        }
        if (sessionStorage.getItem('isDeviceConnected') === 'false') {
            count = 0
        }
    })

    const sendPlayBytes = () => {
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
        const playBytes = setTimeout(() => {
            writePort(PLAY)
            clearTimeout(playBytes)
        }, 500)
    }

    const handlePcPiano = (e) => {
        if (isPcPiano) {
            setPcPiano(!isPcPiano)
            setPianoKey(!isPianoKey)
        } else {
            setPcPiano(!isPcPiano)
            setPianoKey(!isPianoKey)
        }
        var piano = { isPianoKey: isPianoKey, isPcPiano: isPcPiano }
        sessionStorage.setItem('piano', JSON.stringify(piano))
        sessionStorage.setItem('exitPiano', true)
        if (piano.isPianoKey == false) {
            sessionStorage.removeItem('exitPiano')
        }
    }
    const playSound = async (tone) => {
        try {
            const audio = new Audio(`${renderImage(`${tone}`)}`)
            audio.play()
        } catch (e) {
            console.log('piano error', e)
        }
    }
    async function readLoop() {
        if (connectedDevice == 'Ace') {
            props.worker.onmessage = async (e) => {
                let play
                if (deviceVersion[0] == 1) play = e.data.value.slice(1, 6)
                else if (deviceVersion[0] == 0) play = e.data.value.slice(0, 5)
                console.log(play)
                if (
                    e.data.type === 'read' &&
                    play.includes('K') &&
                    play.length === 5
                ) {
                    let tone = ''
                    switch (play) {
                        case 'K0001':
                            tone = 'AudioC'
                            break
                        case 'K0010':
                            tone = 'AudioD'

                            break
                        case 'K0011':
                            tone = 'AudioE'

                            break
                        case 'K0100':
                            tone = 'AudioF'
                            break
                        case 'K0101':
                            tone = 'AudioG'
                            break
                        case 'K0110':
                            tone = 'AudioA'
                            break
                        case 'K0111':
                            tone = 'AudioB'
                            break
                        case 'K1000':
                            tone = 'AudioC'
                            break
                        case 'K1001':
                            tone = 'AudioD'
                            break
                        case 'K1010':
                            tone = 'AudioE'
                            break
                        case 'K1011':
                            tone = 'AudioF'
                            break
                        case 'K1100':
                            tone = 'AudioG'
                            break
                        case 'K1101':
                            tone = 'AudioA'
                            break
                        case 'K1110':
                            tone = 'AudioB'
                            break
                        case 'K1111':
                            tone = 'AudioC'
                            break
                    }
                    if (tone != '') await playSound(tone)
                }
            }
            while (1) {
                var Exitpiano = JSON.parse(sessionStorage.getItem('exitPiano'))
                if (Exitpiano === null) break
                props.worker.postMessage({
                    type: 'write',
                    value: 'KP',
                })
                await new Promise((resolve) => setTimeout(resolve, 300))
            }
            return
        }
        const port = props.webSerial.port
        let portReader
        try {
            if (
                !(
                    deviceVersion?.startsWith('1') &&
                    ['Ace'].includes(connectedDevice)
                )
            ) {
                portReader = port.readable.getReader()
            }
            while (loop) {
                var Exitpiano = JSON.parse(sessionStorage.getItem('exitPiano'))
                if (Exitpiano === null) break
                const pianoBytes = async () => {
                    // if (deviceVersion?.startsWith('1')) {
                    //     let data = ['K'.charCodeAt(), 'P'.charCodeAt()]
                    //     const SendBytes = new Uint8Array(data)
                    //     await port.transferOut(2, SendBytes)
                    //     const recievedBytes = await port
                    //         .transferIn(2, 64)
                    //         .then((value) => {
                    //             let int8array = new Uint8Array(
                    //                 value.data.buffer
                    //             )
                    //             let result = Array.from(int8array)
                    //             console.log(result, 'gsk piano')
                    //             return result
                    //         })
                    //     return recievedBytes
                    // } else {
                    let data = ['K'.charCodeAt(), 'P'.charCodeAt()]
                    await sendBytes(data, props.webSerial.port)
                    let bytes = await portReader.read()
                    return bytes.value
                    // }
                }
                const value = await pianoBytes()
                // console.log('gsk enter2', value, piano)
                let str
                if (deviceVersion) {
                    if (
                        !(
                            deviceVersion?.startsWith('1') &&
                            ['Ace'].includes(connectedDevice)
                        )
                    ) {
                        const strg = unicodeToChar(value)
                        str = strg.trim()
                    } else {
                        str = unicodeToChar(value)
                    }
                    if (str.length === 5 && str != 'K0000') {
                        try {
                            var audio = new Audio(`${renderImage(`${str}`)}`)
                            audio.play()
                            await new Promise((resolve) =>
                                setTimeout(resolve, 200)
                            )
                        } catch (e) {}
                    }
                    console.log('gsk enter3', str, str.length)
                    continue
                }
                console.log('gsk enter4')
                if (
                    sessionStorage.getItem('pcPianoCounter') ==
                    ('45' || undefined || null)
                ) {
                    sessionStorage.setItem('pcPianoCounter', '0')
                }
                if (str.includes('K')) {
                    var ctr =
                        parseInt(sessionStorage.getItem('pcPianoCounter')) + 1
                    sessionStorage.setItem('pcPianoCounter', ctr)
                }
                if (parseInt(sessionStorage.getItem('pcPianoCounter')) == 45) {
                    let data = ['K'.charCodeAt(), 'P'.charCodeAt()]
                    writePort(data)
                }
                if (str == sessionStorage.getItem('prevPianoStr')) {
                } else if (str === 'K494848') {
                    var audio = new Audio(`${renderImage('AudioC')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K484948') {
                    var audio = new Audio(`${renderImage('AudioD')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K484849') {
                    var audio = new Audio(`${renderImage('AudioE')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K494948') {
                    var audio = new Audio(`${renderImage('AudioF')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K484949') {
                    var audio = new Audio(`${renderImage('AudioG')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K494849') {
                    var audio = new Audio(`${renderImage('AudioA')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K494949') {
                    var audio = new Audio(`${renderImage('AudioB')}`)
                    audio.play()
                    sessionStorage.setItem('prevPianoStr', str)
                } else if (str === 'K484848') {
                    sessionStorage.setItem('prevPianoStr', str)
                } else {
                }
            }
            if (
                !(
                    deviceVersion?.startsWith('1') &&
                    ['Ace'].includes(connectedDevice)
                )
            )
                portReader.releaseLock()
        } catch (e) {
            console.log(e)
        }
    }
    console.log('ffcffgfg', devices[connectedDevice])
    //on back button click zing go to default or attention postion
    const setZingDefaultPostion = () => {
        let data = ['R'.charCodeAt(0), 'a'.charCodeAt(0)] //ATTENTION byet code
        writePort(data)
    }

    async function writePort(data) {
        if (props.webSerial.port !== undefined) {
            // if (
            //     (connectedDevice == 'Ace' &&
            //         deviceVersion?.includes('1.0.0')) ||
            //     deviceVersion?.includes('2.0.0')
            // ) {
            if (
                props.webSerial.isConnected &&
                props.webSerial.port !== undefined
            ) {
                props.worker.postMessage({
                    type: 'writeArray',
                    value: data,
                })
            }
            // } else {
            //     await sendBytes(data, props.webSerial.port)
            // }
        }
    }

    async function hi() {
        if (isPcPiano) {
            sessionStorage.setItem('pcPianoCounter', '0')
            await readLoop()
        }
    }
    useEffect(() => {
        hi()
    }, [isPcPiano])

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderImage('MusicH1'),
            renderImage('MusicH2'),
            renderImage('MusicH3'),
            renderImage('MusicH4'),
            renderImage('Musc1'),
            renderImage('Musc2'),
            renderImage('Musc3'),
            renderImage('Musc4'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <div className="Music-Main">
            <MainHeader
                title="Music"
                helper={<MuscSlider />}
                showBluetoothBtn={true}
                goBack={() => {
                    if (['Humanoid', 'Hexapod'].includes(connectedDevice)) {
                        setZingDefaultPostion()
                    }
                    sessionStorage.setItem('slideDirection', true)
                    setLoop(false)
                    history.push('/introduction')
                    sessionStorage.removeItem('piano')
                    sessionStorage.removeItem('exitPiano')
                }}
                isGoBack={true}
            />
            <div className="Music_Body">
                <div className="Piano" style={{ position: 'relative' }}>
                    {isPianoKey == false ? null : (
                        <div className="Piano_p">
                            <div className=" a white a li" onClick={M1}></div>
                            <div className="black as li"></div>
                            <div className="white b li" onClick={M2}></div>
                            <div className="black bs li"></div>
                            <div className="white c li" onClick={M3}></div>

                            <div className="white d li" onClick={M4}></div>
                            <div className="black ds li"></div>
                            <div className="white e li" onClick={M5}></div>
                            <div className="black es li"></div>
                            <div className="white f li" onClick={M6}></div>

                            <div className="white g li" onClick={M7}></div>
                            <div className="white h li" onClick={M8}></div>
                            <div className="black hs li"></div>
                        </div>
                    )}
                    {console.log(
                        'GGGGGGGGGGGG',
                        devices[connectedDevice].image
                    )}

                    {isPcPiano && (
                        <img
                            className="Music_Ac"
                            style={devices[connectedDevice].style2}
                            src={renderImage(devices[connectedDevice].image)}
                            draggable="false"
                        ></img>
                    )}
                </div>
                <div className="" style={{ position: 'relative' }}>
                    {isPianoKey == false ? null : (
                        <img
                            className="Disconnected"
                            src={renderImage('Disconnected_Svg')}
                            draggable="false"
                        ></img>
                    )}
                    {isPcPiano == false ? null : (
                        <img
                            className="Disconnect"
                            src={renderImage('Disconnected_Svg')}
                            draggable="false"
                        ></img>
                    )}
                </div>
                <div className="" style={{ position: 'relative' }}>
                    {isPianoKey && (
                        <img
                            className="Music_Ace"
                            src={renderImage(devices[connectedDevice].image)}
                            style={devices[connectedDevice].style}
                            draggable="false"
                        />
                    )}

                    {isPcPiano == false ? null : (
                        <img
                            className="Piano_Small"
                            src={
                                connectedDevice == 'Ace' &&
                                deviceVersion?.startsWith('1')
                                    ? renderImage('piano_4key')
                                    : renderImage('Pianosmall_Svg')
                            }
                            draggable="false"
                        ></img>
                    )}
                </div>

                {!['Humanoid', 'Hexapod'].includes(connectedDevice) && (
                    <div className="" style={{ position: 'relative' }}>
                        <img
                            className="Pianotoggle_Bg"
                            src={renderImage('Pianotogglebg_Svg')}
                            draggable="false"
                        ></img>

                        {isPianoKey == false ? (
                            <img
                                className="Piano_Keys"
                                src={renderImage('PianokeysIA')}
                                onClick={handlePcPiano}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Piano_Keys"
                                src={renderImage('PianokeysAc')}
                                onClick={handlePcPiano}
                                draggable="false"
                            ></img>
                        )}

                        {isPcPiano == false ? (
                            <img
                                className="Pc_Piano"
                                src={renderImage('PcpianoIA')}
                                onClick={handlePcPiano}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Pc_Piano"
                                src={renderImage('PcpianoAc')}
                                onClick={handlePcPiano}
                                draggable="false"
                            ></img>
                        )}
                    </div>
                )}
            </div>
            <div style={{ position: 'relative' }}>
                {isPcPiano == false ? (
                    <h3 className="Music_Instruc">
                        Tap the keys of the piano to play music
                    </h3>
                ) : (
                    <h3 className="Music_Instruc2">
                        Tap the touch pads on Play Computer to play music
                    </h3>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Music)
