import React, { useEffect, useState } from 'react'
import usbON from '../../Assets/Bisoft_UI/Buttons/Active with shadow/usb - on@2x.png'
import usbOFF from '../../Assets/Bisoft_UI/Buttons/Active with shadow/usb - off@2x.png'
import helpY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/help@2x.png'
import save from '../../Assets/Bisoft_UI/Buttons/Inactive with shadow/save - secondary@2x.png'
import saveButton from '../../Assets/Bisoft_UI/AppMode/save.png'
import navbar from './Navbar.module.css'
import style from '../ReusableComponents/DialogModal/DialogModal.module.css'
import renderImage from '../../source/importImg'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import {
    deviceDetails,
    deviceMismatch,
    showModal,
    webSerialConnect,
} from '../../redux/actions'
import {
    connectSerialDevice,
    connectSerialDeviceIntermediate,
    connectUSBDevice,
} from '../WebSerialConnection/SerialUSBConnect'
import { useHistory } from 'react-router'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
const NavBar = (props) => {
    const [fileName, setFileName] = useState('')
    const [filePopup, setFilePopUp] = useState(false)
    const [isDeviceMismatch, setIsDeviceMismatch] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (props.device) {
            let deviceD = Object.keys(props.device.deviceMismatched)
            if (deviceD.length > 1) {
                if (isDeviceMismatch == false) {
                    setIsDeviceMismatch(true)
                }
            }
        }
    }, [props.device.deviceMismatched])
    const HdleUsb = async (e) => {
        if (!props.webSerial.isConnected) {
            let res = await connectSerialDeviceIntermediate(
                { isAutomatic: false },
                props.worker
            )

            if (res) props.webSerialConnect({ port: res.port })
            if (res !== undefined && !res.isPortOpen) {
                console.log(res.matchedObj_with_Version)
                props.deviceMismatch({
                    deviceMismatched: res.matchedObj_with_Version,
                })
                setIsDeviceMismatch(true)
            }
        }
    }
    const handleDevice = async (option) => {
        if (option === 'yes') {
            let port = props.webSerial.port
            //is this needed??
            // await openSerialReadComPort(port,worker)
        } else {
            history.push('/Selection')
            let mismatchedDevice = props.device.deviceMismatched
            sessionStorage.setItem('connectedDevice', mismatchedDevice.id)
            sessionStorage.setItem('deviceVersion', mismatchedDevice.version)
            props.deviceDetails({
                connectedDevice: mismatchedDevice.id,
                deviceVersion: mismatchedDevice.version,
                // zingVersion: '0.0.0',
            })
            // } else {
            //     sessionStorage.setItem('zingVersion', mismatchedDevice.version)
            //     props.deviceDetails({
            //         connectedDevice: mismatchedDevice.id,
            //         zingVersion: mismatchedDevice.version,
            //         deviceVersion: '0.0.0',
            //     })
            // }
        }
        setIsDeviceMismatch(false)
        props.deviceMismatch({ deviceMismatched: {} })
    }
    const saveAsFile = () => {
        const fileData = JSON.stringify(sessionStorage)
        const blob = new Blob([fileData], { type: 'text/plain' })
        if (fileName.length !== 0) saveAs(blob, `${fileName}.pld`)
    }

    const FileName = () => {
        return (
            <>
                {filePopup && (
                    <div className={style.container}>
                        <div className={style.box}>
                            <img
                                className={style.closeBtn}
                                style={{ width: '2.5rem', top: '-60px' }}
                                src={renderImage('clos')}
                                onClick={() => {
                                    setFilePopUp(false)
                                }}
                            />
                            <input
                                placeholder="Enter the file name"
                                style={{
                                    width: '15vw',
                                    height: '4.5vh',
                                    backgroundColor: 'lightgrey',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    borderRadius: '5px',
                                    border: 'none',
                                    top: '47%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                                value={fileName}
                                autoFocus={true}
                                onChange={(event) => {
                                    setFileName(event.target.value)
                                }}
                            />{' '}
                            <br></br>
                            <img
                                src={saveButton}
                                draggable="false"
                                style={{
                                    cursor: 'pointer',
                                    width: '8vw',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                                onClick={() => {
                                    setFilePopUp(false)
                                    saveAsFile()
                                }}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <DialogModal
                mismatchShow={isDeviceMismatch}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            A device mismatch found!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Would you like to continue anyway?
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() => setIsDeviceMismatch(false)}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => handleDevice('yes'),
                    },
                    {
                        text: 'NO',
                        do: () => handleDevice('no'),
                    },
                ]}
            />
            <div className={navbar.HeaderContainer}>
                <FileName />
                <div
                    className={`${navbar.appModeNavbarContainer} ${navbar.navbarContainer}`}
                >
                    <div
                        className={`${navbar.appModeNavbar_content} ${navbar.navbar_content}`}
                    >
                        {props.headers.map((header, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${navbar.appModeNavbar_new} ${navbar.navbar_new}`}
                                    style={{ color: header.color }}
                                >
                                    {header.name}
                                </div>
                            )
                        })}
                    </div>
                    <img
                        src={props.selectbar}
                        alt="selectbar"
                        style={{
                            height: '100%',
                            width: props.selectbarwidth,
                            position: 'relative',
                            right: '43vw',
                        }}
                    />
                    <div
                        style={{ height: '78%', alignItems: 'center' }}
                        className={` ${navbar.navbarAction}`}
                    >
                        {props.save ? (
                            <img
                                className={`${navbar.appModeSaveBtnSize} ${navbar.iconBtnSize}`}
                                src={save}
                                alt="help"
                                onClick={() => {
                                    setFilePopUp(true)
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        ) : (
                            <></>
                        )}

                        {props.showHelp && (
                            <div
                                className="help_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    className={`${navbar.appModeIconBtnSize} ${navbar.iconBtnSize}`}
                                    src={helpY}
                                    alt="help"
                                    onClick={props.handleHelp}
                                    style={{ cursor: 'pointer' }}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>Help</p>
                                </span>
                            </div>
                        )}
                        {props.webSerial.isConnected === true ? (
                            <div
                                className="web_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    style={{ height: '9vh', cursor: 'pointer' }}
                                    src={usbON}
                                    alt="usbOn"
                                    onClick={HdleUsb}
                                />
                                <span className="tooltiptext">
                                    {' '}
                                    <p>
                                        Device <br />
                                        connectivity
                                    </p>
                                </span>
                            </div>
                        ) : (
                            <div
                                className="web_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    style={{ height: '9vh', cursor: 'pointer' }}
                                    src={usbOFF}
                                    alt="usbOff"
                                    onClick={HdleUsb}
                                />
                                <span className="tooltiptext">
                                    {' '}
                                    <p>
                                        Device <br />
                                        connectivity
                                    </p>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
        deviceDetails: (data) => {
            dispatch(deviceDetails(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
