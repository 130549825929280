import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import Slider from '../helpers/Slider'
import SelectionRow from '../../../../../logic/panels/output/SelectionRow'
import TextRow from '../helpers/TextRow'
import renderImage from '../../../../../../source/importImg'
import './output.css'

import { height } from '@material-ui/system'

import ActionPanel from '../../../../../Humanoid/ActionPanel'

// import { rangeStoreVal } from "../../../../../Assembly/CheckboxData";

var _0to60 = {},
    _0to24 = {},
    _0to1000 = {}
for (let i = 0; i < 24; i++) _0to24[i] = i
for (let i = 0; i < 60; i++) _0to60[i] = i
for (let i = 0; i < 1000; i += 50) _0to1000[i] = i

const OutputPanel = (props) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    // laxmi
    const [humanoidPortData, setHumanoidPortData] = useState(
        JSON.parse(sessionStorage.getItem('humanoidPortData'))
    )
    const isActivehumanoidPort = () => {
        let properties = Object.values(humanoidPortData)
        let data = false
        console.log('6666666666666666666666', props)
        properties.forEach((element, index) => {
            if (element.isPortSelected == true) {
                data = true
            }
        })

        return data
    }
    const startButtonState = JSON.parse(
        sessionStorage.getItem('startButtonState')
    )

    const [bic1Val, setBic1Val] = useState(
        parseInt(sessionStorage.getItem(`bic1Val${props.check}`))
    )
    const [bic1Chk, setBic1Chk] = useState(
        sessionStorage.getItem(`bic1Chk${props.check}`) == 'true'
    )
    const [bic2Val, setBic2Val] = useState(
        parseInt(sessionStorage.getItem(`bic2Val${props.check}`))
    )
    const [bic2Chk, setBic2Chk] = useState(
        sessionStorage.getItem(`bic2Chk${props.check}`) == 'true'
    )
    const [bif1Val, setBif1Val] = useState(
        parseInt(sessionStorage.getItem(`bif1Val${props.check}`))
    )
    const [bif1Chk, setBif1Chk] = useState(
        sessionStorage.getItem(`bif1Chk${props.check}`) == 'true'
    )
    const [bif2Val, setBif2Val] = useState(
        parseInt(sessionStorage.getItem(`bif2Val${props.check}`))
    )
    const [bif2Chk, setBif2Chk] = useState(
        sessionStorage.getItem(`bif2Chk${props.check}`) == 'true'
    )
    const [usbTxVal, setUsbTxVal] = useState(
        parseInt(sessionStorage.getItem(`usbTxVal${props.check}`))
    )
    const [usbTxChk, setUsbTxChk] = useState(
        sessionStorage.getItem(`usbTxChk${props.check}`) == 'true'
    )
    const [btTx1Val, setBtTx1Val] = useState(
        parseInt(sessionStorage.getItem(`btTx1Val${props.check}`))
    )
    const [btTx1Chk, setBtTx1Chk] = useState(
        sessionStorage.getItem(`btTx1Chk${props.check}`) == 'true'
    )
    const [btTx2Val, setBtTx2Val] = useState(
        parseInt(sessionStorage.getItem(`btTx2Val${props.check}`))
    )
    const [btTx2Chk, setBtTx2Chk] = useState(
        sessionStorage.getItem(`btTx2Chk${props.check}`) == 'true'
    )
    const [btTx3Val, setBtTx3Val] = useState(
        parseInt(sessionStorage.getItem(`btTx3Val${props.check}`))
    )
    const [btTx3Chk, setBtTx3Chk] = useState(
        sessionStorage.getItem(`btTx3Chk${props.check}`) == 'true'
    )
    const [btTx4Val, setBtTx4Val] = useState(
        parseInt(sessionStorage.getItem(`btTx4Val${props.check}`))
    )
    const [btTx4Chk, setBtTx4Chk] = useState(
        sessionStorage.getItem(`btTx4Chk${props.check}`) == 'true'
    )
    const [btTx5Val, setBtTx5Val] = useState(
        parseInt(sessionStorage.getItem(`btTx5Val${props.check}`))
    )
    const [btTx5Chk, setBtTx5Chk] = useState(
        sessionStorage.getItem(`btTx5Chk${props.check}`) == 'true'
    )

    const [valA1, setvalA1] = useState(
        parseInt(sessionStorage.getItem(`a1${props.check}`)) || 0
    )
    const [valA2, setvalA2] = useState(
        parseInt(sessionStorage.getItem(`a2${props.check}`)) || 0
    )
    const [valB1, setvalB1] = useState(
        parseInt(sessionStorage.getItem(`b1${props.check}`)) || 0
    )
    const [valB2, setvalB2] = useState(
        parseInt(sessionStorage.getItem(`b2${props.check}`)) || 0
    )
    const [valC1, setvalC1] = useState(
        parseInt(sessionStorage.getItem(`c1${props.check}`)) || 0
    )
    const [valC2, setvalC2] = useState(
        parseInt(sessionStorage.getItem(`c2${props.check}`)) || 0
    )
    const [valD1, setvalD1] = useState(
        parseInt(sessionStorage.getItem(`d1${props.check}`)) || 0
    )
    const [valD2, setvalD2] = useState(
        parseInt(sessionStorage.getItem(`d2${props.check}`)) || 0
    )
    const [valE1, setvalE1] = useState(
        parseInt(sessionStorage.getItem(`e1${props.check}`)) || 0
    )
    const [valE2, setvalE2] = useState(
        parseInt(sessionStorage.getItem(`e2${props.check}`)) || 0
    )
    const [valF1, setvalF1] = useState(
        parseInt(sessionStorage.getItem(`f1${props.check}`)) || 0
    )
    const [valF2, setvalF2] = useState(
        parseInt(sessionStorage.getItem(`f2${props.check}`)) || 0
    )
    const [valM1, setvalM1] = useState(
        parseInt(sessionStorage.getItem(`m1${props.check}`)) || 0
    )
    const [valSerialServo, setSerialServo] = useState(
        parseInt(sessionStorage.getItem(`serialServo${props.check}`)) || 0
    )
    const [valM2, setvalM2] = useState(
        parseInt(sessionStorage.getItem(`m2${props.check}`)) || 0
    )
    const [valM3, setvalM3] = useState(
        parseInt(sessionStorage.getItem(`m3${props.check}`)) || 0
    )
    const [valArmShield, setArmShield] = useState(
        parseInt(sessionStorage.getItem(`armShield${props.check}`)) || 0
    )
    const [valM4, setvalM4] = useState(
        parseInt(sessionStorage.getItem(`m4${props.check}`)) || 0
    )
    const [valT0, setvalT0] = useState(
        parseInt(sessionStorage.getItem(`t0${props.check}`)) || 0
    )
    const [valT1, setvalT1] = useState(
        parseInt(sessionStorage.getItem(`t1${props.check}`)) || 0
    )
    const [valT2, setvalT2] = useState(
        parseInt(sessionStorage.getItem(`t2${props.check}`)) || 0
    )
    const [valT3, setvalT3] = useState(
        parseInt(sessionStorage.getItem(`t3${props.check}`)) || 0
    )

    const [valS1Servo, setValS1Servo] = useState(
        parseInt(sessionStorage.getItem(`s1Servo${props.check}`)) || 0
    )
    const [valS2Servo, setValS2Servo] = useState(
        parseInt(sessionStorage.getItem(`s2Servo${props.check}`)) || 0
    )

    const [isClickOLED1, setIsClickOLED1] = useState(
        Boolean(
            sessionStorage.getItem(`oledChk1${props.check}`) == '1' ||
                sessionStorage.getItem(`oledChk1${props.check}`) == 'true'
        ) || false
    )
    const [isClickOLED2, setIsClickOLED2] = useState(
        Boolean(
            sessionStorage.getItem(`oledChk2${props.check}`) == '1' ||
                sessionStorage.getItem(`oledChk2${props.check}`) == 'true'
        ) || false
    )
    const [isClickOLED3, setIsClickOLED3] = useState(
        Boolean(
            sessionStorage.getItem(`oledChk3${props.check}`) == '1' ||
                sessionStorage.getItem(`oledChk3${props.check}`) == 'true'
        ) || false
    )
    const [isClickOLED4, setIsClickOLED4] = useState(
        Boolean(
            sessionStorage.getItem(`oledChk4${props.check}`) == '1' ||
                sessionStorage.getItem(`oledChk4${props.check}`) == 'true'
        ) || false
    )
    const [curValOLED1, setCurValOLED1] = useState(
        sessionStorage.getItem(`oled1${props.check}`) || ''
    )
    const [curValOLED2, setCurValOLED2] = useState(
        sessionStorage.getItem(`oled2${props.check}`) || ''
    )
    const [curValOLED3, setCurValOLED3] = useState(
        sessionStorage.getItem(`oled3${props.check}`) || ''
    )
    const [curValOLED4, setCurValOLED4] = useState(
        sessionStorage.getItem(`oled4${props.check}`) || ''
    )

    const [valRGBEye, setValRGBEye] = useState(() => {
        const existingState = sessionStorage.getItem(`valRGBEye${props.check}`)
        if (existingState && Number(existingState) !== 0)
            return JSON.parse(existingState)

        return [
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
            { isSelected: false, valR: 0, valG: 0, valB: 0 },
        ]
    })

    useEffect(() => {
        sessionStorage.setItem(
            `valRGBEye${props.check}`,
            JSON.stringify(valRGBEye)
        )
    }, [valRGBEye])

    const [valLeye, setvalLeye] = useState(
        Boolean(
            sessionStorage.getItem(`eyel${props.check}`) == '1' ||
                sessionStorage.getItem(`eyel${props.check}`) == 'true'
        ) || false
    )
    const [valLeyeR, setvalLeyeR] = useState(
        parseInt(sessionStorage.getItem(`eyelR${props.check}`)) || 0
    )
    const [valLeyeG, setvalLeyeG] = useState(
        parseInt(sessionStorage.getItem(`eyelG${props.check}`)) || 0
    )
    const [valLeyeB, setvalLeyeB] = useState(
        parseInt(sessionStorage.getItem(`eyelB${props.check}`)) || 0
    )

    const [valReye, setvalReye] = useState(
        Boolean(
            sessionStorage.getItem(`re${props.check}`) == '1' ||
                sessionStorage.getItem(`re${props.check}`) == 'true'
        ) || false
    )
    const [valReyeR, setvalReyeR] = useState(
        parseInt(sessionStorage.getItem(`reR${props.check}`)) || 0
    )
    const [valReyeG, setvalReyeG] = useState(
        parseInt(sessionStorage.getItem(`reG${props.check}`)) || 0
    )
    const [valReyeB, setvalReyeB] = useState(
        parseInt(sessionStorage.getItem(`reB${props.check}`)) || 0
    )

    const [valBuzz, setvalBuzz] = useState(
        parseInt(sessionStorage.getItem(`buzz${props.check}`)) || 0
    )
    const [buzzCheckbox, setBuzzCheckbox] = useState(
        Boolean(
            sessionStorage.getItem(`buzzChk${props.check}`) == '1' ||
                sessionStorage.getItem(`buzzChk${props.check}`) == 'true'
        ) || false
    )
    const [frequencyBuzzCheck, setFrequencyBuzzChk] = useState(
        // Boolean(
        JSON.parse(sessionStorage.getItem(`frequencyBuzzCheck${props.check}`))
        // )
    )
    const [toneBuzzCheck, setToneBuzzChk] = useState(
        JSON.parse(sessionStorage.getItem(`toneBuzzCheck${props.check}`))
    )
    const [isActiveName, setActiveName] = useState(
        sessionStorage.getItem(`isActiveName${props.check}`) || 'Frequency'
    )
    const [valSm1, setvalSm1] = useState(
        parseInt(sessionStorage.getItem(`sm1${props.check}`)) || 0
    )
    const [valSm2, setvalSm2] = useState(
        parseInt(sessionStorage.getItem(`sm2${props.check}`)) || 0
    )
    const [valSm3, setvalSm3] = useState(
        parseInt(sessionStorage.getItem(`sm3${props.check}`)) || 0
    )
    const [valSm4, setvalSm4] = useState(
        parseInt(sessionStorage.getItem(`sm4${props.check}`)) || 0
    )

    const [valPSm1, setvalPSm1] = useState(
        parseInt(sessionStorage.getItem(`psm1${props.check}`)) || 0
    )
    const [valPSm2, setvalPSm2] = useState(
        parseInt(sessionStorage.getItem(`psm2${props.check}`)) || 0
    )
    const [valPSm3, setvalPSm3] = useState(
        parseInt(sessionStorage.getItem(`psm3${props.check}`)) || 0
    )
    const [valPSm4, setvalPSm4] = useState(
        parseInt(sessionStorage.getItem(`psm4${props.check}`)) || 0
    )
    const [valPSm5, setvalPSm5] = useState(
        parseInt(sessionStorage.getItem(`psm5${props.check}`)) || 0
    )
    const [a1Checkbox, setA1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`a1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`a1Chk${props.check}`) == 'true'
        ) || false
    )
    const [a2Checkbox, setA2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`a2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`a2Chk${props.check}`) == 'true'
        ) || false
    )
    const [b1Checkbox, setB1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`b1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`b1Chk${props.check}`) == 'true'
        ) || false
    )
    const [b2Checkbox, setB2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`b2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`b2Chk${props.check}`) == 'true'
        ) || false
    )
    const [c1Checkbox, setC1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`c1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`c1Chk${props.check}`) == 'true'
        ) || false
    )
    const [c2Checkbox, setC2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`c2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`c2Chk${props.check}`) == 'true'
        ) || false
    )
    const [d1Checkbox, setD1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`d1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`d1Chk${props.check}`) == 'true'
        ) || false
    )
    const [d2Checkbox, setD2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`d2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`d2Chk${props.check}`) == 'true'
        ) || false
    )
    const [e1Checkbox, setE1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`e1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`e1Chk${props.check}`) == 'true'
        ) || false
    )
    const [e2Checkbox, setE2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`e2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`e2Chk${props.check}`) == 'true'
        ) || false
    )
    const [f1Checkbox, setF1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`f1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`f1Chk${props.check}`) == 'true'
        ) || false
    )
    const [f2Checkbox, setF2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`f2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`f2Chk${props.check}`) == 'true'
        ) || false
    )
    const [m1Checkbox, setM1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`m1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`m1Chk${props.check}`) == 'true'
        ) || false
    )
    const [serialServoCheckbox, setSerialServoCheckbox] = useState(
        Boolean(
            sessionStorage.getItem(`serialServoChk${props.check}`) == '1' ||
                sessionStorage.getItem(`serialServoChk${props.check}`) == 'true'
        ) || false
    )
    const [m2Checkbox, setM2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`m2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`m2Chk${props.check}`) == 'true'
        ) || false
    )
    const [m3Checkbox, setM3Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`m3Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`m3Chk${props.check}`) == 'true'
        ) || false
    )
    const [armShieldCheckbox, setArmShieldCheckbox] = useState(
        Boolean(
            sessionStorage.getItem(`armShieldChk${props.check}`) == '1' ||
                sessionStorage.getItem(`armShieldChk${props.check}`) == 'true'
        ) || false
    )

    const [m4Checkbox, setM4Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk${props.check}`) == 'true'
        ) || false
    )
    const [m4Checkbox1, setM4Checkbox1] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk1${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk1${props.check}`) == 'true'
        )
    )
    const [m4Checkbox2, setM4Checkbox2] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk2${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk2${props.check}`) == 'true'
        )
    )
    const [m4Checkbox3, setM4Checkbox3] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk3${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk3${props.check}`) == 'true'
        )
    )
    const [m4Checkbox4, setM4Checkbox4] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk4${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk4${props.check}`) == 'true'
        )
    )
    const [m4Checkbox5, setM4Checkbox5] = useState(
        Boolean(
            sessionStorage.getItem(`m4Chk5${props.check}`) == '1' ||
                sessionStorage.getItem(`m4Chk5${props.check}`) == 'true'
        )
    )

    const [s1Checkbox, setS1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`s1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`s1Chk${props.check}`) == 'true'
        ) || false
    )
    const [s2Checkbox, setS2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`s2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`s2Chk${props.check}`) == 'true'
        ) || false
    )
    const [s1ServoCheckbox, setS1ServoCheckbox] = useState(
        Boolean(
            sessionStorage.getItem(`s1ServoChk${props.check}`) == '1' ||
                sessionStorage.getItem(`s1ServoChk${props.check}`) == 'true'
        ) || false
    )
    const [s2ServoCheckbox, setS2ServoCheckbox] = useState(
        Boolean(
            sessionStorage.getItem(`s2ServoChk${props.check}`) == '1' ||
                sessionStorage.getItem(`s2ServoChk${props.check}`) == 'true'
        ) || false
    )
    const [s3Checkbox, setS3Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`s3Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`s3Chk${props.check}`) == 'true'
        ) || false
    )
    const [s4Checkbox, setS4Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`s4Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`s4Chk${props.check}`) == 'true'
        ) || false
    )
    const [t0Checkbox, setT0Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`t0Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`t0Chk${props.check}`) == 'true'
        ) || false
    )
    const [t1Checkbox, setT1Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`t1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`t1Chk${props.check}`) == 'true'
        ) || false
    )
    const [t2Checkbox, setT2Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`t2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`t2Chk${props.check}`) == 'true'
        ) || false
    )
    const [t3Checkbox, setT3Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`t3Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`t3Chk${props.check}`) == 'true'
        ) || false
    )
    const [valMP3, setvalMP3] = useState(
        parseInt(sessionStorage.getItem(`mp3${props.check}`)) || 0
    )
    const [mp3Checkbox, setMP3Checkbox] = useState(
        Boolean(
            sessionStorage.getItem(`mp3Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`mp3Chk${props.check}`) == 'true'
        ) || false
    )
    const [countRGBComp, setCountRGBComp] = useState(
        parseInt(sessionStorage.getItem(`countRGB${props.check}`)) || 1
    )
    let rgbArray1 = JSON.parse(sessionStorage.getItem(`valRGB1${props.check}`)),
        rgbArray2 = JSON.parse(sessionStorage.getItem(`valRGB2${props.check}`)),
        rgbArray3 = JSON.parse(sessionStorage.getItem(`valRGB3${props.check}`)),
        rgbArray4 = JSON.parse(sessionStorage.getItem(`valRGB4${props.check}`)),
        rgbArray5 = JSON.parse(sessionStorage.getItem(`valRGB5${props.check}`)),
        rgbArray6 = JSON.parse(sessionStorage.getItem(`valRGB6${props.check}`)),
        rgbArray7 = JSON.parse(sessionStorage.getItem(`valRGB7${props.check}`)),
        rgbArray8 = JSON.parse(sessionStorage.getItem(`valRGB8${props.check}`)),
        rgbArray9 = JSON.parse(sessionStorage.getItem(`valRGB9${props.check}`)),
        rgbArray10 = JSON.parse(
            sessionStorage.getItem(`valRGB10${props.check}`)
        )
    if (rgbArray1 == null) rgbArray1 = { r: 0, g: 0, b: 0 }
    if (rgbArray2 == null) rgbArray2 = { r: 0, g: 0, b: 0 }
    if (rgbArray3 == null) rgbArray3 = { r: 0, g: 0, b: 0 }
    if (rgbArray4 == null) rgbArray4 = { r: 0, g: 0, b: 0 }
    if (rgbArray5 == null) rgbArray5 = { r: 0, g: 0, b: 0 }
    if (rgbArray6 == null) rgbArray6 = { r: 0, g: 0, b: 0 }
    if (rgbArray7 == null) rgbArray7 = { r: 0, g: 0, b: 0 }
    if (rgbArray8 == null) rgbArray8 = { r: 0, g: 0, b: 0 }
    if (rgbArray9 == null) rgbArray9 = { r: 0, g: 0, b: 0 }
    if (rgbArray10 == null) rgbArray10 = { r: 0, g: 0, b: 0 }

    const [valRgb1, setValRgb1] = useState([
        rgbArray1.r || 0,
        rgbArray1.g || 0,
        rgbArray1.b || 0,
    ])
    const [valRgb2, setValRgb2] = useState([
        rgbArray2.r || 0,
        rgbArray2.g || 0,
        rgbArray2.b || 0,
    ])
    const [valRgb3, setValRgb3] = useState([
        rgbArray3.r || 0,
        rgbArray3.g || 0,
        rgbArray3.b || 0,
    ])
    const [valRgb4, setValRgb4] = useState([
        rgbArray4.r || 0,
        rgbArray4.g || 0,
        rgbArray4.b || 0,
    ])
    const [valRgb5, setValRgb5] = useState([
        rgbArray5.r || 0,
        rgbArray5.g || 0,
        rgbArray5.b || 0,
    ])
    const [valRgb6, setValRgb6] = useState([
        rgbArray6.r || 0,
        rgbArray6.g || 0,
        rgbArray6.b || 0,
    ])
    const [valRgb7, setValRgb7] = useState([
        rgbArray7.r || 0,
        rgbArray7.g || 0,
        rgbArray7.b || 0,
    ])
    const [valRgb8, setValRgb8] = useState([
        rgbArray8.r || 0,
        rgbArray8.g || 0,
        rgbArray8.b || 0,
    ])
    const [valRgb9, setValRgb9] = useState([
        rgbArray9.r || 0,
        rgbArray9.g || 0,
        rgbArray9.b || 0,
    ])
    const [valRgb10, setValRgb10] = useState([
        rgbArray10.r || 0,
        rgbArray10.g || 0,
        rgbArray10.b || 0,
    ])
    const [rgb1, setRgb1] = useState(
        Boolean(
            sessionStorage.getItem(`rgb1Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb1Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb2, setRgb2] = useState(
        Boolean(
            sessionStorage.getItem(`rgb2Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb2Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb3, setRgb3] = useState(
        Boolean(
            sessionStorage.getItem(`rgb3Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb3Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb4, setRgb4] = useState(
        Boolean(
            sessionStorage.getItem(`rgb4Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb4Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb5, setRgb5] = useState(
        Boolean(
            sessionStorage.getItem(`rgb5Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb5Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb6, setRgb6] = useState(
        Boolean(
            sessionStorage.getItem(`rgb6Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb6Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb7, setRgb7] = useState(
        Boolean(
            sessionStorage.getItem(`rgb7Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb7Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb8, setRgb8] = useState(
        Boolean(
            sessionStorage.getItem(`rgb8Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb8Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb9, setRgb9] = useState(
        Boolean(
            sessionStorage.getItem(`rgb9Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb9Chk${props.check}`) == 'true'
        ) || false
    )
    const [rgb10, setRgb10] = useState(
        Boolean(
            sessionStorage.getItem(`rgb10Chk${props.check}`) == '1' ||
                sessionStorage.getItem(`rgb10Chk${props.check}`) == 'true'
        ) || false
    )

    const a1Checked = JSON.parse(sessionStorage.getItem('a1-I/O'))
    const a1Digi = JSON.parse(sessionStorage.getItem('A1DIGI'))

    const a2Checked = JSON.parse(sessionStorage.getItem('a2-I/O'))
    const a2Digi = JSON.parse(sessionStorage.getItem('A2DIGI'))
    const b1Checked = JSON.parse(sessionStorage.getItem('b1-I/O'))
    const b1Digi = JSON.parse(sessionStorage.getItem('B1DIGI'))
    const b2Checked = JSON.parse(sessionStorage.getItem('b2-I/O'))
    const b2Digi = JSON.parse(sessionStorage.getItem('B2DIGI'))
    const c1Checked = JSON.parse(sessionStorage.getItem('c1-I/O'))
    const c1Digi = JSON.parse(sessionStorage.getItem('C1DIGI'))
    const c2Checked = JSON.parse(sessionStorage.getItem('c2-I/O'))
    const c2Digi = JSON.parse(sessionStorage.getItem('C2DIGI'))
    const d1Checked = !sessionStorage.getItem('deviceVersion')?.startsWith('1')
        ? JSON.parse(sessionStorage.getItem('D1'))
        : JSON.parse(sessionStorage.getItem('d1-I/O'))
    const d1Digi = JSON.parse(sessionStorage.getItem('D1DIGI'))
    const d2Checked = !sessionStorage.getItem('deviceVersion')?.startsWith('1')
        ? JSON.parse(sessionStorage.getItem('D2'))
        : JSON.parse(sessionStorage.getItem('d2-I/O'))
    const d2Digi = JSON.parse(sessionStorage.getItem('D2DIGI'))
    const e1Checked = JSON.parse(sessionStorage.getItem('e1-I/O'))
    const e1Digi = JSON.parse(sessionStorage.getItem('E1DIGI'))
    const e2Checked = JSON.parse(sessionStorage.getItem('e2-I/O'))
    const e2Digi = JSON.parse(sessionStorage.getItem('E2DIGI'))
    const f1Checked = JSON.parse(sessionStorage.getItem('f1-I/O'))
    const f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
    const f2Checked = JSON.parse(sessionStorage.getItem('f2-I/O'))
    const f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
    const m1Checked = JSON.parse(sessionStorage.getItem('m1-I/O'))
    const m1Digi = JSON.parse(sessionStorage.getItem('M1DIGI'))
    const m2Checked = JSON.parse(sessionStorage.getItem('m2-I/O'))
    const m2Digi = JSON.parse(sessionStorage.getItem('M2DIGI'))
    const m3Checked = JSON.parse(sessionStorage.getItem('m3-I/O'))
    const m3Digi = JSON.parse(sessionStorage.getItem('M3DIGI'))
    const m4Checked = JSON.parse(sessionStorage.getItem('m4-I/O'))
    const m4Digi = JSON.parse(sessionStorage.getItem('M4DIGI'))
    const a1Servo = JSON.parse(sessionStorage.getItem('A1Servo'))
    const a1Servo270 = JSON.parse(sessionStorage.getItem('A1Servo270'))
    const a1Servo360 = JSON.parse(sessionStorage.getItem('A1Servo360'))
    const a2Servo = JSON.parse(sessionStorage.getItem('A2Servo'))
    const a2Servo270 = JSON.parse(sessionStorage.getItem('A2Servo270'))
    const a2Servo360 = JSON.parse(sessionStorage.getItem('A2Servo360'))
    const b1Servo = JSON.parse(sessionStorage.getItem('B1Servo'))
    const b1Servo270 = JSON.parse(sessionStorage.getItem('B1Servo270'))
    const b1Servo360 = JSON.parse(sessionStorage.getItem('B1Servo360'))
    const b2Servo = JSON.parse(sessionStorage.getItem('B2Servo'))
    const b2Servo270 = JSON.parse(sessionStorage.getItem('B2Servo270'))
    const b2Servo360 = JSON.parse(sessionStorage.getItem('B2Servo360'))
    const c1Servo = JSON.parse(sessionStorage.getItem('C1Servo'))
    const c1Servo270 = JSON.parse(sessionStorage.getItem('C1Servo270'))
    const c1Servo360 = JSON.parse(sessionStorage.getItem('C1Servo360'))
    const c2Servo = JSON.parse(sessionStorage.getItem('C2Servo'))
    const c2Servo270 = JSON.parse(sessionStorage.getItem('C2Servo270'))
    const c2Servo360 = JSON.parse(sessionStorage.getItem('C2Servo360'))
    const d1Servo = JSON.parse(sessionStorage.getItem('D1Servo'))
    const d1Servo270 = JSON.parse(sessionStorage.getItem('D1Servo270'))
    const d1Servo360 = JSON.parse(sessionStorage.getItem('D1Servo360'))
    const d2Servo = JSON.parse(sessionStorage.getItem('D2Servo'))
    const d2Servo270 = JSON.parse(sessionStorage.getItem('D2Servo270'))
    const d2Servo360 = JSON.parse(sessionStorage.getItem('D2Servo360'))
    const e1Servo = JSON.parse(sessionStorage.getItem('E1Servo'))
    const e1Servo270 = JSON.parse(sessionStorage.getItem('E1Servo270'))
    const e1Servo360 = JSON.parse(sessionStorage.getItem('E1Servo360'))
    const e2Servo = JSON.parse(sessionStorage.getItem('E2Servo'))
    const e2Servo270 = JSON.parse(sessionStorage.getItem('E2Servo270'))
    const e2Servo360 = JSON.parse(sessionStorage.getItem('E2Servo360'))
    const f1Servo = JSON.parse(sessionStorage.getItem('F1Servo'))
    const f1Servo270 = JSON.parse(sessionStorage.getItem('F1Servo270'))
    const f1Servo360 = JSON.parse(sessionStorage.getItem('F1Servo360'))
    const f2Servo = JSON.parse(sessionStorage.getItem('F2Servo'))
    const f2Servo270 = JSON.parse(sessionStorage.getItem('F2Servo270'))
    const f2Servo360 = JSON.parse(sessionStorage.getItem('F2Servo360'))
    const s1Servo = JSON.parse(sessionStorage.getItem('S1Servo'))
    const s2Servo = JSON.parse(sessionStorage.getItem('S2Servo'))
    const s1Servo270 = JSON.parse(sessionStorage.getItem('S1Servo270'))
    const s2Servo270 = JSON.parse(sessionStorage.getItem('S2Servo270'))
    const s1Servo360 = JSON.parse(sessionStorage.getItem('S1Servo360'))
    const s2Servo360 = JSON.parse(sessionStorage.getItem('S2Servo360'))
    const A1 = JSON.parse(sessionStorage.getItem('A1'))
    const A2 = JSON.parse(sessionStorage.getItem('A2'))
    const B1 = JSON.parse(sessionStorage.getItem('B1'))
    const B2 = JSON.parse(sessionStorage.getItem('B2'))
    const C1 = JSON.parse(sessionStorage.getItem('C1'))
    const C2 = JSON.parse(sessionStorage.getItem('C2'))
    const D1 = JSON.parse(sessionStorage.getItem('D1'))
    const D2 = JSON.parse(sessionStorage.getItem('D2'))
    const E1 = JSON.parse(sessionStorage.getItem('E1'))
    const E2 = JSON.parse(sessionStorage.getItem('E2'))
    const F1 = JSON.parse(sessionStorage.getItem('F1'))
    const F2 = JSON.parse(sessionStorage.getItem('F2'))
    const M1 = JSON.parse(sessionStorage.getItem('M1'))
    const M2 = JSON.parse(sessionStorage.getItem('M2'))
    const M3 = JSON.parse(sessionStorage.getItem('M3'))
    const M4 = JSON.parse(sessionStorage.getItem('M4'))
    const S1 = JSON.parse(sessionStorage.getItem('S1'))
    const S2 = JSON.parse(sessionStorage.getItem('S2'))
    const MP3 = JSON.parse(sessionStorage.getItem('BMP3'))
    const OLED = JSON.parse(sessionStorage.getItem('DOLED'))
    const RGB = JSON.parse(sessionStorage.getItem('BRGB'))
    const aUltra = JSON.parse(sessionStorage.getItem('AUltra'))
    const cUltra = JSON.parse(sessionStorage.getItem('CUltra'))
    const armShieldMP3 = JSON.parse(sessionStorage.getItem('M3Mp3'))
    const serialServoM4 = JSON.parse(sessionStorage.getItem('M1Servo'))

    let isTouchZeroOutput = JSON.parse(
        sessionStorage.getItem('isTouchZeroOutput')
    )
    let isTouchOneOutput = JSON.parse(
        sessionStorage.getItem('isTouchOneOutput')
    )
    let isTouchTwoOutput = JSON.parse(
        sessionStorage.getItem('isTouchTwoOutput')
    )
    let isTouchThreeOutput = JSON.parse(
        sessionStorage.getItem('isTouchThreeOutput')
    )
    let isSmileOne = JSON.parse(sessionStorage.getItem('isSmileOne'))
    let isSmileTwo = JSON.parse(sessionStorage.getItem('isSmileTwo'))
    let isSmileThree = JSON.parse(sessionStorage.getItem('isSmileThree'))
    let isSmileFour = JSON.parse(sessionStorage.getItem('isSmileFour'))
    let isEyeLeft = JSON.parse(sessionStorage.getItem('isEyeLeft'))
    let isInternalEye = JSON.parse(sessionStorage.getItem('isInternalEye'))
    let isEyeRight = JSON.parse(sessionStorage.getItem('isEyeRight'))
    let isBuzzer = JSON.parse(sessionStorage.getItem('isBuzzer'))

    const onChange = (key, value) => {
        console.log(value)
        if (key === 's1ServoCheckbox') {
            if (s1ServoCheckbox) {
                setValS1Servo(0)
            } else {
                setValS1Servo(value)
            }
            setS1ServoCheckbox(!s1ServoCheckbox)
            sessionStorage.setItem(`s1ServoChk${props.check}`, !s1ServoCheckbox)
        } else if (key === 's2ServoCheckbox') {
            if (s2ServoCheckbox) {
                setValS2Servo(0)
            } else {
                setValS2Servo(value)
            }
            setS2ServoCheckbox(!s2ServoCheckbox)
            sessionStorage.setItem(`s2ServoChk${props.check}`, !s2ServoCheckbox)
        } else if (key === 'valS1Servo') {
            setValS1Servo(value)
            sessionStorage.setItem(`s1Servo${props.check}`, value)
        } else if (key === 'valS2Servo') {
            setValS2Servo(value)
            sessionStorage.setItem(`s2Servo${props.check}`, value)
        } else if (key === 'bic1Chk') {
            setBic1Chk(!bic1Chk)
            sessionStorage.setItem(`bic1Chk${props.check}`, !bic1Chk)
        } else if (key === 'bic1Val') {
            setBic1Val(value)
            sessionStorage.setItem(`bic1Val${props.check}`, value)
        } else if (key === 'bic2Chk') {
            setBic2Chk(!bic2Chk)
            sessionStorage.setItem(`bic2Chk${props.check}`, !bic2Chk)
        } else if (key === 'bic2Val') {
            setBic2Val(value)
            sessionStorage.setItem(`bic2Val${props.check}`, value)
        } else if (key === 'bif1Chk') {
            setBif1Chk(!bif1Chk)
            sessionStorage.setItem(`bif1Chk${props.check}`, !bif1Chk)
        } else if (key === 'bif1Val') {
            setBif1Val(value)
            sessionStorage.setItem(`bif1Val${props.check}`, value)
        } else if (key === 'bif2Chk') {
            setBif2Chk(!bif2Chk)
            sessionStorage.setItem(`bif2Chk${props.check}`, !bif2Chk)
        } else if (key === 'bif2Val') {
            setBif2Val(value)
            sessionStorage.setItem(`bif2Val${props.check}`, value)
        } else if (key === 'usbTxChk') {
            setUsbTxChk(!usbTxChk)
            sessionStorage.setItem(`usbTxChk${props.check}`, !usbTxChk)
        } else if (key === 'usbTxVal') {
            setUsbTxVal(value)
            sessionStorage.setItem(`usbTxVal${props.check}`, value)
        } else if (key === 'btTx1Chk') {
            setBtTx1Chk(!btTx1Chk)
            sessionStorage.setItem(`btTx1Chk${props.check}`, !btTx1Chk)
        } else if (key === 'btTx1Val') {
            setBtTx1Val(value)
            sessionStorage.setItem(`btTx1Val${props.check}`, value)
        } else if (key === 'btTx2Chk') {
            setBtTx2Chk(!btTx2Chk)
            sessionStorage.setItem(`btTx2Chk${props.check}`, !btTx2Chk)
        } else if (key === 'btTx2Val') {
            setBtTx2Val(value)
            sessionStorage.setItem(`btTx2Val${props.check}`, value)
        } else if (key === 'btTx3Chk') {
            setBtTx3Chk(!btTx3Chk)
            sessionStorage.setItem(`btTx3Chk${props.check}`, !btTx3Chk)
        } else if (key === 'btTx3Val') {
            setBtTx3Val(value)
            sessionStorage.setItem(`btTx3Val${props.check}`, value)
        } else if (key === 'btTx4Chk') {
            setBtTx4Chk(!btTx4Chk)
            sessionStorage.setItem(`btTx4Chk${props.check}`, !btTx4Chk)
        } else if (key === 'btTx4Val') {
            setBtTx4Val(value)
            sessionStorage.setItem(`btTx4Val${props.check}`, value)
        } else if (key === 'btTx5Chk') {
            setBtTx5Chk(!btTx5Chk)
            sessionStorage.setItem(`btTx5Chk${props.check}`, !btTx5Chk)
        } else if (key === 'btTx5Val') {
            setBtTx5Val(value)
            sessionStorage.setItem(`btTx5Val${props.check}`, value)
        } else if (key === 'a1') {
            setvalA1(value)
            return
        } else if (key === 'a2') {
            setvalA2(value)
            return
        } else if (key === 'b1') {
            setvalB1(value)
            return
        } else if (key === 'b2') {
            setvalB2(value)
            return
        } else if (key === 'c1') {
            setvalC1(value)
            return
        } else if (key === 'c2') {
            setvalC2(value)
            return
        } else if (key === 'd1') {
            setvalD1(value)
            return
        } else if (key === 'd2') {
            setvalD2(value)
            return
        } else if (key === 'e1') {
            setvalE1(value)
            return
        } else if (key === 'e2') {
            setvalE2(value)
            return
        } else if (key === 'f1') {
            setvalF1(value)
            return
        } else if (key === 'f2') {
            setvalF2(value)
            return
        } else if (key === 'm1') {
            setvalM1(value)
            return
        } else if (key === 'm2') {
            setvalM2(value)
            return
        } else if (key === 'armShield') {
            setArmShield(value)
            return
        } else if (key === 'serialServo') {
            setSerialServo(value)
            return
        } else if (key === 'm3') {
            setvalM3(value)
            return
        } else if (key === 'm4') {
            setvalM4(value)
            return
        } else if (key === 'psm1') {
            setvalPSm1(value)
            return
        } else if (key === 'psm2') {
            setvalPSm2(value)
            return
        } else if (key === 'psm3') {
            setvalPSm3(value)
            return
        } else if (key === 'psm4') {
            setvalPSm4(value)
            return
        } else if (key === 'psm5') {
            setvalPSm5(value)
            return
        } else if (key === 't0') {
            setvalT0(value)
        } else if (key === 't1') {
            setvalT1(value)
        } else if (key === 't2') {
            setvalT2(value)
        } else if (key === 't3') {
            setvalT3(value)
        } else if (key === 'leye') {
            if (valLeye) {
                setvalLeyeR(0)
                setvalLeyeG(0)
                setvalLeyeB(0)
            } else {
                setvalLeyeR(value)
                setvalLeyeG(value)
                setvalLeyeB(value)
            }
            setvalLeye(!valLeye)
        } else if (key === 'leyeR') {
            setvalLeyeR(value)
        } else if (key === 'leyeG') {
            setvalLeyeG(value)
        } else if (key === 'leyeB') {
            setvalLeyeB(value)
        } else if (key === 'reye') {
            if (valReye) {
                setvalReyeR(0)
                setvalReyeG(0)
                setvalReyeB(0)
            } else {
                setvalReyeR(value)
                setvalReyeG(value)
                setvalReyeB(value)
            }
            setvalReye(!valReye)
        } else if (key === 'reyeR') {
            setvalReyeR(value)
        } else if (key === 'reyeG') {
            setvalReyeG(value)
        } else if (key === 'reyeB') {
            setvalReyeB(value)
        } else if (key === 'buzz') {
            setvalBuzz(value)
        } else if (key === 'buzzCheckbox') {
            setActiveName('Frequency')
            setFrequencyBuzzChk(true)
            setvalBuzz(0)
            setToneBuzzChk(false)
            setBuzzCheckbox(!buzzCheckbox)
        } else if (key === 'sm1') {
            setvalSm1(value)
        } else if (key === 'sm2') {
            setvalSm2(value)
        } else if (key === 'sm3') {
            setvalSm3(value)
        } else if (key === 'sm4') {
            setvalSm4(value)
        } else if (key === 'mp3') {
            setvalMP3(value)
        } else if (key === 'a1Checkbox') {
            if (a1Checkbox) {
                setvalA1(0)
            } else {
                setvalA1(value)
            }
            setA1Checkbox(!a1Checkbox)
        } else if (key === 'a2Checkbox') {
            if (a2Checkbox) {
                setvalA2(0)
            } else {
                setvalA2(value)
            }
            setA2Checkbox(!a2Checkbox)
        } else if (key === 'b1Checkbox') {
            if (b1Checkbox) {
                setvalB1(0)
            } else {
                setvalB1(value)
            }
            setB1Checkbox(!b1Checkbox)
        } else if (key === 'b2Checkbox') {
            if (b2Checkbox) {
                setvalB2(0)
            } else {
                setvalB2(value)
            }
            setB2Checkbox(!b2Checkbox)
        } else if (key === 'c1Checkbox') {
            if (c1Checkbox) {
                setvalC1(0)
            } else {
                setvalC1(value)
            }
            setC1Checkbox(!c1Checkbox)
        } else if (key === 'c2Checkbox') {
            if (c2Checkbox) {
                setvalC2(0)
            } else {
                setvalC2(value)
            }
            setC2Checkbox(!c2Checkbox)
        } else if (key === 'd1Checkbox') {
            if (d1Checkbox) {
                setvalD1(0)
            } else {
                setvalD1(value)
            }
            setD1Checkbox(!d1Checkbox)
        } else if (key === 'd2Checkbox') {
            if (d2Checkbox) {
                setvalD2(0)
            } else {
                setvalD2(value)
            }
            setD2Checkbox(!d2Checkbox)
        } else if (key === 'e1Checkbox') {
            if (e1Checkbox) {
                setvalE1(0)
            } else {
                setvalE1(value)
            }
            setE1Checkbox(!e1Checkbox)
        } else if (key === 'e2Checkbox') {
            if (e2Checkbox) {
                setvalE2(0)
            } else {
                setvalE2(value)
            }
            setE2Checkbox(!e2Checkbox)
        } else if (key === 'f1Checkbox') {
            if (f1Checkbox) {
                setvalF1(0)
            } else {
                setvalF1(value)
            }
            setF1Checkbox(!f1Checkbox)
        } else if (key === 'f2Checkbox') {
            if (f2Checkbox) {
                setvalF2(0)
            } else {
                setvalF2(value)
            }
            setF2Checkbox(!f2Checkbox)
        } else if (key === 'm1Checkbox') {
            if (m1Checkbox) {
                setvalM1(0)
            } else {
                setvalM1(value)
            }
            setM1Checkbox(!m1Checkbox)
        } else if (key === 'serialServoCheckbox') {
            if (serialServoCheckbox) {
                setSerialServo(0)
            } else {
                setSerialServo(value)
            }
            setSerialServoCheckbox(!serialServoCheckbox)
        } else if (key === 'm2Checkbox') {
            if (m2Checkbox) {
                setvalM2(0)
            } else {
                setvalM2(value)
            }
            setM2Checkbox(!m2Checkbox)
        } else if (key === 'm3Checkbox') {
            if (m3Checkbox) {
                setvalM3(0)
            } else {
                setvalM3(value)
            }
            setM3Checkbox(!m3Checkbox)
        } else if (key === 'armShieldCheckbox') {
            if (armShieldCheckbox) {
                setArmShield(0)
            } else {
                setArmShield(value)
            }
            setArmShieldCheckbox(!armShieldCheckbox)
        } else if (key === 'm4Checkbox') {
            if (m4Checkbox) {
                setvalM4(0)
            } else {
                setvalM4(value)
            }
            setM4Checkbox(!m4Checkbox)
        } else if (key === 'm4Checkbox1') {
            setM4Checkbox1(!m4Checkbox1)
        } else if (key === 'm4Checkbox2') {
            setM4Checkbox2(!m4Checkbox2)
        } else if (key === 'm4Checkbox3') {
            setM4Checkbox3(!m4Checkbox3)
        } else if (key === 'm4Checkbox4') {
            setM4Checkbox4(!m4Checkbox4)
        } else if (key === 'm4Checkbox5') {
            setM4Checkbox5(!m4Checkbox5)
        } else if (key === 's1Checkbox') {
            if (s1Checkbox) {
                setvalSm1(0)
            } else {
                setvalSm1(value)
            }
            setS1Checkbox(!s1Checkbox)
        } else if (key === 's2Checkbox') {
            if (s2Checkbox) {
                setvalSm2(0)
            } else {
                setvalSm2(value)
            }
            setS2Checkbox(!s2Checkbox)
        } else if (key === 's3Checkbox') {
            if (s3Checkbox) {
                setvalSm3(0)
            } else {
                setvalSm3(value)
            }
            setS3Checkbox(!s3Checkbox)
        } else if (key === 's4Checkbox') {
            if (s4Checkbox) {
                setvalSm4(0)
            } else {
                setvalSm4(value)
            }
            setS4Checkbox(!s4Checkbox)
        } else if (key === 't0Checkbox') {
            if (t0Checkbox) {
                setvalT0(0)
            } else {
                setvalT0(value)
            }
            setT0Checkbox(!t0Checkbox)
        } else if (key === 't1Checkbox') {
            if (t1Checkbox) {
                setvalT1(0)
            } else {
                setvalT1(value)
            }
            setT1Checkbox(!t1Checkbox)
        } else if (key === 't2Checkbox') {
            if (t2Checkbox) {
                setvalT2(0)
            } else {
                setvalT2(value)
            }
            setT2Checkbox(!t2Checkbox)
        } else if (key === 't3Checkbox') {
            if (t3Checkbox) {
                setvalT3(0)
            } else {
                setvalT3(value)
            }
            setT3Checkbox(!t3Checkbox)
        } else if (key === 'mp3Checkbox') {
            if (t2Checkbox) {
                setvalMP3(0)
            } else {
                setvalMP3(value)
            }
            setMP3Checkbox(!mp3Checkbox)
        } else if (key === 'RGB1') {
            console.log('gsk ************', key)
            setRgb1(!rgb1)
        } else if (key === 'RGB2') {
            console.log('gsk ************', key)
            setRgb2(!rgb2)
        } else if (key === 'RGB3') {
            console.log('gsk ************', key)
            setRgb3(!rgb3)
        } else if (key === 'RGB4') {
            setRgb4(!rgb4)
        } else if (key === 'RGB5') {
            setRgb5(!rgb5)
        } else if (key === 'RGB6') {
            setRgb6(!rgb6)
        } else if (key === 'RGB7') {
            setRgb7(!rgb7)
        } else if (key === 'RGB8') {
            setRgb8(!rgb8)
        } else if (key === 'RGB9') {
            setRgb9(!rgb9)
        } else if (key === 'RGB10') {
            setRgb10(!rgb10)
        }
    }

    sessionStorage.setItem(`a1${props.check}`, valA1)
    sessionStorage.setItem(`a1Chk${props.check}`, a1Checkbox)
    sessionStorage.setItem(`a2${props.check}`, valA2)
    sessionStorage.setItem(`a2Chk${props.check}`, a2Checkbox)
    sessionStorage.setItem(`b1${props.check}`, valB1)
    sessionStorage.setItem(`b1Chk${props.check}`, b1Checkbox)
    sessionStorage.setItem(`b2${props.check}`, valB2)
    sessionStorage.setItem(`b2Chk${props.check}`, b2Checkbox)
    sessionStorage.setItem(`c1${props.check}`, valC1)
    sessionStorage.setItem(`c1Chk${props.check}`, c1Checkbox)
    sessionStorage.setItem(`c2${props.check}`, valC2)
    sessionStorage.setItem(`c2Chk${props.check}`, c2Checkbox)
    sessionStorage.setItem(`d1${props.check}`, valD1)
    sessionStorage.setItem(`d1Chk${props.check}`, d1Checkbox)
    sessionStorage.setItem(`d2${props.check}`, valD2)
    sessionStorage.setItem(`d2Chk${props.check}`, d2Checkbox)
    sessionStorage.setItem(`e1${props.check}`, valE1)
    sessionStorage.setItem(`e1Chk${props.check}`, e1Checkbox)
    sessionStorage.setItem(`e2${props.check}`, valE2)
    sessionStorage.setItem(`e2Chk${props.check}`, e2Checkbox)
    sessionStorage.setItem(`f1${props.check}`, valF1)
    sessionStorage.setItem(`f1Chk${props.check}`, f1Checkbox)
    sessionStorage.setItem(`f2${props.check}`, valF2)
    sessionStorage.setItem(`f2Chk${props.check}`, f2Checkbox)
    sessionStorage.setItem(`m1${props.check}`, valM1)
    sessionStorage.setItem(`m1Chk${props.check}`, m1Checkbox)
    sessionStorage.setItem(`serialServoChk${props.check}`, serialServoCheckbox)
    sessionStorage.setItem(`serialServo${props.check}`, valSerialServo)
    sessionStorage.setItem(`m2${props.check}`, valM2)
    sessionStorage.setItem(`m2Chk${props.check}`, m2Checkbox)
    sessionStorage.setItem(`m3${props.check}`, valM3)
    sessionStorage.setItem(`m3Chk${props.check}`, m3Checkbox)
    sessionStorage.setItem(`armShieldChk${props.check}`, armShieldCheckbox)
    sessionStorage.setItem(`armShield${props.check}`, valArmShield)
    sessionStorage.setItem(`m4${props.check}`, valM4)
    sessionStorage.setItem(`m4Chk${props.check}`, m4Checkbox)
    sessionStorage.setItem(`m4Chk1${props.check}`, m4Checkbox1)
    sessionStorage.setItem(`m4Chk2${props.check}`, m4Checkbox2)
    sessionStorage.setItem(`m4Chk3${props.check}`, m4Checkbox3)
    sessionStorage.setItem(`m4Chk4${props.check}`, m4Checkbox4)
    sessionStorage.setItem(`m4Chk5${props.check}`, m4Checkbox5)
    sessionStorage.setItem(`psm1${props.check}`, valPSm1)
    sessionStorage.setItem(`psm2${props.check}`, valPSm2)
    sessionStorage.setItem(`psm3${props.check}`, valPSm3)
    sessionStorage.setItem(`psm4${props.check}`, valPSm4)
    sessionStorage.setItem(`psm5${props.check}`, valPSm5)

    sessionStorage.setItem(`t0${props.check}`, valT0)
    sessionStorage.setItem(`t1${props.check}`, valT1)
    sessionStorage.setItem(`t2${props.check}`, valT2)
    sessionStorage.setItem(`t3${props.check}`, valT3)
    sessionStorage.setItem(`t0Chk${props.check}`, t0Checkbox)
    sessionStorage.setItem(`t1Chk${props.check}`, t1Checkbox)
    sessionStorage.setItem(`t2Chk${props.check}`, t2Checkbox)
    sessionStorage.setItem(`t3Chk${props.check}`, t3Checkbox)

    sessionStorage.setItem(`eyel${props.check}`, valLeye)
    sessionStorage.setItem(`eyelR${props.check}`, valLeyeR)
    sessionStorage.setItem(`eyelG${props.check}`, valLeyeG)
    sessionStorage.setItem(`eyelB${props.check}`, valLeyeB)
    sessionStorage.setItem(`re${props.check}`, valReye)
    sessionStorage.setItem(`reR${props.check}`, valReyeR)
    sessionStorage.setItem(`reG${props.check}`, valReyeG)
    sessionStorage.setItem(`reB${props.check}`, valReyeB)
    sessionStorage.setItem(`buzz${props.check}`, valBuzz)
    sessionStorage.setItem(`buzzChk${props.check}`, buzzCheckbox)
    sessionStorage.setItem(
        `frequencyBuzzCheck${props.check}`,
        frequencyBuzzCheck
    )
    sessionStorage.setItem(`toneBuzzCheck${props.check}`, toneBuzzCheck)
    sessionStorage.setItem(`isActiveName${props.check}`, isActiveName)

    sessionStorage.setItem(`sm1${props.check}`, valSm1)
    sessionStorage.setItem(`sm2${props.check}`, valSm2)
    sessionStorage.setItem(`sm3${props.check}`, valSm3)
    sessionStorage.setItem(`sm4${props.check}`, valSm4)
    sessionStorage.setItem(`s1Chk${props.check}`, s1Checkbox)
    sessionStorage.setItem(`s2Chk${props.check}`, s2Checkbox)
    sessionStorage.setItem(`s3Chk${props.check}`, s3Checkbox)
    sessionStorage.setItem(`s4Chk${props.check}`, s4Checkbox)

    sessionStorage.setItem(`mp3${props.check}`, valMP3)
    sessionStorage.setItem(`mp3Chk${props.check}`, mp3Checkbox)
    sessionStorage.setItem(`oled1${props.check}`, curValOLED1 || '')
    sessionStorage.setItem(`oledChk1${props.check}`, isClickOLED1)
    sessionStorage.setItem(`oled2${props.check}`, curValOLED2 || '')
    sessionStorage.setItem(`oledChk2${props.check}`, isClickOLED2)
    sessionStorage.setItem(`oled3${props.check}`, curValOLED3 || '')
    sessionStorage.setItem(`oledChk3${props.check}`, isClickOLED3)
    sessionStorage.setItem(`oled4${props.check}`, curValOLED4 || '')
    sessionStorage.setItem(`oledChk4${props.check}`, isClickOLED4)
    sessionStorage.setItem(`countRGB${props.check}`, countRGBComp)
    sessionStorage.setItem(`rgb1Chk${props.check}`, rgb1)
    sessionStorage.setItem(`rgb2Chk${props.check}`, rgb2)
    sessionStorage.setItem(`rgb3Chk${props.check}`, rgb3)
    sessionStorage.setItem(`rgb4Chk${props.check}`, rgb4)
    sessionStorage.setItem(`rgb5Chk${props.check}`, rgb5)
    sessionStorage.setItem(`rgb6Chk${props.check}`, rgb6)
    sessionStorage.setItem(`rgb7Chk${props.check}`, rgb7)
    sessionStorage.setItem(`rgb8Chk${props.check}`, rgb8)
    sessionStorage.setItem(`rgb8Chk${props.check}`, rgb9)
    sessionStorage.setItem(`rgb10Chk${props.check}`, rgb10)
    sessionStorage.setItem(
        `valRGB1${props.check}`,
        JSON.stringify({
            r: valRgb1[0],
            g: valRgb1[1],
            b: valRgb1[2],
        })
    )
    sessionStorage.setItem(
        `valRGB2${props.check}`,
        JSON.stringify({
            r: valRgb2[0],
            g: valRgb2[1],
            b: valRgb2[2],
        })
    )
    sessionStorage.setItem(
        `valRGB3${props.check}`,
        JSON.stringify({
            r: valRgb3[0],
            g: valRgb3[1],
            b: valRgb3[2],
        })
    )
    sessionStorage.setItem(
        `valRGB4${props.check}`,
        JSON.stringify({
            r: valRgb4[0],
            g: valRgb4[1],
            b: valRgb4[2],
        })
    )
    sessionStorage.setItem(
        `valRGB5${props.check}`,
        JSON.stringify({
            r: valRgb5[0],
            g: valRgb5[1],
            b: valRgb5[2],
        })
    )
    sessionStorage.setItem(
        `valRGB6${props.check}`,
        JSON.stringify({
            r: valRgb6[0],
            g: valRgb6[1],
            b: valRgb6[2],
        })
    )
    sessionStorage.setItem(
        `valRGB7${props.check}`,
        JSON.stringify({
            r: valRgb7[0],
            g: valRgb7[1],
            b: valRgb7[2],
        })
    )
    sessionStorage.setItem(
        `valRGB8${props.check}`,
        JSON.stringify({
            r: valRgb8[0],
            g: valRgb8[1],
            b: valRgb8[2],
        })
    )
    sessionStorage.setItem(
        `valRGB9${props.check}`,
        JSON.stringify({
            r: valRgb9[0],
            g: valRgb9[1],
            b: valRgb9[2],
        })
    )
    sessionStorage.setItem(
        `valRGB10${props.check}`,
        JSON.stringify({
            r: valRgb10[0],
            g: valRgb10[1],
            b: valRgb10[2],
        })
    )
    const onOLED1Handle = () => {
        setIsClickOLED1(!isClickOLED1)
    }

    const onOLED2Handle = () => {
        setIsClickOLED2(!isClickOLED2)
    }
    const onOLED3Handle = () => {
        setIsClickOLED3(!isClickOLED3)
    }
    const onOLED4Handle = () => {
        setIsClickOLED4(!isClickOLED4)
    }
    const onOLED1HandleText = (e) => {
        setCurValOLED1(e.target.value)
    }
    const onOLED2HandleText = (e) => {
        setCurValOLED2(e.target.value)
    }
    const onOLED3HandleText = (e) => {
        setCurValOLED3(e.target.value)
    }
    const onOLED4HandleText = (e) => {
        setCurValOLED4(e.target.value)
    }
    var styleAdd = {
        backgroundImage: `url(${renderImage('add3x')}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 40,
        width: 40,
        position: 'relative',
        margin: 'auto',
        marginLeft: '60%',
    }

    var styleRemove = {
        backgroundImage: `url(${renderImage('remove3x')}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 40,
        width: 40,
        position: 'relative',
        margin: 'auto',
        marginLeft: '70%',
    }

    const [valRGB, setvalRGB] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])

    const onRGBCompIncrease = () => {
        if (countRGBComp < 10) setCountRGBComp(countRGBComp + 1)
    }
    const onRGBCompDecrease = () => {
        if (countRGBComp > 1) {
            //setRgb2(false);

            let a = `rgb${countRGBComp}Chk${props.check}`
            console.log('program', a, typeof a)
            eval(`setRgb${countRGBComp}(${false})`)
            eval(`setValRgb${countRGBComp}(${[0, 0, 0]})`)
            //sessionStorage.setItem(a, false);

            //sessionStorage.setItem(`valRGB${2}${props.check}`, null);
            setCountRGBComp(countRGBComp - 1)
        }
    }

    const rgbHandle = (e) => {
        if (e.includes('RGB 1')) setRgb1(!rgb1)
        if (e.includes('RGB 2')) setRgb2(!rgb2)
        if (e.includes('RGB 3')) setRgb3(!rgb3)
        if (e.includes('RGB 4')) setRgb4(!rgb4)
        if (e.includes('RGB 5')) setRgb5(!rgb5)
        if (e.includes('RGB 6')) setRgb6(!rgb6)
        if (e.includes('RGB 7')) setRgb7(!rgb7)
        if (e.includes('RGB 8')) setRgb8(!rgb8)
        if (e.includes('RGB 9')) setRgb9(!rgb9)
        if (e.includes('RGB 10')) setRgb10(!rgb10)
    }
    const rgbHandleValue = (e, value) => {
        try {
            let a = []
            if (e[0] == 'R') {
                a = eval('valRgb' + e[1])
                a[0] = value
            }
            if (e[0] == 'G') {
                a = eval('valRgb' + e[1])
                a[1] = value
            }
            if (e[0] == 'B') {
                a = eval('valRgb' + e[1])
                a[2] = value
            }
            switch (e[1]) {
                case '1':
                    setValRgb1(a)
                    break
                case '2':
                    setValRgb2(a)
                    break
                case '3':
                    setValRgb3(a)
                    break
                case '4':
                    setValRgb4(a)
                    break
                case '5':
                    setValRgb5(a)
                    break
                case '6':
                    setValRgb6(a)
                    break
                case '7':
                    setValRgb7(a)
                    break
                case '8':
                    setValRgb8(a)
                    break
                case '9':
                    setValRgb9(a)
                    break
                case '10':
                    setValRgb10(a)
                    break
            }
        } catch (e) {}
    }
    var totalSliders = []
    for (var i = 1; i <= countRGBComp; i++) {
        console.log('loopRGB', countRGBComp)

        var slidr = (
            <>
                <div
                    className="slider-item1-flowchart"
                    style={{ position: 'relative' }}
                >
                    <div className="portDetails-flowchart">
                        <div
                            id={i}
                            onClick={(e) => rgbHandle(e.target.innerHTML)}
                            className={
                                'renderClick' +
                                (eval('rgb' + i) || false) +
                                '  checkBox-conatiner'
                            }
                        >
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                RGB {i}
                            </p>
                        </div>
                    </div>
                    {/* <input type ="checkbox" id="re" checked={valReye} onChange={() => onChange("reye")}></input>      
                <span className="hardwareText">Right Eye</span> */}

                    <div
                        className={
                            'portSlider-flowchart' +
                            ' isActivePortInfo' +
                            (eval('rgb' + i) || false)
                        }
                        style={{
                            position: 'relative',
                            height: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                value={eval('valRgb' + i + '[0]') || 0}
                                min={0}
                                max={100}
                                renderIn="hardwarePropertyPanel"
                                sliderName={`R${i}`}
                                onChange={(e, id) => rgbHandleValue(id, e)}
                                disabled={!eval('rgb' + i)}
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '33%',
                                    fontSize: '16px',
                                }}
                            >
                                0
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',

                                    fontSize: '16px',
                                    right: '13%',
                                }}
                            >
                                100
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                id={i}
                                value={eval('valRgb' + i + '[1]') || 0}
                                min={0}
                                max={100}
                                renderIn="hardwarePropertyPanel"
                                sliderName={`G${i}`}
                                onChange={(e, id) => rgbHandleValue(id, e)}
                                disabled={!eval('rgb' + i)}
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '33%',
                                    fontSize: '16px',
                                }}
                            >
                                0
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',

                                    fontSize: '16px',
                                    right: '13%',
                                }}
                            >
                                100
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                value={eval('valRgb' + i + '[2]') || 0}
                                min={0}
                                max={100}
                                renderIn="hardwarePropertyPanel"
                                sliderName={`B${i}`}
                                onChange={(e, id) => rgbHandleValue(id, e)}
                                disabled={!eval('rgb' + i)}
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '33%',
                                    fontSize: '16px',
                                }}
                            >
                                0
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '55%',

                                    fontSize: '16px',
                                    right: '13%',
                                }}
                            >
                                100
                            </p>
                        </div>
                    </div>
                </div>
                <br></br>
            </>
        )

        totalSliders = [...totalSliders, slidr]
    }

    if (countRGBComp == 10) {
        styleAdd = {
            ...styleAdd,
            backgroundImage: `url(${renderImage('add3xIA')}`,
        }
    }
    if (countRGBComp == 1) {
        styleRemove = {
            ...styleRemove,
            backgroundImage: `url(${renderImage('remove3xIA')}`,
        }
    }

    const handleClick = (actionName) => {
        console.log('working', actionName)
        if (actionName === 'Frequency') {
            setActiveName('Frequency')
            setvalBuzz(0)
            setFrequencyBuzzChk(true)
            setToneBuzzChk(false)
        } else {
            setToneBuzzChk(true)
            setActiveName('Tone')
            setvalBuzz(0)
            setFrequencyBuzzChk(false)
        }
    }

    const isPortOutput = () => {
        if (
            (A1 && a1Checked) ||
            (A2 && a2Checked) ||
            (B1 && b1Checked) ||
            (B2 && b2Checked) ||
            (C1 && c1Checked) ||
            (C2 && c2Checked) ||
            D1 == true ||
            D2 == true ||
            M1 == true ||
            M2 == true ||
            M3 == true ||
            M4 == true ||
            F1 == true ||
            F2 == true ||
            isBuzzer == true ||
            isSmileOne == true ||
            isSmileTwo == true ||
            isSmileThree == true ||
            isSmileFour == true ||
            isTouchOneOutput == true ||
            isTouchTwoOutput == true ||
            isTouchZeroOutput == true ||
            isTouchThreeOutput == true ||
            isEyeLeft == true ||
            isInternalEye == true ||
            isEyeRight == true ||
            startButtonState[0].isEnabled ||
            startButtonState[1].isEnabled ||
            startButtonState[2].isEnabled ||
            startButtonState[3].isEnabled ||
            startButtonState[4].isEnabled ||
            startButtonState[6].isEnabled
        ) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="outertabDiv-output">
            {/* {A1 === false &&
            B1 === false &&
            isBuzzer === false &&
            isTouchOneOutput === false &&
            isTouchTwoOutput === false &&
            isSmileOne === false &&
            isSmileTwo === false &&
            isSmileThree === false &&
            isSmileFour === false &&
            isEyeLeft === false &&
            isEyeRight === false ? (
                <div className="outertabDiv-outputText">
                    <p>
                        Please enable or connect an ouput accessory to play
                        computer
                        <span className="subText">
                            <br />
                            to see hardware property panel options{' '}
                        </span>
                    </p>
                </div>
            ) : null} */}
            {/* laxmi */}

            {/* {isActivehumanoidPort() == false ? (
                <div className="outertabDiv-outputText">
                    <p>
                        Please enable or connect an ouput accessory to play
                        computer
                        <span className="subText">
                            <br />
                            to see hardware property panel options{' '}
                        </span>
                    </p>
                </div>
            ) : null} */}
            <div className="slider-section">
                {connectedDevice == 'Ace' && (
                    <>
                        {startButtonState &&
                            [
                                {
                                    var1: 'bic1Chk',
                                    var2: 'bic1Val',
                                    btnTxt: 'Bicounter1',
                                    min: -1,
                                    max: 1,
                                    id: 0,
                                },
                                {
                                    var1: 'bic2Chk',
                                    var2: 'bic2Val',
                                    btnTxt: 'Bicounter2',
                                    min: -1,
                                    max: 1,
                                    id: 1,
                                },
                                {
                                    var1: 'bif1Chk',
                                    var2: 'bif1Val',
                                    btnTxt: 'Biflag1',
                                    min: 0,
                                    max: 1,
                                    id: 3,
                                },
                                {
                                    var1: 'bif2Chk',
                                    var2: 'bif2Val',
                                    btnTxt: 'Biflag2',
                                    min: 0,
                                    max: 1,
                                    id: 4,
                                },
                                {
                                    var1: 'usbTxChk',
                                    var2: 'usbTxVal',
                                    btnTxt: 'USB TX',
                                    min: 0,
                                    max: 255,
                                    id: 6,
                                },
                                {
                                    var1: 'btTx1Chk',
                                    var2: 'btTx1Val',
                                    btnTxt: 'BT TX 1',
                                    min: 0,
                                    max: 255,
                                    id: 2,
                                },
                                {
                                    var1: 'btTx2Chk',
                                    var2: 'btTx2Val',
                                    btnTxt: 'BT TX 2',
                                    min: 0,
                                    max: 255,
                                    id: 2,
                                },
                                {
                                    var1: 'btTx3Chk',
                                    var2: 'btTx3Val',
                                    btnTxt: 'BT TX 3',
                                    min: 0,
                                    max: 255,
                                    id: 2,
                                },
                                {
                                    var1: 'btTx4Chk',
                                    var2: 'btTx4Val',
                                    btnTxt: 'BT TX 4',
                                    min: 0,
                                    max: 255,
                                    id: 2,
                                },
                                {
                                    var1: 'btTx5Chk',
                                    var2: 'btTx5Val',
                                    btnTxt: 'BT TX 5',
                                    min: 0,
                                    max: 255,
                                    id: 2,
                                },
                            ].map((item, index) => {
                                const { var1, var2, btnTxt, min, max, id } =
                                    item
                                if (!startButtonState[id].isEnabled) return
                                return (
                                    <div
                                        className="slider-item1-flowchart"
                                        style={{ position: 'relative' }}
                                        key={var1}
                                    >
                                        <div className="portDetails-flowchart">
                                            <div
                                                id={var1}
                                                key={var1}
                                                onClick={() => onChange(var1)}
                                                className={
                                                    'renderClick' +
                                                    (eval(var1) || false) +
                                                    ' checkBox-conatiner'
                                                }
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {btnTxt}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                'portSlider-flowchart' +
                                                ' isActivePortInfo' +
                                                (eval(var1) || false)
                                            }
                                        >
                                            <Slider
                                                title="Intensity"
                                                value={eval(var2) || 0}
                                                min={min}
                                                max={max}
                                                renderIn="hardwarePropertyPanel"
                                                onChange={(value) =>
                                                    onChange(var2, value)
                                                }
                                                disabled={!eval(var1)}
                                            />
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '33%',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                {min}
                                            </p>
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    fontSize: '16px',
                                                    right: '13%',
                                                }}
                                            >
                                                {max}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}

                        {A1 && !aUltra ? (
                            <div>
                                {(a1Servo || a1Servo270) && a1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="A1"
                                                    onClick={() =>
                                                        onChange('a1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (a1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        A1 Servo{' '}
                                                        {a1Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (a1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        a1Checkbox == true
                                                            ? valA1
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={a1Servo270 ? 270 : 180}
                                                    disabled={!a1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        a1Checkbox == true
                                                            ? onChange(
                                                                  'a1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {a1Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : a1Servo360 && a1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="A1"
                                                    onClick={() =>
                                                        onChange('a1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (a1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        A1 Servo 360
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (a1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        a1Checkbox == true
                                                            ? valA1
                                                            : 0
                                                    }
                                                    min={-100}
                                                    max={100}
                                                    disabled={!a1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        a1Checkbox == true
                                                            ? onChange(
                                                                  'a1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : a1Checked ? (
                                    <>
                                        {!a1Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="A1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'a1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (a1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                A1 Digital
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                        {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                        <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (a1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                a1Checkbox ==
                                                                true
                                                                    ? valA1
                                                                    : 0
                                                            }
                                                            // value={valA1}
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !a1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                a1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'a1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="A1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'a1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (a1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                A1 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/*        <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      <span className="hardwareText">A1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (a1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                a1Checkbox ==
                                                                true
                                                                    ? valA1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                a1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'a1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !a1Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}

                        {A2 && !aUltra ? (
                            <div>
                                {(a2Servo || a2Servo270) && a2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="A2"
                                                    onClick={() =>
                                                        onChange('a2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (a2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        A2 Servo{' '}
                                                        {a2Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (a2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        a2Checkbox == true
                                                            ? valA2
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={a2Servo270 ? 270 : 180}
                                                    disabled={!a2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        a2Checkbox == true
                                                            ? onChange(
                                                                  'a2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {a2Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : a2Servo360 && a2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="A2"
                                                    onClick={() =>
                                                        onChange('a2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (a2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        A2 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (a2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        a2Checkbox == true
                                                            ? valA2
                                                            : 0
                                                    }
                                                    min={-100}
                                                    max={100}
                                                    disabled={!a2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        a2Checkbox == true
                                                            ? onChange(
                                                                  'a2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : a2Checked ? (
                                    <>
                                        {' '}
                                        {!a2Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="A2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'a2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (a2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                A2 Digital
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="A2" checked={a2Checkbox} onChange={() => onChange("a2Checkbox")}></input>
                    <span className="hardwareText">A2 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (a2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                a1Checkbox ==
                                                                true
                                                                    ? valA2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                a2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'a2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !a2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="A2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'a2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (a2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                A2 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="A2" checked={a2Checkbox} onChange={() => onChange("a2Checkbox")}></input>
                    <span className="hardwareText">A2 Analog</span> */}

                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (a2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                a2Checkbox ==
                                                                true
                                                                    ? valA2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                a2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'a2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !a2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}

                        {MP3 && B1 && B2 ? (
                            <>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="MP3"
                                            onClick={() =>
                                                onChange('mp3Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (mp3Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port B: MP3
                                            </p>
                                        </div>
                                    </div>
                                    {/* <input type ="checkbox" id="b1" checked={b1Checkbox} onChange={() => onChange("b1Checkbox")}></input>
                    <span className="hardwareText">B1 Digital</span> */}
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (mp3Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                mp3Checkbox == true ? valMP3 : 0
                                            }
                                            min={0}
                                            max={255}
                                            disabled={!mp3Checkbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                mp3Checkbox == true
                                                    ? onChange('mp3', value)
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            255
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : RGB && B1 && B2 ? (
                            <>
                                {totalSliders}
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: ' repeat(2, 75px)',
                                        marginLeft: '50%',
                                        marginBottom: '5%',
                                    }}
                                >
                                    <div
                                        style={styleAdd}
                                        onClick={onRGBCompIncrease}
                                    ></div>
                                    <div
                                        style={styleRemove}
                                        onClick={onRGBCompDecrease}
                                    ></div>
                                </div>
                            </>
                        ) : (
                            <>
                                {B1 ? (
                                    <div>
                                        {(b1Servo || b1Servo270) &&
                                        b1Checked ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="B1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'b1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (b1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                B1 Servo
                                                                {b1Servo270
                                                                    ? '270'
                                                                    : '180'}
                                                                {/* <span
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    marginTop:
                                                                        '15%',
                                                                }}
                                                                className="subText2"
                                                            >
                                                                <br />
                                                                B1{' '}
                                                            </span> */}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                          {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                          <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (b1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                b1Checkbox ==
                                                                true
                                                                    ? valB1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={
                                                                b1Servo270
                                                                    ? 270
                                                                    : 180
                                                            }
                                                            disabled={
                                                                !b1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                b1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'b1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            {b1Servo270
                                                                ? '270'
                                                                : '180'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : b1Servo360 && b1Checked ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="B1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'b1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (b1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                B1 SERVO 360{' '}
                                                                {/* <span
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    marginTop:
                                                                        '15%',
                                                                }}
                                                                className="subText2"
                                                            >
                                                                <br />
                                                                B1{' '}
                                                            </span> */}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                          {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                          <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (b1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                b1Checkbox ==
                                                                true
                                                                    ? valB1
                                                                    : 0
                                                            }
                                                            min={-100}
                                                            max={100}
                                                            disabled={
                                                                !b1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                b1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'b1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                left: '33%',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            -100
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : b1Checked ? (
                                            <>
                                                {!b1Digi ? (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="b1"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'b1Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (b1Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        B1
                                                                        Digital{' '}
                                                                        {/* <span
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            marginTop:
                                                                                '15%',
                                                                        }}
                                                                        className="subText2"
                                                                    >
                                                                        <br />
                                                                        B1{' '}
                                                                    </span> */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <input type ="checkbox" id="b1" checked={b1Checkbox} onChange={() => onChange("b1Checkbox")}></input>
                        <span className="hardwareText">B1 Digital</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (b1Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        b1Checkbox ==
                                                                        true
                                                                            ? valB1
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={1}
                                                                    disabled={
                                                                        !b1Checkbox
                                                                    }
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        b1Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'b1',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p className="num1">
                                                                    1
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="b1"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'b1Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (b1Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        B1
                                                                        Analog{' '}
                                                                        {/* <span
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            marginTop:
                                                                                '15%',
                                                                        }}
                                                                        className="subText2"
                                                                    >
                                                                        <br />
                                                                        B1{' '}
                                                                    </span> */}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* <input type ="checkbox" id="B1" checked={b1Checkbox} onChange={() => onChange("b1Checkbox")}></input>
                        <span className="hardwareText">B1 Analog</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (b1Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        b1Checkbox ==
                                                                        true
                                                                            ? valB1
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        b1Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'b1',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    disabled={
                                                                        !b1Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        top: '55%',
                                                                        fontSize:
                                                                            '16px',
                                                                        right: '13%',
                                                                    }}
                                                                >
                                                                    100
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : null}

                                {B2 ? (
                                    <div>
                                        {console.log(
                                            'UUUUUUUUU',
                                            b2Servo,
                                            b2Servo270
                                        )}
                                        {(b2Servo || b2Servo270) &&
                                        b2Checked ? (
                                            <>
                                                {' '}
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="B2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'b2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (b2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                B2 Servo{' '}
                                                                {b2Servo270
                                                                    ? '270'
                                                                    : '180'}
                                                                {/* <span
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    marginTop:
                                                                        '15%',
                                                                }}
                                                                className="subText2"
                                                            >
                                                                <br />
                                                                B2{' '}
                                                            </span> */}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                          {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                          <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (b2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                b2Checkbox ==
                                                                true
                                                                    ? valB2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={
                                                                b2Servo270
                                                                    ? 270
                                                                    : 180
                                                            }
                                                            disabled={
                                                                !b2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                b2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'b2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            {a1Servo270
                                                                ? '270'
                                                                : '180'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : b2Servo360 && b2Checked ? (
                                            <>
                                                {' '}
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="B2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'b2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (b2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                B2 SERVO 360{' '}
                                                                {/* <span
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    marginTop:
                                                                        '15%',
                                                                }}
                                                                className="subText2"
                                                            >
                                                                <br />
                                                                B2{' '}
                                                            </span> */}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                          {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                          <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (b2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                b2Checkbox ==
                                                                true
                                                                    ? valB2
                                                                    : 0
                                                            }
                                                            min={-100}
                                                            max={100}
                                                            disabled={
                                                                !b2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                b2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'b2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                left: '33%',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            -100
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : b2Checked ? (
                                            <>
                                                {!b2Digi ? (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="B2"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'b2Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (b2Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        B2
                                                                        Digital{' '}
                                                                        {/* <span
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            marginTop:
                                                                                '15%',
                                                                        }}
                                                                        className="subText2"
                                                                    >
                                                                        <br />
                                                                        B2{' '}
                                                                    </span> */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <input type ="checkbox" id="B2" checked={b2Checkbox} onChange={() => onChange("b2Checkbox")}></input>
                        <span className="hardwareText">B2 Digital</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (b2Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        b2Checkbox ==
                                                                        true
                                                                            ? valB2
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={1}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        b2Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'b2',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    disabled={
                                                                        !b2Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p className="num1">
                                                                    1
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="B2"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'b2Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (b2Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        B2
                                                                        Analog{' '}
                                                                        {/* <span
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            marginTop:
                                                                                '15%',
                                                                        }}
                                                                        className="subText2"
                                                                    >
                                                                        <br />
                                                                        B2{' '}
                                                                    </span> */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <input type ="checkbox" id="B2" checked={b2Checkbox} onChange={() => onChange("b2Checkbox")}></input>
                        <span className="hardwareText">B2 Analog</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (b2Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        b2Checkbox ==
                                                                        true
                                                                            ? valB2
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        b2Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'b2',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    disabled={
                                                                        !b2Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        top: '55%',
                                                                        fontSize:
                                                                            '16px',
                                                                        right: '13%',
                                                                    }}
                                                                >
                                                                    100
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : null}
                            </>
                        )}

                        {C1 && !cUltra ? (
                            <div>
                                {' '}
                                {(c1Servo || c1Servo270) && c1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="C1"
                                                    onClick={() =>
                                                        onChange('c1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (c1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        C1 Servo{' '}
                                                        {c1Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (c1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        c1Checkbox == true
                                                            ? valC1
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={c1Servo270 ? 270 : 180}
                                                    disabled={!c1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        c1Checkbox == true
                                                            ? onChange(
                                                                  'c1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {c1Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : c1Servo360 && c1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="C1"
                                                    onClick={() =>
                                                        onChange('c1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (c1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        C1 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (c1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        c1Checkbox == true
                                                            ? valC1
                                                            : 0
                                                    }
                                                    min={-100}
                                                    max={100}
                                                    disabled={!c1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        c1Checkbox == true
                                                            ? onChange(
                                                                  'c1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : c1Checked ? (
                                    <>
                                        {' '}
                                        {!c1Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="C1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'c1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (c1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                C1 Digital
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="C1" checked={c1Checkbox} onChange={() => onChange("c1Checkbox")}></input>
                    <span className="hardwareText">C1 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (c1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                c1Checkbox ==
                                                                true
                                                                    ? valC1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !c1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                c1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'c1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="C1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'c1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (c1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                C1 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="C1" checked={c1Checkbox} onChange={() => onChange("c1Checkbox")}></input>
                    <span className="hardwareText">C1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (c1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                c1Checkbox ==
                                                                true
                                                                    ? valC1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                c1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'c1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !c1Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}

                        {C2 && !cUltra ? (
                            <div>
                                {(c2Servo || c2Servo270) && c2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="C2"
                                                    onClick={() =>
                                                        onChange('c2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (c2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        C2 Servo{' '}
                                                        {c2Servo270 ? 270 : 180}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (c2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        c2Checkbox == true
                                                            ? valC2
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={c2Servo270 ? 270 : 180}
                                                    disabled={!c2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        c2Checkbox == true
                                                            ? onChange(
                                                                  'c2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {c2Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : c2Servo360 && c2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="C2"
                                                    onClick={() =>
                                                        onChange('c2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (c2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        C2 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (c2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        c2Checkbox == true
                                                            ? valC2
                                                            : 0
                                                    }
                                                    min={-100}
                                                    max={100}
                                                    disabled={!c2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        c2Checkbox == true
                                                            ? onChange(
                                                                  'c2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : c2Checked ? (
                                    <>
                                        {!c2Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="C2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'c2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (c2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                C2 Digital
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="C2" checked={c2Checkbox} onChange={() => onChange("c2Checkbox")}></input>
                    <span className="hardwareText">C2 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (c2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                c1Checkbox ==
                                                                true
                                                                    ? valC2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                c2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'c2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            diagonal={
                                                                !c2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="C2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'c2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (c2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                C2 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="C2" checked={c2Checkbox} onChange={() => onChange("c2Checkbox")}></input>
                    <span className="hardwareText">C2 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (c2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                c2Checkbox ==
                                                                true
                                                                    ? valC2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                c2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'c2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !c2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}

                        {D1 && D2 && OLED ? (
                            <>
                                <TextRow
                                    name={'OLED'}
                                    port={'D'}
                                    assign={isClickOLED1}
                                    key={'D'}
                                    handlecheckbox={onOLED1Handle}
                                    textValue={curValOLED1}
                                    //onChange={onChange}
                                    label={'OLED Line 1'}
                                    handleTextChange={onOLED1HandleText}
                                />
                                <TextRow
                                    name={'OLED'}
                                    port={'D'}
                                    assign={isClickOLED2}
                                    key={'D'}
                                    handlecheckbox={onOLED2Handle}
                                    textValue={curValOLED2}
                                    // onChange={onChange}
                                    label={'OLED Line 2'}
                                    handleTextChange={onOLED2HandleText}
                                />
                                <TextRow
                                    name={'OLED'}
                                    port={'D'}
                                    assign={isClickOLED3}
                                    key={'D'}
                                    handlecheckbox={onOLED3Handle}
                                    textValue={curValOLED3}
                                    // onChange={onChange}
                                    label={'OLED Line 3'}
                                    handleTextChange={onOLED3HandleText}
                                />
                                <SelectionRow
                                    name={'OLED'}
                                    port={'D'}
                                    assign={isClickOLED4}
                                    key={'D'}
                                    handlecheckbox={onOLED4Handle}
                                    selected={curValOLED4}
                                    textValue={curValOLED4}
                                    onChange={onChange}
                                    label={'OLED Line 4'}
                                    handleTextChange={onOLED4HandleText}
                                />
                            </>
                        ) : (
                            <>
                                {' '}
                                {d1Checked &&
                                D1 &&
                                !(
                                    JSON.parse(
                                        sessionStorage.getItem('isColorSensor')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            'isGestureSensor'
                                        )
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            'isDistanceSensors'
                                        )
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('isLightSensor')
                                    )
                                ) ? (
                                    <div>
                                        {d1Servo || d1Servo270 ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="d1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'd1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (d1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                D1 Servo{' '}
                                                                {d1Servo270
                                                                    ? '270'
                                                                    : '180'}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (d1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                d1Checkbox ==
                                                                true
                                                                    ? valD1
                                                                    : 0
                                                            }
                                                            // value={valD1}
                                                            min={0}
                                                            max={
                                                                d1Servo270
                                                                    ? 270
                                                                    : 180
                                                            }
                                                            disabled={
                                                                !d1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                d1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'd1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            {d1Servo270
                                                                ? '270'
                                                                : '180'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : d1Servo360 ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="d1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'd1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (d1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                D1 Servo 360
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (d1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                d1Checkbox ==
                                                                true
                                                                    ? valD1
                                                                    : 0
                                                            }
                                                            // value={valD1}
                                                            min={-100}
                                                            max={100}
                                                            disabled={
                                                                !d1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                d1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'd1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                left: '33%',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            -100
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {!d1Digi ? (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="D1"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'd1Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (d1Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        D1
                                                                        Digital
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (d1Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        d1Checkbox ==
                                                                        true
                                                                            ? valD1
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={1}
                                                                    disabled={
                                                                        !d1Checkbox
                                                                    }
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        d1Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'd1',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p className="num1">
                                                                    1
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="D1"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'd1Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (d1Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        D1
                                                                        Analog
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (d1Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        d1Checkbox ==
                                                                        true
                                                                            ? valD1
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        d1Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'd1',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    disabled={
                                                                        !d1Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        top: '55%',

                                                                        fontSize:
                                                                            '16px',
                                                                        right: '13%',
                                                                    }}
                                                                >
                                                                    100
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : null}
                                {d2Checked &&
                                D2 &&
                                !JSON.parse(
                                    sessionStorage.getItem('isColorSensor')
                                ) &&
                                !(
                                    JSON.parse(
                                        sessionStorage.getItem('isColorSensor')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            'isGestureSensor'
                                        )
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            'isDistanceSensors'
                                        )
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('isLightSensor')
                                    )
                                ) ? (
                                    <div>
                                        {d2Servo || d2Servo270 ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="D2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'd2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (d2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                D2 Servo{' '}
                                                                {d2Servo270
                                                                    ? '270'
                                                                    : '180'}
                                                            </p>
                                                            {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (d2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                d2Checkbox ==
                                                                true
                                                                    ? valD2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={
                                                                d2Servo270
                                                                    ? 270
                                                                    : 180
                                                            }
                                                            disabled={
                                                                !d2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                d2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'd2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            {d2Servo270
                                                                ? '270'
                                                                : '180'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : d2Servo360 ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="D2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'd2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (d2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                D2 Servo 360
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (d2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                d2Checkbox ==
                                                                true
                                                                    ? valD2
                                                                    : 0
                                                            }
                                                            min={-100}
                                                            max={100}
                                                            disabled={
                                                                !d2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                d2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'd2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',
                                                                left: '33%',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        >
                                                            -100
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {!d2Digi ? (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="D2"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'd2Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (d2Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        D2
                                                                        Digital
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* <input type ="checkbox" id="D2" checked={d2Checkbox} onChange={() => onChange("d2Checkbox")}></input>
                    <span className="hardwareText">D2 Digital</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (d2Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        d2Checkbox ==
                                                                        true
                                                                            ? valD2
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={1}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        d2Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'd2',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    diagonal={
                                                                        !d2Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p className="num1">
                                                                    1
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="slider-item1-flowchart"
                                                            style={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            <div className="portDetails-flowchart">
                                                                <div
                                                                    id="D2"
                                                                    onClick={() =>
                                                                        onChange(
                                                                            'd2Checkbox'
                                                                        )
                                                                    }
                                                                    className={
                                                                        'renderClick' +
                                                                        (d2Checkbox ||
                                                                            false) +
                                                                        '  checkBox-conatiner'
                                                                    }
                                                                >
                                                                    <p
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                        }}
                                                                    >
                                                                        D2
                                                                        Analog
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* <input type ="checkbox" id="D2" checked={d2Checkbox} onChange={() => onChange("d2Checkbox")}></input>
                    <span className="hardwareText">D2 Analog</span> */}
                                                            <div
                                                                className={
                                                                    'portSlider-flowchart' +
                                                                    ' isActivePortInfo' +
                                                                    (d2Checkbox ||
                                                                        false)
                                                                }
                                                            >
                                                                <Slider
                                                                    title="Intensity"
                                                                    value={
                                                                        d2Checkbox ==
                                                                        true
                                                                            ? valD2
                                                                            : 0
                                                                    }
                                                                    min={0}
                                                                    max={100}
                                                                    renderIn="hardwarePropertyPanel"
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        d2Checkbox ==
                                                                        true
                                                                            ? onChange(
                                                                                  'd2',
                                                                                  value
                                                                              )
                                                                            : null
                                                                    }
                                                                    diagonal={
                                                                        !d2Checkbox
                                                                    }
                                                                />
                                                                <p className="num0">
                                                                    0
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        top: '55%',

                                                                        fontSize:
                                                                            '16px',
                                                                        right: '13%',
                                                                    }}
                                                                >
                                                                    100
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : null}
                            </>
                        )}

                        {E1 ? (
                            <div>
                                {(e1Servo || e1Servo270) && e1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="e1"
                                                    onClick={() =>
                                                        onChange('e1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (e1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        E1 Servo{' '}
                                                        {e1Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (e1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        e1Checkbox == true
                                                            ? valE1
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={0}
                                                    max={e1Servo270 ? 270 : 180}
                                                    disabled={!e1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        e1Checkbox == true
                                                            ? onChange(
                                                                  'e1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {e1Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : e1Servo360 && e1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="e1"
                                                    onClick={() =>
                                                        onChange('e1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (e1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        E1 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (e1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        e1Checkbox == true
                                                            ? valE1
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={-100}
                                                    max={100}
                                                    disabled={!e1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        e1Checkbox == true
                                                            ? onChange(
                                                                  'e1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : e1Checked ? (
                                    <>
                                        {' '}
                                        {!e1Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="E1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'e1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (e1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                E1 Digital
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (e1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                e1Checkbox ==
                                                                true
                                                                    ? valE1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !e1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                e1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'e1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="E1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'e1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (e1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                E1 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (e1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                e1Checkbox ==
                                                                true
                                                                    ? valE1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                e1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'e1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !e1Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                        {E2 ? (
                            <div>
                                {(e2Servo || e2Servo270) && e2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="e2"
                                                    onClick={() =>
                                                        onChange('e2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (e2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        E2 Servo{' '}
                                                        {e2Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (e2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        e2Checkbox == true
                                                            ? valE2
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={0}
                                                    max={e2Servo270 ? 270 : 180}
                                                    disabled={!e2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        e2Checkbox == true
                                                            ? onChange(
                                                                  'e2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {e2Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : e2Servo360 && e2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="e2"
                                                    onClick={() =>
                                                        onChange('e2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (e2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        E2 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (e2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        e2Checkbox == true
                                                            ? valE2
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={-100}
                                                    max={100}
                                                    disabled={!e2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        e1Checkbox == true
                                                            ? onChange(
                                                                  'e2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : e2Checked ? (
                                    <>
                                        {!e2Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="E2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'e2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (e2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                E2 Digital
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (e2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                e2Checkbox ==
                                                                true
                                                                    ? valE2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !e2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                e2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'e2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="E2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'e2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (e2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                E2 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (e2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                e2Checkbox ==
                                                                true
                                                                    ? valE2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                e2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'e2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !e2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                        {F1 ? (
                            <div>
                                {(f1Servo || f1Servo270) && f1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="f1"
                                                    onClick={() =>
                                                        onChange('f1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (f1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        F1 Servo{' '}
                                                        {f1Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (f1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        f1Checkbox == true
                                                            ? valF1
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={0}
                                                    max={f1Servo270 ? 270 : 180}
                                                    disabled={!f1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        f1Checkbox == true
                                                            ? onChange(
                                                                  'f1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {f1Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : f1Servo360 && f1Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="f1"
                                                    onClick={() =>
                                                        onChange('f1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (f1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        F1 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (f1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        f1Checkbox == true
                                                            ? valF1
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={-100}
                                                    max={100}
                                                    disabled={!f1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        f1Checkbox == true
                                                            ? onChange(
                                                                  'f1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : f1Checked ? (
                                    <>
                                        {!f1Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="f1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'f1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (f1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                F1 Digital
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (f1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                f1Checkbox ==
                                                                true
                                                                    ? valF1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !f1Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                f1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'f1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="F1"
                                                            onClick={() =>
                                                                onChange(
                                                                    'f1Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (f1Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                F1 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (f1Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                f1Checkbox ==
                                                                true
                                                                    ? valF1
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                f1Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'f1',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !f1Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                        {F2 ? (
                            <div>
                                {(f2Servo || f2Servo270) && f2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="f2"
                                                    onClick={() =>
                                                        onChange('f2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (f2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        F2 Servo{' '}
                                                        {f2Servo270
                                                            ? '270'
                                                            : '180'}
                                                    </p>
                                                    {/*   <input type ="checkbox" id="A1" checked={a1Checkbox} onChange={() => onChange("a1Checkbox")}></input>
                      {console.log("checkbox@@@@@@@@",document.getElementById("A1"))}
                      <span className="hardwareText">A1 Digital</span> */}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (f2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        f2Checkbox == true
                                                            ? valF2
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={0}
                                                    max={f2Servo270 ? 270 : 180}
                                                    disabled={!f2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        f2Checkbox == true
                                                            ? onChange(
                                                                  'f2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    {f2Servo270 ? '270' : '180'}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : f2Servo360 && f2Checked ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="f2"
                                                    onClick={() =>
                                                        onChange('f2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (f2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        F2 Servo 360
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (f2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        f2Checkbox == true
                                                            ? valF2
                                                            : 0
                                                    }
                                                    // value={valD1}
                                                    min={-100}
                                                    max={100}
                                                    disabled={!f2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        f2Checkbox == true
                                                            ? onChange(
                                                                  'f2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">-100</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : f2Checked ? (
                                    <>
                                        {!f2Digi ? (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="F2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'f2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (f2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                F2 Digital
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (f2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                f2Checkbox ==
                                                                true
                                                                    ? valF2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={1}
                                                            disabled={
                                                                !f2Checkbox
                                                            }
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                f2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'f2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p className="num1">
                                                            1
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="slider-item1-flowchart"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="portDetails-flowchart">
                                                        <div
                                                            id="F2"
                                                            onClick={() =>
                                                                onChange(
                                                                    'f2Checkbox'
                                                                )
                                                            }
                                                            className={
                                                                'renderClick' +
                                                                (f2Checkbox ||
                                                                    false) +
                                                                '  checkBox-conatiner'
                                                            }
                                                        >
                                                            <p
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform:
                                                                        'translate(-50%, -50%)',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                F2 Analog
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                                    <div
                                                        className={
                                                            'portSlider-flowchart' +
                                                            ' isActivePortInfo' +
                                                            (f2Checkbox ||
                                                                false)
                                                        }
                                                    >
                                                        <Slider
                                                            title="Intensity"
                                                            value={
                                                                f2Checkbox ==
                                                                true
                                                                    ? valF2
                                                                    : 0
                                                            }
                                                            min={0}
                                                            max={100}
                                                            renderIn="hardwarePropertyPanel"
                                                            onChange={(value) =>
                                                                f2Checkbox ==
                                                                true
                                                                    ? onChange(
                                                                          'f2',
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                            disabled={
                                                                !f2Checkbox
                                                            }
                                                        />
                                                        <p className="num0">
                                                            0
                                                        </p>
                                                        <p
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '55%',

                                                                fontSize:
                                                                    '16px',
                                                                right: '13%',
                                                            }}
                                                        >
                                                            100
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                        {m1Checked && M1 && !serialServoM4 ? (
                            <div>
                                {!m1Digi ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M1"
                                                    onClick={() =>
                                                        onChange('m1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L1 Digital
                                                    </p>
                                                </div>
                                            </div>

                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m1Checkbox == true
                                                            ? valM1
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={1}
                                                    disabled={!m1Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m1Checkbox == true
                                                            ? onChange(
                                                                  'm1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p className="num1">1</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M1"
                                                    onClick={() =>
                                                        onChange('m1Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m1Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L1 Analog
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m1Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m1Checkbox == true
                                                            ? valM1
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m1Checkbox == true
                                                            ? onChange(
                                                                  'm1',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                    disabled={!m1Checkbox}
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}

                        {m2Checked && M2 ? (
                            <div>
                                {!m2Digi ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M2"
                                                    onClick={() =>
                                                        onChange('m2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L2 Digital
                                                    </p>
                                                </div>
                                            </div>

                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m2Checkbox == true
                                                            ? valM2
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={1}
                                                    disabled={!m2Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m2Checkbox == true
                                                            ? onChange(
                                                                  'm2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p className="num1">1</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M2"
                                                    onClick={() =>
                                                        onChange('m2Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m2Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L2 Analog
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m2Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m2Checkbox == true
                                                            ? valM2
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m2Checkbox == true
                                                            ? onChange(
                                                                  'm2',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                    disabled={!m2Checkbox}
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}

                        {/* M3 start */}

                        {m3Checked && M3 ? (
                            <div>
                                {!m3Digi ? (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M3"
                                                    onClick={() =>
                                                        onChange('m3Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m3Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L3 Digital
                                                    </p>
                                                </div>
                                            </div>

                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m3Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m3Checkbox == true
                                                            ? valM3
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={1}
                                                    disabled={!m3Checkbox}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m3Checkbox == true
                                                            ? onChange(
                                                                  'm3',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                />
                                                <p className="num0">0</p>
                                                <p className="num1">1</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="slider-item1-flowchart"
                                            style={{ position: 'relative' }}
                                        >
                                            <div className="portDetails-flowchart">
                                                <div
                                                    id="M3"
                                                    onClick={() =>
                                                        onChange('m3Checkbox')
                                                    }
                                                    className={
                                                        'renderClick' +
                                                        (m3Checkbox || false) +
                                                        '  checkBox-conatiner'
                                                    }
                                                >
                                                    <p
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform:
                                                                'translate(-50%, -50%)',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        L3 Analog
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                            <div
                                                className={
                                                    'portSlider-flowchart' +
                                                    ' isActivePortInfo' +
                                                    (m3Checkbox || false)
                                                }
                                            >
                                                <Slider
                                                    title="Intensity"
                                                    value={
                                                        m3Checkbox == true
                                                            ? valM3
                                                            : 0
                                                    }
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) =>
                                                        m1Checkbox == true
                                                            ? onChange(
                                                                  'm3',
                                                                  value
                                                              )
                                                            : null
                                                    }
                                                    disabled={!m3Checkbox}
                                                />
                                                <p className="num0">0</p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',

                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}

                        {armShieldMP3 == true ? (
                            <>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            // id="M3"
                                            onClick={() =>
                                                onChange('armShieldCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (armShieldCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                ARM shield MP3
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (armShieldCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                armShieldCheckbox == true
                                                    ? valArmShield
                                                    : 0
                                            }
                                            min={0}
                                            max={255}
                                            disabled={!armShieldCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                armShieldCheckbox == true
                                                    ? onChange(
                                                          'armShield',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            255
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {m4Checked && M4 && !armShieldMP3 ? (
                            <div
                                className="slider-item1-flowchart"
                                style={{ position: 'relative' }}
                            >
                                {!m4Digi ? (
                                    <>
                                        <div className="portDetails-flowchart">
                                            <div
                                                id="M4"
                                                onClick={() =>
                                                    onChange('m4Checkbox')
                                                }
                                                className={
                                                    'renderClick' +
                                                    (m4Checkbox || false) +
                                                    '  checkBox-conatiner'
                                                }
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    L4 Digital
                                                </p>
                                            </div>
                                        </div>

                                        {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Digital</span> */}
                                        <div
                                            className={
                                                'portSlider-flowchart' +
                                                ' isActivePortInfo' +
                                                (m4Checkbox || false)
                                            }
                                        >
                                            <Slider
                                                title="Intensity"
                                                value={
                                                    m4Checkbox == true
                                                        ? valM4
                                                        : 0
                                                }
                                                min={0}
                                                max={1}
                                                disabled={!m4Checkbox}
                                                renderIn="hardwarePropertyPanel"
                                                onChange={(value) =>
                                                    m4Checkbox == true
                                                        ? onChange('m4', value)
                                                        : null
                                                }
                                            />
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '33%',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                0
                                            </p>
                                            <p className="num1">1</p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="portDetails-flowchart">
                                            <div
                                                id="M4"
                                                onClick={() =>
                                                    onChange('m4Checkbox')
                                                }
                                                className={
                                                    'renderClick' +
                                                    (m4Checkbox || false) +
                                                    '  checkBox-conatiner'
                                                }
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    L4 Analog
                                                </p>
                                            </div>
                                        </div>
                                        {/* <input type ="checkbox" id="D1" checked={d1Checkbox} onChange={() => onChange("d1Checkbox")}></input>
                    <span className="hardwareText">D1 Analog</span> */}
                                        <div
                                            className={
                                                'portSlider-flowchart' +
                                                ' isActivePortInfo' +
                                                (m4Checkbox || false)
                                            }
                                        >
                                            <Slider
                                                title="Intensity"
                                                value={
                                                    m4Checkbox == true
                                                        ? valM4
                                                        : 0
                                                }
                                                min={0}
                                                max={100}
                                                renderIn="hardwarePropertyPanel"
                                                onChange={(value) =>
                                                    m4Checkbox == true
                                                        ? onChange('m4', value)
                                                        : null
                                                }
                                                disabled={!m4Checkbox}
                                            />
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '33%',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                0
                                            </p>
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',

                                                    fontSize: '16px',
                                                    right: '13%',
                                                }}
                                            >
                                                100
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}

                        {s1Servo || s1Servo270 ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="A1"
                                            onClick={() =>
                                                onChange('s1ServoCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s1ServoCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                S1 Servo{' '}
                                                {s1Servo270 ? '270' : '180'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s1ServoCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s1ServoCheckbox == true
                                                    ? valS1Servo
                                                    : 0
                                            }
                                            min={0}
                                            max={s1Servo270 ? 270 : 180}
                                            disabled={!s1ServoCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s1ServoCheckbox == true
                                                    ? onChange(
                                                          'valS1Servo',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            {s1Servo270 ? '270' : '180'}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {s1Servo360 ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="A1"
                                            onClick={() =>
                                                onChange('s1ServoCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s1ServoCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                S1 Servo 360
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s1ServoCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s1ServoCheckbox == true
                                                    ? valS1Servo
                                                    : 0
                                            }
                                            min={-100}
                                            max={100}
                                            disabled={!s1ServoCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s1ServoCheckbox == true
                                                    ? onChange(
                                                          'valS1Servo',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            -100
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {s2Servo || s2Servo270 ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="A1"
                                            onClick={() =>
                                                onChange('s2ServoCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s2ServoCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                S2 Servo{' '}
                                                {s2Servo270 ? '270' : '180'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s2ServoCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s2ServoCheckbox == true
                                                    ? valS2Servo
                                                    : 0
                                            }
                                            min={0}
                                            max={s2Servo270 ? 270 : 180}
                                            disabled={!s2ServoCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s2ServoCheckbox == true
                                                    ? onChange(
                                                          'valS2Servo',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            {s2Servo270 ? '270' : '180'}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {s2Servo360 ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="A1"
                                            onClick={() =>
                                                onChange('s2ServoCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s2ServoCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                S2 Servo 360
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s2ServoCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s2ServoCheckbox == true
                                                    ? valS2Servo
                                                    : 0
                                            }
                                            min={-100}
                                            max={100}
                                            disabled={!s2ServoCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s2ServoCheckbox == true
                                                    ? onChange(
                                                          'valS2Servo',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            -100
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {/*  m4 end */}
                        {isTouchZeroOutput ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="T0"
                                            onClick={() =>
                                                onChange('t0Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (t0Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                TouchPad Zero
                                            </p>
                                        </div>
                                    </div>

                                    {/* <input type ="checkbox" id="T0" checked={t0Checkbox} onChange={() => onChange("t0Checkbox")}></input>      
                <span className="hardwareText">TouchPad Zero</span> */}
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (t0Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                t0Checkbox == true ? valT0 : 0
                                            }
                                            min={0}
                                            max={1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                t0Checkbox == true
                                                    ? onChange('t0', value)
                                                    : null
                                            }
                                            disabled={!t0Checkbox}
                                        />
                                        <p className="num0">0</p>
                                        <p className="num1">1</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isTouchOneOutput ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="T1"
                                            onClick={() =>
                                                onChange('t1Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (t1Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                TouchPad One
                                            </p>
                                        </div>
                                    </div>
                                    {/* <input type ="checkbox" id="T1" checked={t1Checkbox} onChange={() => onChange("t1Checkbox")}></input>      
                <span className="hardwareText">TouchPad One</span> */}
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (t1Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                t1Checkbox == true ? valT1 : 0
                                            }
                                            min={0}
                                            max={1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                t1Checkbox == true
                                                    ? onChange('t1', value)
                                                    : null
                                            }
                                            disabled={!t1Checkbox}
                                        />
                                        <p className="num0">0</p>
                                        <p className="num1">1</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isTouchTwoOutput ? (
                            <div>
                                <div>
                                    <div
                                        className="slider-item1-flowchart"
                                        style={{ position: 'relative' }}
                                    >
                                        <div className="portDetails-flowchart">
                                            <div
                                                id="T2"
                                                onClick={() =>
                                                    onChange('t2Checkbox')
                                                }
                                                className={
                                                    'renderClick' +
                                                    (t2Checkbox || false) +
                                                    ' checkBox-conatiner'
                                                }
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    TouchPad Two
                                                </p>
                                            </div>
                                        </div>

                                        {/* <input type ="checkbox" id="T2" checked={t2Checkbox} onChange={() => onChange("t2Checkbox")}></input>      
                <span className="hardwareText">TouchPad Two</span> */}
                                        <div
                                            className={
                                                'portSlider-flowchart' +
                                                ' isActivePortInfo' +
                                                (t2Checkbox || false)
                                            }
                                        >
                                            <Slider
                                                title="Intensity"
                                                value={
                                                    t2Checkbox == true
                                                        ? valT2
                                                        : 0
                                                }
                                                min={0}
                                                max={1}
                                                renderIn="hardwarePropertyPanel"
                                                onChange={(value) =>
                                                    t1Checkbox == true
                                                        ? onChange('t2', value)
                                                        : null
                                                }
                                                disabled={!t2Checkbox}
                                            />
                                            <p className="num0">0</p>
                                            <p className="num1">1</p>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isTouchThreeOutput ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="T3"
                                            onClick={() =>
                                                onChange('t3Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (t3Checkbox || false) +
                                                ' checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                TouchPad Three
                                            </p>
                                        </div>
                                    </div>

                                    {/* <input type ="checkbox" id="T2" checked={t2Checkbox} onChange={() => onChange("t2Checkbox")}></input>      
                    <span className="hardwareText">TouchPad Two</span> */}
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (t3Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                t3Checkbox == true ? valT3 : 0
                                            }
                                            min={0}
                                            max={1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                t3Checkbox == true
                                                    ? onChange('t3', value)
                                                    : null
                                            }
                                            disabled={!t3Checkbox}
                                        />
                                        <p className="num0">0</p>
                                        <p className="num1">1</p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isInternalEye && (
                            <>
                                {valRGBEye.map((eye, index) => (
                                    <div
                                        className="slider-item1-flowchart"
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                        }}
                                    >
                                        <div className="portDetails-flowchart">
                                            <div
                                                id="le"
                                                onClick={() => {
                                                    const newState = [
                                                        ...valRGBEye,
                                                    ]
                                                    newState[index].isSelected =
                                                        !newState[index]
                                                            .isSelected
                                                    setValRGBEye(newState)
                                                }}
                                                className={
                                                    'renderClick' +
                                                    (eye.isSelected || false) +
                                                    '  checkBox-conatiner'
                                                }
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    RGB Eye {index + 1}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                'portSlider-flowchart' +
                                                ' isActivePortInfo' +
                                                (eye.isSelected || false)
                                            }
                                            style={{
                                                position: 'relative',
                                                height: '250px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                padding: '20px 0px 40px 0px ',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    height: 'auto',
                                                    // left: '10%',
                                                    width: '100%',
                                                }}
                                            >
                                                <Slider
                                                    title={null}
                                                    value={eye.valR || 0}
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) => {
                                                        const newState = [
                                                            ...valRGBEye,
                                                        ]
                                                        newState[index].valR =
                                                            value
                                                        setValRGBEye(newState)
                                                    }}
                                                    sliderName={'R'}
                                                    disabled={!eye.isSelected}
                                                />
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '62%',
                                                        left: '33%',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    0
                                                </p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',
                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    height: 'auto',
                                                    // left: '10%',
                                                    width: '100%',
                                                }}
                                            >
                                                <Slider
                                                    title={null}
                                                    value={eye.valG || 0}
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) => {
                                                        const newState = [
                                                            ...valRGBEye,
                                                        ]
                                                        newState[index].valG =
                                                            value
                                                        setValRGBEye(newState)
                                                    }}
                                                    sliderName={'G'}
                                                    disabled={!eye.isSelected}
                                                />
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '62%',
                                                        left: '33%',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    0
                                                </p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',
                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    height: 'auto',
                                                    // left: '10%',
                                                    width: '100%',
                                                }}
                                            >
                                                <Slider
                                                    title={null}
                                                    value={eye.valB || 0}
                                                    min={0}
                                                    max={100}
                                                    renderIn="hardwarePropertyPanel"
                                                    onChange={(value) => {
                                                        const newState = [
                                                            ...valRGBEye,
                                                        ]
                                                        newState[index].valB =
                                                            value
                                                        setValRGBEye(newState)
                                                    }}
                                                    sliderName={'B'}
                                                    disabled={!eye.isSelected}
                                                />
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '62%',
                                                        left: '33%',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    0
                                                </p>
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                        top: '55%',
                                                        fontSize: '16px',
                                                        right: '13%',
                                                    }}
                                                >
                                                    100
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}

                        {isEyeLeft ? (
                            <div
                                className="slider-item1-flowchart"
                                style={{ position: 'relative', height: 'auto' }}
                            >
                                <div className="portDetails-flowchart">
                                    <div
                                        id="eyel"
                                        onClick={() => onChange('leye')}
                                        className={
                                            'renderClick' +
                                            (valLeye || false) +
                                            '  checkBox-conatiner'
                                        }
                                    >
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Left Eye
                                        </p>
                                    </div>
                                </div>
                                {/* <input type ="checkbox" id="le" checked={valLeye} onChange={() => onChange("leye")}></input>      
                    <span className="hardwareText">Left Eye</span> */}
                                <div
                                    className={
                                        'portSlider-flowchart' +
                                        ' isActivePortInfo' +
                                        (valLeye || false)
                                    }
                                    style={{
                                        position: 'relative',
                                        height: '250px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        padding: '20px 0px 40px 0px ',
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            title={null}
                                            value={
                                                valLeye == true ? valLeyeR : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                valLeye == true
                                                    ? onChange('leyeR', value)
                                                    : null
                                            }
                                            sliderName={'R'}
                                            disabled={!valLeye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            title={null}
                                            value={
                                                valLeye == true ? valLeyeG : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                valLeye == true
                                                    ? onChange('leyeG', value)
                                                    : null
                                            }
                                            sliderName={'G'}
                                            disabled={!valLeye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            title={null}
                                            value={
                                                valLeye == true ? valLeyeB : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                valLeye == true
                                                    ? onChange('leyeB', value)
                                                    : null
                                            }
                                            sliderName={'B'}
                                            disabled={!valLeye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {isEyeRight ? (
                            <div
                                className="slider-item1-flowchart"
                                style={{ position: 'relative', height: 'auto' }}
                            >
                                <div className="portDetails-flowchart">
                                    <div
                                        id="re"
                                        onClick={() => onChange('reye')}
                                        className={
                                            'renderClick' +
                                            (valReye || false) +
                                            '  checkBox-conatiner'
                                        }
                                    >
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Right Eye
                                        </p>
                                    </div>
                                </div>
                                {/* <input type ="checkbox" id="re" checked={valReye} onChange={() => onChange("reye")}></input>      
                <span className="hardwareText">Right Eye</span> */}

                                <div
                                    className={
                                        'portSlider-flowchart' +
                                        ' isActivePortInfo' +
                                        (valReye || false)
                                    }
                                    style={{
                                        position: 'relative',
                                        height: '250px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        padding: '20px 0px 40px 0px',
                                        // padding: "22px 0 39px 0 !important",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            value={
                                                valReye == true ? valReyeR : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            sliderName={'R'}
                                            onChange={(value) =>
                                                valReye == true
                                                    ? onChange('reyeR', value)
                                                    : null
                                            }
                                            disabled={!valReye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            value={
                                                valReye == true ? valReyeG : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            sliderName={'G'}
                                            onChange={(value) =>
                                                valReye == true
                                                    ? onChange('reyeG', value)
                                                    : null
                                            }
                                            disabled={!valReye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    >
                                        <Slider
                                            value={
                                                valReye == true ? valReyeB : 0
                                            }
                                            min={0}
                                            max={100}
                                            renderIn="hardwarePropertyPanel"
                                            sliderName={'B'}
                                            onChange={(value) =>
                                                valReye == true
                                                    ? onChange('reyeB', value)
                                                    : null
                                            }
                                            disabled={!valReye}
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '62%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            100
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {isBuzzer ? (
                            <div
                                className="slider-item1-flowchart"
                                style={{ position: 'relative', height: '25vh' }}
                            >
                                <div className="portDetails-flowchart">
                                    <div
                                        id="Buzz"
                                        onClick={() => onChange('buzzCheckbox')}
                                        className={
                                            'renderClick' +
                                            (buzzCheckbox || false) +
                                            '  checkBox-conatiner'
                                        }
                                    >
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Buzzer
                                        </p>
                                    </div>
                                </div>
                                {/* <input type ="checkbox" id="Buzz" checked={buzzCheckbox} onChange={() => onChange("buzzCheckbox")}></input>
                    <span className="hardwareText">Buzzer</span> */}
                                <div
                                    className={
                                        'portSlider-flowchart' +
                                        ' isActivePortInfo' +
                                        (buzzCheckbox || false)
                                    }
                                    style={{
                                        paddingBottom: '0%',
                                        display: 'block',
                                    }}
                                >
                                    <div
                                        id={'activeBuzzer'}
                                        style={{
                                            width: '90%',
                                            height: '28%',
                                            position: 'absolute',
                                            top: '20%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '10px',
                                            background: 'rgb(255, 255, 255)',
                                            display: 'flex',
                                        }}
                                    >
                                        <button
                                            // className={"activeBuzzertrue"}
                                            style={{
                                                width: '50%',
                                                height: '100%',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',
                                                outline: 'inherit',
                                            }}
                                            className={`${
                                                isActiveName === 'Frequency'
                                                    ? 'activeBuzzertrue'
                                                    : 'activeBuzzerfalse'
                                            }`}
                                            value="as"
                                            onClick={() =>
                                                handleClick('Frequency')
                                            }
                                            disabled={!buzzCheckbox}
                                        >
                                            <p1 style={{ marginTop: '0.9%' }}>
                                                Frequency
                                            </p1>
                                        </button>
                                        <button
                                            // className={"activeBuzzerfalse"}
                                            style={{
                                                width: '50%',
                                                height: '100%',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',
                                                outline: 'inherit',
                                            }}
                                            className={`${
                                                isActiveName === 'Tone'
                                                    ? 'activeBuzzertrue'
                                                    : 'activeBuzzerfalse'
                                            }`}
                                            value="as"
                                            onClick={() => handleClick('Tone')}
                                            disabled={!buzzCheckbox}
                                        >
                                            <p1>Tone</p1>
                                        </button>{' '}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            top: '65%',
                                            left: '48%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                buzzCheckbox == true
                                                    ? valBuzz
                                                    : 0
                                            }
                                            min={0}
                                            max={
                                                isActiveName == 'Frequency'
                                                    ? 9000
                                                    : 5
                                            }
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                buzzCheckbox == true
                                                    ? onChange('buzz', value)
                                                    : null
                                            }
                                            disabled={!buzzCheckbox}
                                        />
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '70%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            0
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '70%',
                                                fontSize: '16px',
                                                right: '10%',
                                            }}
                                        >
                                            {isActiveName == 'Frequency'
                                                ? 9000
                                                : 5}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {isSmileOne ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="S1"
                                            onClick={() =>
                                                onChange('s1Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s1Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Smile LED One
                                            </p>
                                        </div>
                                    </div>
                                    {/* <input type ="checkbox" id="S1" checked={s1Checkbox} onChange={() => onChange("s1Checkbox")}></input>      
                <span className="hardwareText">Smile LED One</span> */}
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s1Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s1Checkbox == true ? valSm1 : 0
                                            }
                                            min={0}
                                            max={m1Digi ? 100 : 1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s1Checkbox == true
                                                    ? onChange('sm1', value)
                                                    : null
                                            }
                                            disabled={!s1Checkbox}
                                        />
                                        <p className="num0">0</p>

                                        <p className="num1">
                                            {m1Digi ? 100 : 1}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isSmileTwo ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="S2"
                                            onClick={() =>
                                                onChange('s2Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s2Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Smile LED Two
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s2Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s2Checkbox == true ? valSm2 : 0
                                            }
                                            min={0}
                                            max={m2Digi ? 100 : 1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s2Checkbox == true
                                                    ? onChange('sm2', value)
                                                    : null
                                            }
                                            disabled={!s2Checkbox}
                                        />
                                        <p className="num0">0</p>

                                        <p className="num1">
                                            {m2Digi ? 100 : 1}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isSmileThree ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="S3"
                                            onClick={() =>
                                                onChange('s3Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s3Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Smile LED Three
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s3Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s3Checkbox == true ? valSm3 : 0
                                            }
                                            min={0}
                                            max={m3Digi ? 100 : 1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s3Checkbox == true
                                                    ? onChange('sm3', value)
                                                    : null
                                            }
                                            disabled={!s3Checkbox}
                                        />
                                        <p className="num0">0</p>

                                        <p className="num1">
                                            {m3Digi ? 100 : 1}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {isSmileFour ? (
                            <div>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{ position: 'relative' }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="S4"
                                            onClick={() =>
                                                onChange('s4Checkbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (s4Checkbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Smile LED Four
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (s4Checkbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                s4Checkbox == true ? valSm4 : 0
                                            }
                                            min={0}
                                            max={m4Digi ? 100 : 1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                s4Checkbox == true
                                                    ? onChange('sm4', value)
                                                    : null
                                            }
                                            disabled={!s4Checkbox}
                                        />
                                        <p className="num0">0</p>

                                        <p className="num1">
                                            {m4Digi ? 100 : 1}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        ) : null}

                        {serialServoM4 == true ? (
                            <>
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M1"
                                            onClick={() =>
                                                onChange('serialServoCheckbox')
                                            }
                                            className={
                                                'renderClick' +
                                                (serialServoCheckbox || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Time
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (serialServoCheckbox || false)
                                        }
                                    >
                                        <Slider
                                            title="Intensity"
                                            value={
                                                serialServoCheckbox == true
                                                    ? valSerialServo
                                                    : 0
                                            }
                                            min={50}
                                            max={5000}
                                            disabled={!serialServoCheckbox}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                serialServoCheckbox == true
                                                    ? onChange(
                                                          'serialServo',
                                                          value
                                                      )
                                                    : null
                                            }
                                        />
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '33%',
                                                fontSize: '16px',
                                            }}
                                        >
                                            50
                                        </p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            5000
                                        </p>
                                    </div>
                                </div>

                                {/* PORT S1 */}
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M4"
                                            onClick={() =>
                                                onChange('m4Checkbox1')
                                            }
                                            className={
                                                'renderClick' +
                                                (m4Checkbox1 || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port S1 :
                                                {/* <br />
                                                        <span>
                                                            Serial Servo Motor
                                                        </span> */}
                                            </p>
                                            <br />
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Serial Servo Motor
                                                {/* <br />
                                                        <span>
                                                            Serial Servo Motor
                                                        </span> */}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (m4Checkbox1 || false)
                                        }
                                    >
                                        <Slider
                                            title="Value"
                                            value={
                                                m4Checkbox1 == true
                                                    ? valPSm1
                                                    : 0
                                            }
                                            min={50}
                                            max={5000}
                                            disabled={!m4Checkbox1}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                m4Checkbox1 == true
                                                    ? onChange('psm1', value)
                                                    : null
                                            }
                                        />
                                        <p className="num0">0</p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            4096
                                        </p>
                                    </div>
                                </div>

                                {/* PORT S1 END */}

                                {/* PORT S2 START */}
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M42"
                                            onClick={() =>
                                                onChange('m4Checkbox2')
                                            }
                                            className={
                                                'renderClick' +
                                                (m4Checkbox2 || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port S2 :
                                            </p>

                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Serial Servo Motor
                                                {/* <br />
                                                        <span>
                                                            Serial Servo Motor
                                                        </span> */}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (m4Checkbox2 || false)
                                        }
                                    >
                                        <Slider
                                            title="Value"
                                            value={
                                                m4Checkbox2 == true
                                                    ? valPSm2
                                                    : 0
                                            }
                                            min={0}
                                            max={4096}
                                            disabled={!m4Checkbox2}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                m4Checkbox2 == true
                                                    ? onChange('psm2', value)
                                                    : null
                                            }
                                        />
                                        <p className="num0">0</p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            4096
                                        </p>
                                    </div>
                                </div>

                                {/* PORT S2 END */}

                                {/* PORT S3 START */}
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M4"
                                            onClick={() =>
                                                onChange('m4Checkbox3')
                                            }
                                            className={
                                                'renderClick' +
                                                (m4Checkbox3 || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port S3 :
                                            </p>
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Serial Servo Motor
                                                {/* <br />
                                                        <span>
                                                            Serial Servo Motor
                                                        </span> */}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (m4Checkbox3 || false)
                                        }
                                    >
                                        <Slider
                                            title="Value"
                                            value={
                                                m4Checkbox3 == true
                                                    ? valPSm3
                                                    : 0
                                            }
                                            min={0}
                                            max={4096}
                                            disabled={!m4Checkbox3}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                m4Checkbox3 == true
                                                    ? onChange('psm3', value)
                                                    : null
                                            }
                                        />
                                        <p className="num0">0</p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            4096
                                        </p>
                                    </div>
                                </div>

                                {/* PORT S3 END */}

                                {/* PORT S4 */}
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M4"
                                            onClick={() =>
                                                onChange('m4Checkbox4')
                                            }
                                            className={
                                                'renderClick' +
                                                (m4Checkbox4 || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port S4 :
                                            </p>
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Serial Servo Motor
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (m4Checkbox4 || false)
                                        }
                                    >
                                        <Slider
                                            title="Value"
                                            value={
                                                m4Checkbox4 == true
                                                    ? valPSm4
                                                    : 0
                                            }
                                            min={0}
                                            max={4096}
                                            disabled={!m4Checkbox4}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                m4Checkbox4 == true
                                                    ? onChange('psm4', value)
                                                    : null
                                            }
                                        />
                                        <p className="num0">0</p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            4096
                                        </p>
                                    </div>
                                </div>

                                {/* PORT S4 END */}

                                {/* PORT S5 */}
                                <div
                                    className="slider-item1-flowchart"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <div className="portDetails-flowchart">
                                        <div
                                            id="M4"
                                            onClick={() =>
                                                onChange('m4Checkbox5')
                                            }
                                            className={
                                                'renderClick' +
                                                (m4Checkbox5 || false) +
                                                '  checkBox-conatiner'
                                            }
                                        >
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Port S5 :
                                            </p>
                                            <p
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Serial Servo Motor
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'portSlider-flowchart' +
                                            ' isActivePortInfo' +
                                            (m4Checkbox5 || false)
                                        }
                                    >
                                        <Slider
                                            title="Value"
                                            value={
                                                m4Checkbox5 == true
                                                    ? valPSm5
                                                    : 0
                                            }
                                            min={0}
                                            max={4096}
                                            disabled={!m4Checkbox5}
                                            renderIn="hardwarePropertyPanel"
                                            onChange={(value) =>
                                                m4Checkbox5 == true
                                                    ? onChange('psm5', value)
                                                    : null
                                            }
                                        />
                                        <p className="num0">0</p>
                                        <p
                                            style={{
                                                position: 'absolute',
                                                top: '55%',

                                                fontSize: '16px',
                                                right: '13%',
                                            }}
                                        >
                                            4096
                                        </p>
                                    </div>

                                    {/* PORT S5 END */}
                                </div>
                            </>
                        ) : null}
                    </>
                )}

                {['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice) &&
                    isActivehumanoidPort() == true && (
                        <ActionPanel check={props.check} />
                    )}
            </div>
        </div>
    )
}

export default OutputPanel
