import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Slider from './Slider'

import style from './SliderBox.module.css'
import IOComponents from '../../logic/IOComponents'

function SliderBox(props) {
    const {
        id,
        servoId,
        btnText,
        sliderText,
        min,
        max,
        value,
        handleSliderChange,
        handleClick,
        liveServoFeedback,
        isEnabled,
        type,
        handelAssignButtonClick,
        dropDownValue,
        check,
        handelServoButtonClick,
        isLiveSActive,
        isDisabledAction,
    } = props
    const [isLiveServoEnabled, setIsLiveServoEnabled] = useState(false)
    const [assignBid, setassignBid] = useState(false)
    const startState = props.logic.program[0].state
    const humanoidProgramData = JSON.parse(
        sessionStorage.getItem('humanoidProgramData')
    )

    //handel bid button
    const onhandleBidValue = () => {
        if (isEnabled) {
            setassignBid(false)
            handelAssignButtonClick(id, assignBid)
        }
    }
    const onhandleBidAssign = () => {
        if (isEnabled) {
            setassignBid(true)
            handelAssignButtonClick(id, assignBid)
        }
    }

    //take input and output
    const { PortConnections } = props.assembly

    let componentProps = props.concept.componentProps,
        internalAccessories = props.concept.internalaccessories
    // let sstartState = props.logic.program[0].state
    let sourceOptionsOrder = [],
        sourceOptionsOrderOutput = [],
        sourceOptions = {},
        sourceOptionsOutput = {}
    sourceOptionsOrder.push('default')
    sourceOptionsOrderOutput.push('default')
    sourceOptions['default'] = 'default'
    sourceOptionsOutput['default'] = 'default'
    Object.keys(PortConnections).forEach((port) => {
        if (
            Object.keys(componentProps).length !== 0 &&
            componentProps !== 'undefined' &&
            port != 'undefined' &&
            PortConnections[port]
        ) {
            if (!PortConnections[port]) return
            var type = PortConnections[port].type
            if (type == '4_in_1_sensor') {
                //   var positionPorts = ["R", "G", "B", "D", "L", "M"];
                //   for (var i = 0; i < positionPorts.length; i++) {
                //     sourceOptionsOrder.push(positionPorts[i]);
                //     sourceOptions[positionPorts[i]] =
                //       componentProps[type].name + " \u2192 " + positionPorts[i];
                //   }
            } else {
                if (IOComponents[type].input && port.length != 1) {
                    sourceOptionsOrder.push(port)
                    sourceOptions[port] =
                        componentProps[type].name + ' \u2192 ' + port
                }
                if (IOComponents[type].output && port.length != 1) {
                    sourceOptionsOrderOutput.push(port)
                    sourceOptionsOutput[port] =
                        componentProps[type].name + ' \u2192 ' + port
                }
            }
        }
    })

    Object.keys(startState).forEach((port) => {
        if (
            startState[`${port}`] &&
            port != 'btRx' &&
            port != 'usbrx' &&
            port != 'btTx'
        ) {
            // console.log('gskk Selection', port)
            if (
                !(btnText == 'BiData1' && port == 'bid1') &&
                !(btnText == 'BiData2' && port == 'bid2')
            ) {
                sourceOptionsOrder.push(port)
                sourceOptions[`${port}`] = port
            }
        } else if (port == 'btTx') {
            for (let i = 1; i < 6; i++) {
                sourceOptionsOrder.push(`${port}${i}`)
                sourceOptions[`${port}${i}`] = `${port}${i}`
            }
        }
    })
    Object.keys(internalAccessories).forEach((port) => {
        var type = port
        if (type == 'Four_in_one_sensor') {
            var positionPorts = [
                'DistanceSensors',
                'GestureSensor',
                'LightSensor',
                'ColorSensor',
            ]
            for (var i = 0; i < positionPorts.length; i++) {
                if (
                    internalAccessories.Four_in_one_sensor[
                        `is${positionPorts[i]}`
                    ]
                ) {
                    if (positionPorts[i] == 'ColorSensor') {
                        sourceOptionsOrder.push('RED')
                        sourceOptions['RED'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'RED'
                        sourceOptionsOrder.push('GREEN')
                        sourceOptions['GREEN'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'GREEN'
                        sourceOptionsOrder.push('BLUE')
                        sourceOptions['BLUE'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'BLUE'
                    } else {
                        sourceOptionsOrder.push(positionPorts[i])
                        sourceOptions[positionPorts[i]] =
                            '4_in_1_sensor' + ' \u2192 ' + positionPorts[i]
                    }
                }
            }
        } else if (
            type === 'isAccelerometer' &&
            internalAccessories[`${type}`]
        ) {
            sourceOptionsOrder.push('ACCELEROMETER_X')
            sourceOptions['isAccelerometerX'] = ' Accelerometer X'
            sourceOptionsOrder.push('ACCELEROMETER_Y')
            sourceOptions['isAccelerometerY'] = ' Accelerometer Y'
            sourceOptionsOrder.push('ACCELEROMETER_Z')
            sourceOptions['isAccelerometerZ'] = ' Accelerometer Z'
            sourceOptionsOrder.push('GYRO_X')
            sourceOptions['isGyroX'] = ' Gyro X'
            sourceOptionsOrder.push('GYRO_Y')
            sourceOptions['isGyroY'] = ' Gyro Y'
            sourceOptionsOrder.push('GYRO_Z')
            sourceOptions['isGyroZ'] = ' Gyro Z'
        } else if (type != 'isFour_in_one_sensor') {
            if (
                internalAccessories[`${type}`] &&
                ![
                    // 'isTouchZeroOutput',
                    // 'isTouchOneOutput',
                    // 'isTouchTwoOutput',
                    // 'isTouchThreeOutput',
                    // 'isbuzzer',
                    // 'isSmileOne',
                    // 'isSmileTwo',
                    // 'isSmileThree',
                    // 'isSmileFour',
                    'isActionGroup',
                    'isActionServo',
                    'isHeadServo',
                    'isZingmp3',
                    'isEYES',
                    'isEyeLeft',
                    'isEyeRight',
                    // 'isRightEye',
                    // 'isLeftEye',
                ].includes(type)
                // type != 'isTouchZeroOutput' &&
                // type != 'isTouchOneOutput' &&
                // type != 'isTouchTwoOutput' &&
                // type != 'isTouchThreeOutput' &&
                // type != 'isbuzzer' &&
                // type != 'isSmileOne' &&
                // type != 'isSmileTwo' &&
                // type != 'isSmileThree' &&
                // type != 'isSmileFour' &&
                // type != 'isEYES'
            ) {
                sourceOptionsOrder.push(port)
                sourceOptions[port] = type.slice(2)
            } else if (
                // type == 'isTouchZeroOutput' ||
                // type == 'isTouchOneOutput' ||
                // type == 'isTouchTwoOutput' ||
                // type == 'isTouchThreeOutput' ||
                (type == 'isbuzzer' ||
                    // type == 'isSmileOne' ||
                    // type == 'isSmileTwo' ||
                    // type == 'isSmileThree' ||
                    // type == 'isSmileFour' ||
                    type == 'isEYES') &&
                internalAccessories[`${type}`]
            ) {
                if (type == 'isEYES') {
                    for (let i = 1; i <= 12; i++) {
                        sourceOptionsOrderOutput.push(port + i)
                        sourceOptionsOutput[port + i] = 'Eye' + i
                    }
                } else {
                    sourceOptionsOrderOutput.push(port)
                    sourceOptionsOutput[port] = type.slice(2)
                }
            }
        }
    })

    //if typ===asign than take sourceOptionsInput
    let sourceOptionsInput = sourceOptions
    if (type === 'asign') {
        sourceOptionsInput['default'] = 'Input'
        sourceOptionsOutput['default'] = 'Output'
        delete sourceOptionsInput.asgn1
        delete sourceOptionsInput.asgn2
        delete sourceOptionsInput.asgn3
        delete sourceOptionsInput.asgn4
        delete sourceOptionsInput.asgn5
    }

    //for bttx assign input array
    let bttxassignArr = {}
    bttxassignArr['default'] = 'default'
    Object.keys(startState).forEach((port) => {
        if (
            startState[`${port}`] &&
            ![
                'btRx',
                'usbrx',
                'btTx',
                'bid1',
                'bid2',
                'asgn1',
                'asgn2',
                'asgn3',
                'asgn4',
                'asgn5',
                'btRte',
                'usbtx',
            ].includes(port)
        ) {
            bttxassignArr[`${port}`] = port
        }
    })

    useEffect(() => {
        if (type == 'bid' || type === 'bttx') {
            setassignBid(humanoidProgramData[check].state[id].value[3])
        }
    }, [id])
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    return (
        <div className={style.container}>
            <div className={style.servoBtnGrp}>
                <button
                    className={isEnabled ? style.enabledBtn : style.disabledBtn}
                    onClick={() => {
                        // if (servoId >= 1 && servoId <= 16) {
                        if (handelServoButtonClick) {
                            handelServoButtonClick(
                                servoId,
                                id,
                                'checkLiveServo'
                            )
                        }
                        // }
                        handleClick(id, assignBid)
                    }}
                    style={
                        isDisabledAction
                            ? {
                                  cursor: 'default',
                                  opacity: '0.5',
                              }
                            : {
                                  cursor: 'pointer',
                                  opacity: '1',
                              }
                    }
                >
                    {btnText === 'M1' || btnText === 'M2' ? (
                        <>
                            Port {btnText} : <br /> Motor
                        </>
                    ) : (
                        btnText
                    )}
                </button>
                {type == 'servo' ? (
                    <button
                        className={
                            isEnabled
                                ? isLiveSActive
                                    ? style.liveServoBtnOn
                                    : style.liveServoBtnOff
                                : style.disabledLiveServoBtn
                        }
                        onClick={() => {
                            liveServoFeedback(servoId, value)
                            handelServoButtonClick(servoId, id)
                            // setIsLiveServoEnabled(!isLiveServoEnabled)
                        }}
                        // onClick={() => liveServoFeedback(servoId, value)}
                    >
                        Live Servo
                    </button>
                ) : null}
            </div>

            <div
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className={isEnabled ? style.enabledSlider : style.slider}
            >
                {(type === 'bid' || type === 'bttx') && isEnabled && (
                    <div
                        style={{
                            display: 'flex',
                            width: '80%',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                        }}
                    >
                        <button
                            className={
                                !assignBid ? style.bidBtnActive : style.bidBtn
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                            }}
                            onClick={() => onhandleBidValue()}
                        >
                            Value
                        </button>
                        <button
                            className={
                                assignBid ? style.bidBtnActive : style.bidBtn
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                            }}
                            onClick={() => onhandleBidAssign()}
                        >
                            Assign
                        </button>
                    </div>
                )}
                {(type === 'bid' || type === 'bttx') && !isEnabled && (
                    <div
                        style={{
                            display: 'flex',
                            width: '80%',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                        }}
                    >
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                                color: 'rgb(0, 0, 0, 0.6)',
                            }}
                        >
                            Value
                        </button>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                                color: 'rgb(0, 0, 0, 0.6)',
                            }}
                        >
                            Assign
                        </button>
                    </div>
                )}

                <div className={style.slidercontainer}>
                    {(type === 'single' || type === 'servo') && (
                        <Slider
                            title={sliderText}
                            value={value}
                            min={min}
                            max={max}
                            renderIn="hardwarePropertyPanel"
                            onChange={(value) => {
                                if (type == 'servo' && isLiveSActive)
                                    liveServoFeedback(servoId, value)
                                handleSliderChange(id, value)
                            }}
                            disabled={!isEnabled || isDisabledAction}
                            // disabled={true}
                            sliderName={isEnabled ? null : ''}
                        />
                    )}
                    {(type === 'bid' || type === 'bttx') && !assignBid ? (
                        <div style={{ paddingTop: '30px' }}>
                            {
                                <Slider
                                    title={sliderText}
                                    value={
                                        humanoidProgramData[check].state[id]
                                            .value[2]
                                    }
                                    min={min}
                                    max={max}
                                    renderIn="hardwarePropertyPanel"
                                    onChange={(value) => {
                                        if (
                                            type == 'servo' &&
                                            isLiveServoEnabled
                                        )
                                            liveServoFeedback(servoId, value)
                                        handleSliderChange(id, value)
                                    }}
                                    disabled={!isEnabled}
                                    sliderName={isEnabled ? null : ''}
                                />
                            }
                        </div>
                    ) : (
                        (type === 'bid' || type === 'bttx') && (
                            <select
                                style={{
                                    height: '40px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    borderStyle: 'dotted',
                                    borderColor: '#e0dede',
                                    background: '#ebf6fa',
                                    fontSize: '18px',
                                    marginTop: '30px',
                                }}
                                value={
                                    humanoidProgramData[check].state[id]
                                        .value[4]
                                }
                                onChange={(e) => {
                                    if (e.target.value == 'Input') {
                                        handleSliderChange(
                                            id,
                                            'default',
                                            'dropdown'
                                        )
                                    } else
                                        handleSliderChange(
                                            id,
                                            e.target.value,
                                            'dropdown'
                                        )
                                }}
                                disabled={!isEnabled}
                            >
                                {Object.keys(
                                    type === 'bid'
                                        ? sourceOptionsInput
                                        : bttxassignArr
                                ).map((value, index) => (
                                    <option key={index} value={value}>
                                        {sourceOptionsInput[value]}
                                    </option>
                                ))}
                            </select>
                        )
                    )}
                    {type === 'RGB' && (
                        <>
                            <Slider
                                title="Red"
                                value={value[0]}
                                min={min[0]}
                                max={max[0]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        val,
                                        value[1],
                                        value[2],
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'R' : ''}
                            />
                            <Slider
                                title="Green"
                                value={value[1]}
                                min={min[1]}
                                max={max[1]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        value[0],
                                        val,
                                        value[2],
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'G' : ''}
                            />
                            <Slider
                                title="Blue"
                                value={value[2]}
                                min={min[2]}
                                max={max[2]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        value[0],
                                        value[1],
                                        val,
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'B' : ''}
                            />
                        </>
                    )}
                </div>

                {type === 'asign' && (
                    <>
                        <div
                            style={{
                                position: 'relative',
                                display: 'block',
                                width: '100%',
                                height: '100px',
                            }}
                        >
                            <div
                                style={{
                                    width: '45%',
                                    position: 'relative',
                                    top: '40%',
                                    left: '25%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '40px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={value[0]}
                                    // value={
                                    //     this.props.changeState.state[
                                    //         `valueAsgn${n}Output`
                                    //     ]
                                    // }
                                    onChange={(e) => {
                                        if (e.target.value === 'Output')
                                            handleSliderChange(
                                                id,
                                                'default',
                                                'output'
                                            )
                                        else
                                            handleSliderChange(
                                                id,
                                                e.target.value,
                                                'output'
                                            )
                                    }}
                                    disabled={!isEnabled}
                                >
                                    {Object.keys(sourceOptionsOutput).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsOutput[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div
                                style={{
                                    transform: `translate(98.5%,-50%)scale(2)`,
                                }}
                            >
                                <p>=</p>
                            </div>
                            <div
                                style={{
                                    width: '45%',
                                    position: 'relative',
                                    top: '-40%',
                                    left: '77%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '40px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={value[0]}
                                    // value={
                                    //     this.props.changeState.state[
                                    //         `valueAsgn${n}Input`
                                    //     ]
                                    // }
                                    onChange={(e) => {
                                        if (e.target.value == 'Input') {
                                            handleSliderChange(
                                                id,
                                                'default',
                                                'input'
                                            )
                                        } else
                                            handleSliderChange(
                                                id,
                                                e.target.value,
                                                'input'
                                            )
                                    }}
                                    disabled={!isEnabled}
                                >
                                    {Object.keys(sourceOptionsInput).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsInput[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}
// export default SliderBox
export default connect(mapStateToProps)(SliderBox)
