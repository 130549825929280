import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import unicodeToChar from '../../../../utils/unicodeToChar'

import './camera.css'
import { useHistory } from 'react-router'

import renderImage from '../../../../source/importImg'

import * as facemesh from '@tensorflow-models/face-landmarks-detection'

import Webcam from 'react-webcam'
import { useRef } from 'react'
import CamSlider from '../../../ReusableComponents/CamSlider/CamSlider'
import MainHeader from '../../../ReusableComponents/Header/MainHeader'
import { drawMesh } from './utilities'
import { sendBytes } from '../../../ReusableComponents/ByteTransfer'
import cacheAssets from '../../../../utils/cacheAssets'
import { generateDeviceDataKey } from '../../../ReusableComponents/ByteTransfer/utils'
let count = 0
// const connectedDevice = sessionStorage.getItem('connectedDevice')
// const deviceVersion = sessionStorage.getItem('deviceVersion')
const devices = {
    HUMANOID: { htmlc: <>Zing </>, name: 'Zing', image: 'Zing', style: {} },
    HEXAPOD: {
        htmlc: <>Crawle </>,
        name: 'Crawle',
        image: 'Hexapod',
        style: { width: '25%', top: '30%', left: '70%' },
    },
    KLAW: {
        htmlc: <>Klaw </>,
        name: 'Klaw',
        image: 'Klaw',
        style: {},
    },
    ACE1: {
        htmlc: <>PeeCee Beta </>,
        name: 'PeeCee Beta',
        image: 'PCv1',
        style: {},
    },
    ACE0: {
        htmlc: <>PeeCee Alpha </>,
        name: 'PeeCee Alpha',
        image: 'PC',
        style: {},
    },
}

function Camera(props) {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const [sendPack, setSendPack] = useState(false)
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    //all device image link nd style
    // const devices = {
    //     Humanoid: { htmlc: <>Zing </>, name: 'Zing', image: 'Zing', style: {} },
    //     Hexapod: {
    //         htmlc: <>Crawle </>,
    //         name: 'Crawle',
    //         image: 'Hexapod',
    //         style: { width: '25%', top: '30%', left: '70%' },
    //     },
    //     Klaw: {
    //         htmlc: <>Klaw </>,
    //         name: 'Klaw',
    //         image: 'Klaw',
    //         style: {},
    //     },
    //     Ace: {
    //         htmlc: deviceVersion?.startsWith('1') ? (
    //             <>PeeCee Beta </>
    //         ) : (
    //             <>PeeCee Alpha </>
    //         ),
    //         name: deviceVersion?.startsWith('1')
    //             ? 'PeeCee Beta'
    //             : 'PeeCee Alpha',
    //         image: deviceVersion?.startsWith('1') ? 'PCv1' : 'PC',
    //         style: {},
    //     },
    // }
    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderImage('cameraH'),
            renderImage('cameraH1'),
            renderImage('Cam1'),
            renderImage('Cam2'),
        ]
        cacheAssets(assetsToBeCached)
        // let data = ['C'.charCodeAt(0), '0'.charCodeAt(0)]
        // writePort(data)
    }, [])

    const connectedACE = {
        height: '90%',
        width: '100%',
        backgroundImage: `url("/playImages/ace_con.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        position: 'relative',
        left: '5px',
    }

    // let history = useHistory();

    // setup
    const webcamRef = useRef(null)

    const canvasRef = useRef(null)

    // DATA FACE
    let [faceData, setFaceData] = useState(false)

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendPack) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }
    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read' && e.data.value.includes('RDONE')) {
                setSendPack(false)
            }
        }
        props.worker.addEventListener('message', messageHandler)
    }, [])

    const sendPlayBytes = () => {
        sessionStorage.setItem('isPlay', true)
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
        const playBytes = setTimeout(() => {
            writePort(PLAY)
            clearTimeout(playBytes)
        }, 250)
        // if (
        //     !(
        //         sessionStorage.getItem('deviceVersion')?.startsWith('2') &&
        //         ['Humanoid'].includes(connectedDevice)
        //     )
        // ) {
        //     const clearPin = setTimeout(() => {
        //         writePort(M8)
        //         clearTimeout(clearPin)
        //     }, 350)
        // }
    }
    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d')

        // facemesh
        const runFacemesh = async () => {
            // document.getElementById("loaderContainer").style.display = "block";

            const net = await facemesh.load(
                facemesh.SupportedPackages.mediapipeFacemesh,
                { maxFaces: '1', detectionConfidence: '0.9' }
            )

            // document.getElementById("loaderContainer").style.display = "none";

            console.log('net', net)
            setInterval(() => {
                // Get canvas context

                detect(net, ctx)
            }, 10)
        }

        const detect = async (net, ctx) => {
            console.log()

            if (
                typeof webcamRef.current !== 'undefined' &&
                webcamRef.current !== null &&
                webcamRef.current.video.readyState === 4
            ) {
                // Get Video Properties
                const video = webcamRef.current.video
                const videoWidth = webcamRef.current.video.videoWidth
                const videoHeight = webcamRef.current.video.videoHeight

                // Set video width
                webcamRef.current.video.width = videoWidth
                webcamRef.current.video.height = videoHeight

                // Set canvas width
                canvasRef.current.width = videoWidth
                canvasRef.current.height = videoHeight

                // Make Detections
                const face = await net.estimateFaces({
                    input: video,
                })

                setFaceData(true)

                // console.log('FACE', face)

                // // Get canvas context
                // const ctx = canvasRef.current.getContext("2d");

                if (ctx != null) {
                    requestAnimationFrame(() => {
                        drawMesh(face, ctx)
                    })
                }
            }
        }

        runFacemesh()
    }, [])

    let interval

    //const socket = io("http://localhost:3008");
    useEffect(() => {
        // let no_port = props.webserialPort.name;
        // if (no_port == "Not Connected") {
        //   console.log("SERIAL PORT NOT CONNECTED");
        // } else {
        let timeInterval
        if (
            // ['2'].includes(deviceVersion[0]) &&
            ['Humanoid', 'Hexapod'].includes(connectedDevice)
        ) {
            timeInterval = 100
        } else {
            timeInterval = 1500
        }
        interval = setInterval(async () => {
            let data = localStorage.getItem('faceSide')
            let url = window.location.href
            let path = 'http://localhost:3008/camera'
            console.log('5ms : ', url)
            if (props.webSerial.isConnected) {
                if (data === 'Left') {
                    let data = ['C'.charCodeAt(), '2'.charCodeAt()]
                    console.log(' emit L ', data)
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                } else if (data === 'Right') {
                    let data = ['C'.charCodeAt(), '1'.charCodeAt()]
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                    console.log('emit R ', data)
                } else if (data === 'Center') {
                    let data = ['C'.charCodeAt(), '0'.charCodeAt()]
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                    // console.log('emit C ', data)
                } else if (data === 'Up') {
                    let data = ['C'.charCodeAt(), '3'.charCodeAt()]
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                    console.log('emit T ', data)
                } else if (data === 'Down') {
                    let data = ['C'.charCodeAt(), '4'.charCodeAt()]
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                    console.log('emit B ', data)
                } else if (data === 'Smile') {
                    let data = ['C'.charCodeAt(), '5'.charCodeAt()]
                    // socket.emit("/camera", data);
                    if (
                        // ['2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                    ) {
                        if (!sendPack) {
                            setSendPack(true)
                            writePort(data)
                        }
                    } else {
                        writePort(data)
                    }
                    console.log('emit S ', data)
                } else return
            }
        }, timeInterval) //1500  timeInterval
        console.log('interval', interval)
        return () => {
            console.log('interval', interval)
            clearInterval(interval)
        }
        // }
    }, [sendPack, props.webSerial.isConnected])

    //  WEB SERIAL   //

    // useEffect(() => {
    //     setTimeout(async () => {
    //         if (
    //             !(
    //                 deviceVersion?.includes('2.0.0') &&
    //                 ['Humanoid'].includes(connectedDevice)
    //             )
    //         ) {
    //             const p_Port = props.webSerial.port
    //             try {
    //                 let portReader = p_Port.readable.getReader()

    //                 // let portWriter = portList.writable.getWriter();

    //                 while (true) {
    //                     const { value, done } = await portReader.read()
    //                     console.log('value', value)
    //                     console.log('done', done)

    //                     const strg = unicodeToChar(value)
    //                     let str = strg.trim()

    //                     console.log(str, 'uniCodeTOCHAR')
    //                     if (done) {
    //                         console.log('[readLoop] DONE', done)
    //                         portReader.releaseLock()
    //                         break
    //                     }
    //                 }
    //             } catch (e) {
    //                 console.log(e)
    //             }
    //         }
    //     }, 1000)
    // })

    useEffect(async () => {
        if (props.webSerial.isConnected && count === 0) {
            sendPlayBytes()
            count = count + 1
        }
        if (props.webSerial.isConnected && count > 0) {
            if (!JSON.parse(sessionStorage.getItem('isPlay'))) {
                sendPlayBytes()
            }
        }
        if (!props.webSerial.isConnected) {
            setSendPack(false)
            count = 0
        }
    })

    async function writePort(data) {
        if (props.webSerial.port !== undefined) {
            // if (
            //     (connectedDevice == 'Ace' &&
            //         deviceVersion?.includes('1.0.0')) ||
            //     deviceVersion?.includes('2.0.0')
            // ) {
            if (
                props.webSerial.isConnected &&
                props.webSerial.port !== undefined
            ) {
                props.worker.postMessage({
                    type: 'writeArray',
                    value: data,
                })
            }
            // } else {
            //     await sendBytes(data, props.webSerial.port)
            // }
        }
        // }
    }

    let history = useHistory()

    // const getImgUrl = () => {
    //     if (connectedDevice === 'Roboki') {
    //         return renderImage('Roboki')
    //     } else {
    //         return renderImage('PC')
    //     }
    // }
    return (
        <div className="Camera-Main">
            <MainHeader
                title="Camera"
                helper={<CamSlider />}
                showBluetoothBtn={true}
                goBack={async () => {
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/introduction')
                    let replyOK = await Promise.race([
                        checkOk(),
                        timeoutPromise(2000),
                    ])

                    let data = ['C'.charCodeAt(0), '0'.charCodeAt(0)] //ATTENTION byet code
                    if (props.webSerial.isConnected) {
                        writePort(data)
                    }
                }}
                isGoBack={true}
            />
            {/* <div className="Camera_Header">
        <div>
          <img
            className="Back_BTNN"
            src={renderImage("backBtn")}
            onClick={gobackUrl}
          ></img>
        </div>
        <div>
          {" "}
          <p className="Play_Speech">Camera</p>
        </div>
        <div></div>
        <div>
          {isHelp == false ? (
            <img
              className="Help_btn"
              src={renderImage("helpBtnInActive")}
              onClick={handleHelpBtn}
            ></img>
          ) : (
            <div className="Musc_Slider">
              <CamSlider />
            </div>
          )}
          {isHelp ? (
            <div>
              <img
                className="Crs"
                src={renderImage("clos")}
                onClick={handleHelpBtn}
              ></img>
            </div>
          ) : null}
        </div>
        <div>
          {" "}
          {isUsb ? (
            <img
              className="Bluetooth_Button"
              src={renderImage("UsbOn")}
              onClick={HdleUsb}
            ></img>
          ) : (
            <img
              className="Bluetooth_Button"
              src={renderImage("UsbOff")}
              onClick={HdleUsb}
            ></img>
          )}
        </div>
      </div> */}

            <div className="Camera123">
                <img
                    className="PopUp_Card"
                    src={renderImage('popup_Svg')}
                    draggable="false"
                ></img>
                {!faceData ? (
                    <div id="loaderContainer">
                        <div id="loader" className="Camera_loader"></div>
                        <p
                            className="loaderText"
                            style={{
                                marginLeft: '-17%',
                                color: 'black',

                                textShadow: '0px 0px 2px white',
                            }}
                        >
                            Loading AI Engine
                        </p>
                    </div>
                ) : (
                    console.log('SO YES')
                )}
                <Webcam
                    ref={webcamRef}
                    style={{
                        position: 'absolute',
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        left: '16%',
                        right: 0,
                        top: '25%',
                        textAlign: 'center',
                        zIndex: 9,
                        width: '30%',
                        height: '50%',
                        transform: 'scaleX(-1)',
                    }}
                />
                <canvas
                    className="canvas"
                    ref={canvasRef}
                    style={{
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: '34.5%',
                        left: '-38%',
                        right: 0,
                        textAlign: 'center',
                        zIndex: '9',
                        width: '30%',
                        height: '100% + 10%',

                        display: 'none',
                        // height: "100%",
                        // border: "2px solid red",
                        display: 'block',
                    }}
                />
                <img
                    className="Camera_Disconnected"
                    src={renderImage('Disconnected_Svg')}
                    draggable="false"
                ></img>
                <img
                    className="Camera_Ace"
                    style={
                        connectedDevice == 'Ace'
                            ? devices[
                                  generateDeviceDataKey(
                                      connectedDevice,
                                      deviceVersion
                                  )
                              ].style
                            : devices[connectedDevice.toUpperCase()].style
                    }
                    src={
                        connectedDevice == 'Ace'
                            ? renderImage(
                                  devices[
                                      generateDeviceDataKey(
                                          connectedDevice,
                                          deviceVersion
                                      )
                                  ].image
                              )
                            : renderImage(
                                  devices[connectedDevice.toUpperCase()].image
                              )
                    }
                    draggable="false"
                ></img>
                {/* {connectedDevice === 'Ace' ? (
                    <>
                        {deviceVersion?.startsWith('1') ? (
                            <img
                                className="Camera_Ace"
                                src={renderImage('PCv1')}
                                draggable="false"
                            />
                        ) : (
                            <img
                                className="Camera_Ace"
                                src={getImgUrl()}
                                draggable="false"
                            />
                        )}
                    </>
                ) : (
                    <></>
                )}
                {connectedDevice === 'Humanoid' && (
                    <img
                        className="Camera_Ace"
                        src={renderImage('Zing')}
                        draggable="false"
                    ></img>
                )} */}
                {/* <img className="Camera_Ace" src={renderImage("PC")}></img> */}
                {/* <h1>{faceData}</h1> */}
            </div>

            <div style={{ position: 'relative' }}>
                {connectedDevice === 'Humanoid' ? (
                    <h3 className="Camera_Instruc">
                        Tilt your head in different directions to see the Zing
                        respond
                    </h3>
                ) : (
                    <h3 className="Camera_Instruc">
                        Tilt your head in different directions to see the Play
                        Computer respond
                    </h3>
                )}
            </div>
        </div>
    )
}
// export default Camera;

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Camera)
