import React, { useEffect, useState } from 'react'
import style from './GraphHeader.module.css'
import renderPrgImage from '../../../source/programImg'
import { useHistory } from 'react-router'
import {
    deviceDetails,
    deviceMismatch,
    showModal,
    webSerialConnect,
} from '../../../redux/actions'
import { connect } from 'react-redux'
import {
    connectSerialDevice,
    connectSerialDeviceIntermediate,
    connectUSBDevice,
} from '../../WebSerialConnection/SerialUSBConnect'
import DialogModal from '../DialogModal/DialogModal'

function GraphHeader({
    isGoBack,
    goBack,
    text,
    helpBtn1,
    helpBtn,
    webSerial,
    showGraph,
    webSerialConnect,
    showModal,
    setConnectedPort,
    device,
    deviceMismatch,
    deviceDetail,
}) {
    const [isDeviceMismatch, setIsDeviceMismatch] = useState(false)
    const history = useHistory()
    const [showErrorMsg, setshowErrorMsg] = useState(false)
    const handleUSB = async () => {
        if (!webSerial.isConnected) {
            let res = null
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                res = await connectUSBDevice({ isAutomatic: false })
            } else
                res = await connectSerialDeviceIntermediate({
                    isAutomatic: false,
                })

            if (res !== undefined && !res.isPortOpen) {
                console.log(res.matchedObj_with_Version)
                deviceMismatch({
                    deviceMismatched: res.matchedObj_with_Version,
                })
                setIsDeviceMismatch(true)
            }
            if (res.isPortOpen) {
                webSerialConnect({ port: res.port })
                setConnectedPort(res.port)
            } else setshowErrorMsg(true)
        }
    }
    const handleDevice = async (option) => {
        if (option === 'yes') {
            let port = webSerial.port
            //is this needed??
            // await openSerialReadComPort(port,worker)
        } else {
            history.push('/Selection')
            let mismatchedDevice = device.deviceMismatched
            sessionStorage.setItem('connectedDevice', mismatchedDevice.id)
            // if (mismatchedDevice.id === 'Ace') {
            sessionStorage.setItem('deviceVersion', mismatchedDevice.version)
            deviceDetail({
                connectedDevice: mismatchedDevice.id,
                deviceVersion: mismatchedDevice.version,
            })
            // } else {
            //     sessionStorage.setItem('zingVersion', mismatchedDevice.version)
            //     deviceDetail({
            //         connectedDevice: mismatchedDevice.id,
            //         zingVersion: mismatchedDevice.version,
            //         deviceVersion: '0.0.0',
            //     })
            // }
        }
        setIsDeviceMismatch(false)
        deviceMismatch({ deviceMismatched: {} })
    }

    //handle error in connection
    const handleError = async () => {
        setshowErrorMsg(false)
        window.location.reload()
    }
    useEffect(() => {
        if (device) {
            let deviceD = Object.keys(device.deviceMismatched)
            if (deviceD.length > 1) {
                if (isDeviceMismatch == false) {
                    setIsDeviceMismatch(true)
                }
            }
        }
    }, [device.deviceMismatched])
    return (
        <>
            <DialogModal
                mismatchShow={isDeviceMismatch}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            A device mismatch found!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Would you like to continue anyway?
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() => setIsDeviceMismatch(false)}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => handleDevice('yes'),
                    },
                    {
                        text: 'NO',
                        do: () => handleDevice('no'),
                    },
                ]}
            />
            <DialogModal
                showError={showErrorMsg}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            Error in connecting the device!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Please disconnect the device and try connecting
                            again.
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() => setshowErrorMsg(false)}
                optionsToSelect={[
                    {
                        text: 'OK',
                        do: handleError,
                    },
                ]}
            />
            <div className={style.Main_Header}>
                <div id={style.firstDiv} style={{ position: 'relative' }}>
                    {isGoBack ? (
                        <img
                            className={style.backBtn}
                            src={renderPrgImage('backBtn')}
                            onClick={() => {
                                if (goBack) goBack()
                                else history.goBack()
                            }}
                        ></img>
                    ) : null}
                </div>
                <div id={style.SecondDiv} style={{ position: 'relative' }}>
                    <p className={style.text}> {text} </p>
                </div>

                <div id={style.ThirdDiv}></div>

                <div id={style.ForthDiv}>
                    {showGraph && (
                        <img
                            className={style.graphBtn}
                            src={renderPrgImage('graphbtn')}
                        ></img>
                    )}

                    {helpBtn && (
                        <img
                            className={style.helpBtn}
                            src={renderPrgImage('graphHelpBtn')}
                        ></img>
                    )}

                    {helpBtn1 && (
                        <img
                            className={style.helpBtn1}
                            src={renderPrgImage('graphHelpBtn')}
                        ></img>
                    )}

                    {/* {webSerial && (
                        <img
                            className={style.bluetooth}
                            src={renderPrgImage('connectionOn')}
                        />
                    )} */}

                    {webSerial.isConnected ? (
                        <img
                            src={renderPrgImage('usbON')}
                            onClick={handleUSB}
                            style={{ height: '8vh', marginTop: '6.5%' }}
                        />
                    ) : (
                        <img
                            src={renderPrgImage('usbOFF')}
                            onClick={handleUSB}
                            style={{ height: '8vh', marginTop: '6.5%' }}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },

        showModal: (data) => {
            dispatch(showModal(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
        deviceDetail: (data) => {
            dispatch(deviceDetails(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GraphHeader)
