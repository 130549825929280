import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import SpchStyle from './SpchSlider.module.css'
import renderImage from '../../../source/importImg'
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

export class SpchSlider extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }

        let connectedDevice = ['Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )
            ? true
            : false
        let deviceName = sessionStorage.getItem('connectedDevice')
        let device =
            sessionStorage.getItem('connectedDevice') == 'Hexapod'
                ? 'Crawlee'
                : 'Zing'
        return (
            <div>
                <div
                    className="container"
                    style={{
                        height: '100vh',
                        background: 'white',
                        left: '19.5%',
                    }}
                >
                    <div
                        className="Ss_sliderPlay"
                        style={{
                            background: '#fff',
                            width: '100vw',
                            height: '100vh',
                            position: 'relative',
                        }}
                    >
                        <Slider
                            {...settings}
                            style={{
                                top: '46%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <div>
                                <div>
                                    <img
                                        className={SpchStyle.img}
                                        src={
                                            sessionStorage.getItem(
                                                'connectedDevice'
                                            ) === 'Humanoid'
                                                ? renderImage('speechH')
                                                : sessionStorage.getItem(
                                                      'connectedDevice'
                                                  ) === 'Hexapod'
                                                ? renderImage('speechH')
                                                : renderImage('Spch1')
                                        }
                                    />
                                </div>
                                {sessionStorage.getItem('connectedDevice') !==
                                    'Hexapod' && (
                                    <div
                                        className={SpchStyle.Hdg}
                                        style={
                                            deviceName === 'Hexapod'
                                                ? { right: '71.2%' }
                                                : connectedDevice
                                                ? { right: '76.1%' }
                                                : { right: '79.3%' }
                                        }
                                    >
                                        <p className={SpchStyle.Hdg_P}>
                                            {' '}
                                            Here languages English & hindi can
                                            be setected.
                                        </p>
                                    </div>
                                )}
                                <div
                                    className={SpchStyle.Mdl}
                                    style={
                                        deviceName === 'Hexapod'
                                            ? { right: '71.2%' }
                                            : connectedDevice
                                            ? { right: '75.8%' }
                                            : { right: '79%' }
                                    }
                                >
                                    <p className={SpchStyle.Mdl_P}>
                                        Help Button will explain all the
                                        features and functionality of the entire
                                        app for each screen
                                    </p>
                                </div>
                                <div
                                    className={SpchStyle.Ftr}
                                    style={
                                        deviceName === 'Hexapod'
                                            ? { right: '71.2%' }
                                            : connectedDevice
                                            ? { right: '75.2%' }
                                            : { right: '78.5%' }
                                    }
                                >
                                    <p className={SpchStyle.Ftr_P}>
                                        When the app is connected to the Play
                                        Computer, the icon will be GREEN in
                                        colour, & when disconnected, the icon
                                        will turn RED. If the app gets
                                        disconnected from the Play computer,
                                        then on top of this button, it’ll
                                        attempt to connect automatically.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <img
                                        className={SpchStyle.img}
                                        src={
                                            sessionStorage.getItem(
                                                'connectedDevice'
                                            ) === 'Humanoid'
                                                ? renderImage('speechH1')
                                                : sessionStorage.getItem(
                                                      'connectedDevice'
                                                  ) === 'Hexapod'
                                                ? renderImage('speechH1')
                                                : renderImage('Spch2')
                                        }
                                    />
                                </div>

                                <div
                                    className={SpchStyle.con}
                                    style={
                                        deviceName === 'Hexapod'
                                            ? { left: '34%' }
                                            : connectedDevice
                                            ? { left: '28.4%' }
                                            : { left: '24.15%' }
                                    }
                                >
                                    <p className={SpchStyle.con_P}>
                                        • Tap on the mic button once, which will
                                        begin to listening to words you speak.{' '}
                                        <br /> • The app will detect the words
                                        spoken & check if it is part of the
                                        pre-defined phrases. <br /> • There are
                                        pre-defined words / phrases that the app
                                        will recognise, and the respective
                                        action will be performed by the
                                        playcomputer.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <img
                                        className={SpchStyle.img}
                                        src={
                                            sessionStorage.getItem(
                                                'connectedDevice'
                                            ) === 'Humanoid'
                                                ? renderImage('speechH1')
                                                : sessionStorage.getItem(
                                                      'connectedDevice'
                                                  ) === 'Hexapod'
                                                ? renderImage('speechH1')
                                                : renderImage('Spch2')
                                        }
                                    />
                                </div>
                                {sessionStorage.getItem('connectedDevice') ==
                                'Humanoid' ? (
                                    <div
                                        className={SpchStyle.Mus}
                                        style={{ left: '36.7%' }}
                                    >
                                        <p className={SpchStyle.Mus_P}>
                                            Predefined phrases : <br />
                                            • "WALK FORWARD"
                                            <br />
                                            • "MOVE FORWARD"
                                            <br />
                                            •"WALK FRONT"
                                            <br /> • "MOVE FRONT" <br />
                                            • "WALK BACKWARD"
                                            <br />
                                            • "MOVE BACKWARD"
                                            <br />
                                            •"WALK BACK"
                                            <br /> • "MOVE BACK" <br />
                                            • "TURN LEFT"
                                            <br />
                                            • "TURN RIGHT"
                                            <br />
                                        </p>
                                    </div>
                                ) : sessionStorage.getItem('connectedDevice') ==
                                  'Hexapod' ? (
                                    <div
                                        className={SpchStyle.Mus}
                                        style={{ left: '44%' }}
                                    >
                                        <p className={SpchStyle.Mus_P}>
                                            Predefined phrases : <br />
                                            • "WALK FORWARD"
                                            <br />
                                            • "MOVE FORWARD"
                                            <br />
                                            •"WALK FRONT"
                                            <br /> • "MOVE FRONT" <br />
                                            • "WALK BACKWARD"
                                            <br />
                                            • "MOVE BACKWARD"
                                            <br />
                                            •"WALK BACK"
                                            <br /> • "MOVE BACK" <br />
                                            • "TURN LEFT"
                                            <br />
                                            • "TURN RIGHT"
                                            <br />
                                        </p>
                                    </div>
                                ) : (
                                    <div className={SpchStyle.Mus}>
                                        <p className={SpchStyle.Mus_P}>
                                            • The app will detect the words
                                            spoken & check if it is part of the
                                            pre-defined phrases.
                                            <br />
                                            • For this to work, see to that you
                                            allow your app to record audio and
                                            enable the mic of your smarphone.
                                            Set permission to allow whenever the
                                            app is in use.
                                            <br />
                                            • If yes, then the PLODE app will
                                            send a command to the playcomputer
                                            to perform the respective action.
                                            <br /> • To command a different
                                            action, tap on the mic again & then
                                            speak the command.
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div>
                                <div>
                                    <img
                                        className={SpchStyle.img}
                                        src={
                                            sessionStorage.getItem(
                                                'connectedDevice'
                                            ) === 'Humanoid'
                                                ? renderImage('speechH1')
                                                : sessionStorage.getItem(
                                                      'connectedDevice'
                                                  ) === 'Hexapod'
                                                ? renderImage('speechH1')
                                                : renderImage('Spch2')
                                        }
                                    />
                                </div>

                                {['Humanoid'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                ) ? (
                                    <div
                                        className={SpchStyle.Cam}
                                        style={{ left: '45%', top: '50%' }}
                                    >
                                        <p className={SpchStyle.Cam_P}>
                                            Predefined phrases:
                                            <br />
                                            • "ATTENTION"
                                            <br />
                                            • "SLIDE LEFT"
                                            <br />
                                            •"SLIDE RIGHT"
                                            <br /> • " SALUTE" <br />
                                            • "DANCE"
                                            <br />
                                            • "DANCE FOR ME"
                                            <br />
                                            •"PERFORM A DANCE ACTION"
                                            <br /> • "STOP" <br />
                                            • "PERFORM A SQUAT ACTION"
                                            <br />
                                            • "SQUAT ACTION"
                                            <br />
                                        </p>
                                    </div>
                                ) : sessionStorage.getItem('connectedDevice') ==
                                  'Hexapod' ? (
                                    <div
                                        className={SpchStyle.Cam}
                                        style={{ left: '54%', top: '50%' }}
                                    >
                                        <p className={SpchStyle.Cam_P}>
                                            Predefined phrases:
                                            <br />
                                            • "ATTENTION"
                                            <br />
                                            • "SLIDE LEFT"
                                            <br />
                                            •"SLIDE RIGHT"
                                            <br /> • " WAVE" <br />
                                            • "DANCE"
                                            <br />
                                            • "DANCE FOR ME"
                                            <br />
                                            •"ATTACK"
                                            <br /> • "STOP" <br />
                                            • "DEFENCE"
                                            <br />
                                            • "TURN AROUND"
                                            <br />
                                        </p>
                                    </div>
                                ) : (
                                    <div className={SpchStyle.Cam}>
                                        <p className={SpchStyle.Cam_P}>
                                            Predefined phrases:
                                            <br />
                                            • LIGHTS OFF - this will turn off
                                            all the lights of the PLaycomputer.
                                            <br />• RED light ON - this will
                                            turn ON both the RGB led in red
                                            colour. <br />• Blue light ON - this
                                            will turn ON both the RGB led in
                                            blue colour. <br />
                                        </p>
                                    </div>
                                )}
                            </div>

                            {sessionStorage.getItem('connectedDevice') !==
                                'Hexapod' && (
                                <div>
                                    <div>
                                        <img
                                            className={SpchStyle.img}
                                            src={
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                ) === 'Humanoid'
                                                    ? renderImage('speechH1')
                                                    : sessionStorage.getItem(
                                                          'connectedDevice'
                                                      ) === 'Hexapod'
                                                    ? renderImage('speechH1')
                                                    : renderImage('Spch2')
                                            }
                                        />
                                    </div>

                                    {['Humanoid'].includes(
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        )
                                    ) ? (
                                        <div
                                            className={SpchStyle.Cam1}
                                            style={{ left: '53.4%' }}
                                        >
                                            <p className={SpchStyle.Cam1_P}>
                                                Predefined phrases:
                                                <br /> • "SQUAT"
                                                <br />
                                                • "WAVE AT ME"
                                                <br />
                                                •"WAVE"
                                                {/* <br /> • " SALUTE" <br /> */}
                                                • "DO A FIGHT STEP"
                                                <br />
                                                • "FIGHT"
                                                <br />
                                                •"TAKE A BOW"
                                                <br /> • "BOW" <br />
                                                • "DO PUSH UP ACTION"
                                                <br />
                                                • "PUSH UP "
                                                <br />
                                            </p>
                                        </div>
                                    ) : (
                                        <div className={SpchStyle.Cam1}>
                                            <p className={SpchStyle.Cam1_P}>
                                                Predefined phrases:
                                                <br />• Green light ON - this
                                                will turn ON both the RGB led in
                                                green colour. <br />• White
                                                light ON - this will turn ON
                                                both the RGB led in white
                                                colour. <br />
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}

                            {['Hexapod', 'Humanoid'].includes(
                                sessionStorage.getItem('connectedDevice')
                            ) ? null : (
                                <div>
                                    <div>
                                        <img
                                            className={SpchStyle.img}
                                            src={
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                ) === 'Humanoid'
                                                    ? renderImage('speechH1')
                                                    : sessionStorage.getItem(
                                                          'connectedDevice'
                                                      ) === 'Hexapod'
                                                    ? renderImage('speechH1')
                                                    : renderImage('Spch2')
                                            }
                                        />
                                    </div>

                                    <div className={SpchStyle.Cam2}>
                                        <p className={SpchStyle.Cam2_P}>
                                            Predefined phrases:
                                            <br />
                                            • SMILE ON - this will turn on the
                                            LED at the mouth, to make a smile
                                            emotion.
                                            <br />
                                            • SMILE OFF - this will turn OFF the
                                            led at the mouth, to remove the
                                            smile.
                                            <br />
                                            • Disco lights - this will make the
                                            playcomputer to go into dicso mode,
                                            just like the the remote option.
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default SpchSlider
