import {
    DEFAULTSERVOVALUES,
    SINGLEACTIONSTATE,
} from '../../Code/CreateAction/defaultData'
// import { generateDeviceDataKey } from '../../Humanoid/defaultData'

const MSBLSBToDecimal = (data) => {
    if (data.length !== 2) return
    const [MSBInDecimal, LSBInDecimal] = data
    const MSBInBinary = parseInt(MSBInDecimal).toString(2).padStart(8, 0)
    const LSBInBinary = parseInt(LSBInDecimal).toString(2).padStart(8, 0)
    const dataInBinary = MSBInBinary + LSBInBinary
    const dataInDecimal = parseInt(dataInBinary, 2)
    return dataInDecimal
}

const decimalToMSBLSB = (dataInDecimal) => {
    // Conversion of 16 bit data into MSB & LSB
    const dataIn16Bit = parseInt(dataInDecimal).toString(2).padStart(16, 0)
    const MSBInDecimal = parseInt(dataIn16Bit.slice(0, 8), 2)
    const LSBInDecimal = parseInt(dataIn16Bit.slice(8), 2)
    return [MSBInDecimal, LSBInDecimal]
}

const utf16ToChar = (arr) => {
    if (arr == undefined || arr.length === 0) return
    return arr.map((element) => String.fromCharCode(element))
}
const hexapodPABytesToSensorData = (receivedPABytes, selectedPort) => {
    let deviceVersion = sessionStorage.getItem('deviceVersion')
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    console.log('JJJJJJJJ', receivedPABytes, selectedPort)
    if (selectedPort == 23)
        // Battery
        return receivedPABytes[28]
    else if (selectedPort == 25)
        // AccelerometerX
        return (
            MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]]) - 32768
        )
    else if (selectedPort == 26)
        // AccelerometerY
        return (
            MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]]) - 32768
        )
    else if (selectedPort == 27)
        // AccelerometerZ
        return (
            MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]]) - 32768
        )
    else if (selectedPort == 28)
        // GyroX
        return (
            MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]]) - 2500
        )
    else if (selectedPort == 29)
        // GyroY
        return (
            MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]]) - 2500
        )
    else if (selectedPort == 30)
        // GyroZ
        return (
            MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]]) - 2500
        )
    else if (selectedPort == 31)
        // A1
        return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    else if (selectedPort == 32)
        // A2
        return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    else if (selectedPort == 33)
        // B1
        return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    else if (selectedPort == 34)
        // B2
        return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    else if (selectedPort == 35)
        // C1
        return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
    else if (selectedPort == 36)
        // C2
        return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
    else if (selectedPort == 37)
        // D1
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    else if (selectedPort == 38)
        // D2
        return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
    else if (selectedPort == 24)
        // head ultrasonic
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    else if (selectedPort == 5)
        // servo 1
        return receivedPABytes[16]
    else if (selectedPort == 6)
        // servo 2
        return receivedPABytes[17]
    else if (selectedPort == 7)
        // servo 3
        return receivedPABytes[18]
    else if (selectedPort == 8)
        // servo 4
        return receivedPABytes[19]
    else if (selectedPort == 9)
        // servo 5
        return receivedPABytes[20]
    else if (selectedPort == 10)
        // servo 6
        return receivedPABytes[21]
    else if (selectedPort == 11)
        // servo 7
        return receivedPABytes[22]
    else if (selectedPort == 12)
        // servo 8
        return receivedPABytes[23]
    else if (selectedPort == 13)
        // servo 9
        return receivedPABytes[24]
    else if (selectedPort == 14)
        // servo 10
        return receivedPABytes[25]
    else if (selectedPort == 15)
        // servo 11
        return receivedPABytes[26]
    else if (selectedPort == 16)
        // servo 12
        return receivedPABytes[27]
    else if (selectedPort == 17)
        // servo 13
        return receivedPABytes[28]
    else if (selectedPort == 18)
        // servo 14
        return receivedPABytes[29]
    else if (selectedPort == 19)
        // servo 15
        return receivedPABytes[30]
    else if (selectedPort == 20)
        // servo 16
        return receivedPABytes[31]
    else if (selectedPort == 21)
        // servo 17
        return receivedPABytes[32]
    else if (selectedPort == 22)
        // servo 18
        return receivedPABytes[33]
}

const humanoidPABytesToSensorData = (receivedPABytes, selectedPort) => {
    // let zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    let deviceVersion = sessionStorage.getItem('deviceVersion')
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    // console.log('JJJJJJJJ', receivedPABytes)
    if (selectedPort == 21)
        // Battery
        return ['1', '2'].includes(deviceVersion[0])
            ? receivedPABytes[28]
            : receivedPABytes[18]
    else if (selectedPort == 23)
        // AccelerometerX
        return ['1', '2'].includes(deviceVersion[0])
            ? MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]]) -
                  32768
            : MSBLSBToDecimal([receivedPABytes[20], receivedPABytes[19]]) -
                  30000
    else if (selectedPort == 24)
        // AccelerometerY
        return ['1', '2'].includes(deviceVersion[0])
            ? MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]]) -
                  32768
            : MSBLSBToDecimal([receivedPABytes[22], receivedPABytes[21]]) -
                  30000
    else if (selectedPort == 25)
        // AccelerometerZ
        return ['1', '2'].includes(deviceVersion[0])
            ? MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]]) -
                  32768
            : MSBLSBToDecimal([receivedPABytes[24], receivedPABytes[23]]) -
                  30000
    else if (selectedPort == 26)
        // GyroX
        return ['1', '2'].includes(deviceVersion[0])
            ? MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]]) - 2500
            : MSBLSBToDecimal([receivedPABytes[26], receivedPABytes[25]]) -
                  30000
    else if (selectedPort == 27)
        // GyroY
        return ['1', '2'].includes(deviceVersion[0])
            ? MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]]) - 2500
            : MSBLSBToDecimal([receivedPABytes[28], receivedPABytes[27]]) -
                  30000
    else if (selectedPort == 28)
        // GyroZ
        return ['1', '2'].includes(
            deviceVersion[0] ||
                (connectedDevice == 'Hexapod' && deviceVersion[0] == '2')
        )
            ? MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]]) - 2500
            : MSBLSBToDecimal([receivedPABytes[30], receivedPABytes[29]]) -
                  30000
    else if (selectedPort == 31)
        // A1
        return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    else if (selectedPort == 32)
        // A2
        return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    else if (selectedPort == 33)
        // B1
        return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    else if (selectedPort == 34)
        // B2
        return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    else if (selectedPort == 35)
        // C1
        return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
    else if (selectedPort == 36)
        // C2
        return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
    else if (selectedPort == 37)
        // D1
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    else if (selectedPort == 38)
        // D2
        return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
    else if (selectedPort == 22)
        // head ultrasonic
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    // else if (selectedPort == 5)
    //     // servo 1
    //     return receivedPABytes[16]
    // else if (selectedPort == 6)
    //     // servo 2
    //     return receivedPABytes[17]
    // else if (selectedPort == 7)
    //     // servo 3
    //     return receivedPABytes[18]
    // else if (selectedPort == 8)
    //     // servo 4
    //     return receivedPABytes[19]
    // else if (selectedPort == 9)
    //     // servo 5
    //     return receivedPABytes[20]
    // else if (selectedPort == 10)
    //     // servo 6
    //     return receivedPABytes[21]
    // else if (selectedPort == 11)
    //     // servo 7
    //     return receivedPABytes[22]
    // else if (selectedPort == 12)
    //     // servo 8
    //     return receivedPABytes[23]
    // else if (selectedPort == 13)
    //     // servo 9
    //     return receivedPABytes[24]
    // else if (selectedPort == 14)
    //     // servo 10
    //     return receivedPABytes[25]
    // else if (selectedPort == 15)
    //     // servo 11
    //     return receivedPABytes[26]
    // else if (selectedPort == 16)
    //     // servo 12
    //     return receivedPABytes[27]
    // else if (selectedPort == 17)
    //     // servo 13
    //     return receivedPABytes[28]
    // else if (selectedPort == 18)
    //     // servo 14
    //     return receivedPABytes[29]
    // else if (selectedPort == 19)
    //     // servo 15
    //     return receivedPABytes[30]
    // else if (selectedPort == 20)
    //     // servo 16
    //     return receivedPABytes[31]
}
const klawPABytesToSensorData = (receivedPABytes, selectedPort) => {
    const PCVersion = sessionStorage.getItem('PCVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    if (selectedPort == 31)
        // A1
        return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    else if (selectedPort == 32)
        // A2
        return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    else if (selectedPort == 33)
        // B1
        return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    else if (selectedPort == 34)
        // B2
        return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    else if (selectedPort == 0) {
        //pot1
        return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
    } else if (selectedPort == 1) {
        //pot2
        return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
    } else if (selectedPort == 2) {
        //pot3
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    } else if (selectedPort == 3) {
        //pot4
        return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
    } else if (selectedPort == 4) {
        //pot5
        return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
    } else if (selectedPort == 5) {
        //pot6
        return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
    }
    // if (selectedPort == 0.2) {
    //     //joystick1_y
    //     return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
    // } else if (selectedPort == 0.1) {
    //     //joystick1_x
    //     return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
    // } else if (selectedPort == 1.2) {
    //     //joystick2_y
    //     return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    // } else if (selectedPort == 1.1) {
    //     //joystick2_x
    //     return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
    // } else if (selectedPort == 2.2) {
    //     //joystick3_y
    //     return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
    // } else if (selectedPort == 2.1) {
    //     //joystick3_x
    //     return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
    // } else if (selectedPort == 4) {
    //     //switch1
    //     return MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]])
    // } else if (selectedPort == 5) {
    //     //switch2
    //     return MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]])
    // } else if (selectedPort == 6) {
    //     //switch3
    //     return MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]])
    // } else if (selectedPort == 31) {
    //     //joystick3_y
    //     return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    // } else if (selectedPort == 32) {
    //     //switch1
    //     return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    // } else if ((selectedPort = 33)) {
    //     //switch2
    //     return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    // } else if (selectedPort == 34) {
    //     //switch3
    //     return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    // }
}

const playComputerPABytesToSensorData = (receivedPABytes, selectedPort) => {
    // console.log('recieved PA', receivedPABytes)
    if (selectedPort == 2)
        // A1
        return MSBLSBToDecimal([receivedPABytes[1], receivedPABytes[0]])
    else if (selectedPort == 3)
        // A2
        return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    else if (selectedPort == 4)
        // B1
        return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    else if (selectedPort == 5)
        // B2
        return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    else if (selectedPort == 6)
        // C1
        return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    else if (selectedPort == 7)
        // C2
        return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
    else if (selectedPort == 8)
        // D1
        return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
    else if (selectedPort == 9)
        // D2
        return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
    else if (selectedPort == 10)
        // E1
        return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
    else if (selectedPort == 11)
        // E2
        return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
    else if (selectedPort == 12)
        // F1
        return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
    else if (selectedPort == 13)
        // F2
        return MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]])
    else if (selectedPort == 14)
        // Mic
        return MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]])
    else if (selectedPort == 15)
        // Temperature
        return MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]])
    else if (selectedPort == 16)
        // Touch Pad 0
        return MSBLSBToDecimal([receivedPABytes[29], receivedPABytes[28]])
    else if (selectedPort == 17)
        // Touch Pad 1
        return MSBLSBToDecimal([receivedPABytes[31], receivedPABytes[30]])
    else if (selectedPort == 18)
        // Touch Pad 2
        return MSBLSBToDecimal([receivedPABytes[33], receivedPABytes[32]])
    else if (selectedPort == 19)
        // Touch Pad 3
        return MSBLSBToDecimal([receivedPABytes[35], receivedPABytes[34]])
    else if (selectedPort == '20L')
        // Light
        return MSBLSBToDecimal([receivedPABytes[37], receivedPABytes[36]])
    else if (selectedPort == '20D')
        // Distance
        return receivedPABytes[37]
    else if (selectedPort == '20G') {
        // Gesture
        if (receivedPABytes[38] === '1') return 'Down'
        else if (receivedPABytes[38] === '2') return 'Up'
        else if (receivedPABytes[38] === '3') return 'Right'
        else if (receivedPABytes[38] === '4') return 'Left'
        else return receivedPABytes[38]
    } else if (selectedPort == '20C')
        // Color [R, G, B]
        return [receivedPABytes[36], receivedPABytes[37], receivedPABytes[38]]
    else if (selectedPort == '21X')
        // Accelerometer X
        return MSBLSBToDecimal([receivedPABytes[40], receivedPABytes[39]])
    else if (selectedPort == '21Y')
        // Accelerometer Y
        return MSBLSBToDecimal([receivedPABytes[42], receivedPABytes[41]])
    else if (selectedPort == '21Z')
        // Accelerometer Z
        return MSBLSBToDecimal([receivedPABytes[44], receivedPABytes[43]])
    else if (selectedPort == '21GX')
        // Gyro X
        return MSBLSBToDecimal([receivedPABytes[47], receivedPABytes[46]])
    else if (selectedPort == '21GY')
        // Gyro Y
        return MSBLSBToDecimal([receivedPABytes[49], receivedPABytes[48]])
    else if (selectedPort == '21GZ')
        // Gyro Z
        return MSBLSBToDecimal([receivedPABytes[51], receivedPABytes[50]])
    else if (selectedPort == 22)
        // Battery
        return receivedPABytes[45]

    return
}

//roboki sensor PA bytes  data format
const robokiPABytesToSensorData = (receivedPABytes, selectedPort) => {
    if (selectedPort == 2)
        // A1
        return MSBLSBToDecimal([receivedPABytes[1], receivedPABytes[0]])
    else if (selectedPort == 3)
        // A2
        return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
    else if (selectedPort == 6)
        // B1
        return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
    else if (selectedPort == 7)
        // B2
        return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
    else if (selectedPort == 23)
        // switch1
        return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
    else if (selectedPort == 24)
        // switch2
        return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
}

//from the zing board action data convert to the session storage object createActionState like
const actionConverter = (data) => {
    console.log('recive FA data', data)
    let actionNumber = MSBLSBToDecimal([data[0], data[1]])
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const deviceKey = generateDeviceDataKey(connectedDevice, deviceVersion)

    const defaultServoValue = DEFAULTSERVOVALUES[deviceKey]
    data.splice(0, 2)
    console.log(data)

    let commonActionState = {}
    if (connectedDevice === 'Hexapod') {
        for (let i = 1; i <= 19; i++) {
            if (i == 19) {
                commonActionState['T'] = { isSelected: true }
            } else {
                commonActionState[i] = { isSelected: false }
            }
        }
    } else {
        for (let i = 1; i <= 18; i++) {
            if (i == 17) {
                commonActionState['H'] = { isSelected: false }
            } else if (i == 18) {
                commonActionState['T'] = { isSelected: true }
            } else {
                commonActionState[i] = { isSelected: false }
            }
        }
    }

    let allActions = []

    let servoNum = 0
    let actionData = {}
    // debugger
    for (let i = 0; i < data.length; i++) {
        if (servoNum == 17 && connectedDevice === 'Humanoid') {
            //last value is the head servo value which is not a  format of msb lsb so directly push the value
            //after that action data push intu allActions array, and create a ne obj for actionData
            const value = data[i]
            if (!commonActionState['H'].isSelected) {
                commonActionState['H'].isSelected =
                    defaultServoValue.H != value && true
            }

            actionData.H = {
                isEnabled: defaultServoValue.H == value ? false : true,
                value,
                // value: data[i],
            }
            let d = {
                data: actionData,
                id: Math.random().toString(16).slice(2, 6),
            }
            allActions.push(d)
            actionData = {}
            servoNum = 0
        } else if (servoNum == 0) {
            //1st value is the time value
            const value = MSBLSBToDecimal([data[i], data[i + 1]])
            actionData.T = {
                isEnabled: defaultServoValue.T === value ? false : true,
                value,
                // value: MSBLSBToDecimal([data[i], data[i + 1]]),
            }
            i++
            servoNum++
        } else {
            const value = MSBLSBToDecimal([data[i], data[i + 1]])

            if (!commonActionState[servoNum].isSelected) {
                commonActionState[servoNum].isSelected =
                    defaultServoValue[servoNum] !== value && true
            }
            actionData[servoNum] = {
                isEnabled: defaultServoValue[servoNum] === value ? false : true,
                value,
                // value: MSBLSBToDecimal([data[i], data[i + 1]]),
            }

            i++
            servoNum++

            //in hexapod 18 servo  motor present so after 18th servo data push into the actionData object,
            //actionData object push intu the allActions array, and create a new object actionData.

            if (servoNum === 19 && connectedDevice === 'Hexapod') {
                let d = {
                    data: actionData,
                    id: Math.random().toString(16).slice(2, 6),
                }
                allActions.push(d)
                actionData = {}
                servoNum = 0
            }
        }
    }

    return {
        commonActionState: commonActionState,
        allActions: allActions,
    }
}

//this key are used the separet each device
const generateDeviceDataKey = (Device, version) => {
    if (Device == 'Ace' && version[0] == '0') {
        return 'ACE0'
    } else if (Device == 'Ace' && version[0] == '1') {
        console.log('FFFFFFFF3333', Device, version)
        return 'ACE1'
    } else if (Device == 'Hexapod' && version[0] == '1') {
        return 'HEXAPOD1'
    } else if (Device == 'Hexapod' && version[0] == '2') {
        return 'HEXAPOD2'
    } else if (Device == 'Humanoid' && version[0] == '0') {
        return 'HUMANOID0'
    } else if (Device == 'Humanoid' && version[0] == '1') {
        return 'HUMANOID1'
    } else if (Device == 'Humanoid' && version[0] == '2') {
        return 'HUMANOID2'
    } else if (Device == 'Roboki' && version[0] == '1') {
        return 'ROBOKI1'
    } else if (Device == 'Klaw' && version[0] == '1') {
        return 'KLAW1'
    } else {
        return 'HUMANOID2'
    }
}

export {
    MSBLSBToDecimal,
    decimalToMSBLSB,
    utf16ToChar,
    humanoidPABytesToSensorData,
    hexapodPABytesToSensorData,
    playComputerPABytesToSensorData,
    robokiPABytesToSensorData,
    actionConverter,
    generateDeviceDataKey,
    klawPABytesToSensorData,
}
