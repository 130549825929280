import renderPrgImage from '../../../source/programImg'
import Slider from 'react-slick'
import closeBtn from '../../../Assets/img/close.png'
import SampleNextArrow from '../../flowchart_port/components/Input/components/HelpScreen/components/NextArrow'
import SamplePrevArrow from '../../flowchart_port/components/Input/components/HelpScreen/components/PreviousArrow'

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

function ActionCreateHelp(props) {
    const { closeModal } = props
    const connectedDevice = sessionStorage.getItem('connectedDevice')

    let deviceName = 'Zing'
    if (connectedDevice == 'Hexapod') deviceName = 'Crawle'

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                background: '#fff',
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: '1000',
                position: 'absolute',
            }}
        >
            <div
                onClick={closeModal}
                style={{
                    borderRadius: '50%',
                    zIndex: '2000',
                    position: 'absolute',
                    top: '15%',
                    right: '18%',
                }}
            >
                <img src={closeBtn} style={{ width: '7vh', height: 'auto' }} />
            </div>
            <Slider
                {...settings}
                style={{
                    transform: 'translate(-50%,-50%)',
                    top: '46%',
                    left: '50%',
                    zIndex: '10',
                    position: 'absolute',
                }}
            >
                {/* First screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img
                        src={renderPrgImage('createActionH1')}
                        style={{ width: '100%' }}
                    />
                    <div
                        style={{
                            // height: '6%',
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '41.5%',
                            left: '31%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '7px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '2px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                            }}
                        >
                            Shows the group of actions created. Plus sign is to
                            add more actions. The actions can be individually
                            controlled. Hover on the Action name to Play, Copy
                            or Delete the action. You can also click and drag
                            the action to rearrange the position.
                        </p>
                    </div>
                </div>
                {/* second screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img
                        src={renderPrgImage('createActionH2')}
                        style={{ width: '100%' }}
                    />

                    <div
                        style={{
                            // height: '6%',
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '60.5%',
                            left: '43%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '7px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '2px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                            }}
                        >
                            Set time to specify how long the action will play
                            out before going to the next action. Individual
                            motors can be enabled/disabled and set for the
                            action with Live Write to see it in real time.
                        </p>
                    </div>
                </div>

                {/* Third screen */}
                {/* <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img
                        src={renderPrgImage('createActionH3')}
                        style={{ width: '100%' }}
                    />

                    <div
                        style={{
                            // height: '6%',
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '57.5%',
                            left: '54.3%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '7px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '2px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                            }}
                        >
                            Buttons to enable/disable the motors and control in
                            the actions programmed in the next screen.
                        </p>
                    </div>
                </div> */}

                {/* fourth screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img
                        src={renderPrgImage('createActionH4')}
                        style={{ width: '100%' }}
                    />

                    <div
                        style={{
                            // height: '6%',
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',

                            top: '69.5%',
                            left: '57.6%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '7px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '2px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                            }}
                        >
                            Play button to run all the actions in the specified
                            sequence.
                        </p>
                    </div>
                    <div
                        style={{
                            // height: '6%',
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '53.2%',
                            left: '59%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '7px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '2px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                            }}
                        >
                            Save the actions to {deviceName} or local machine to
                            be used in the Programming screens.
                        </p>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
export default ActionCreateHelp
