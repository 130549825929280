import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import SelectStyle from './SMSlider.module.css'
import { Ply, Prog, Bild } from '../../../source/index'
import renderImage from '../../../source/importImg'
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
    ? true
    : false
export class SMSlider extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            className: 'setting',
        }
        return (
            <div
                className="container"
                style={{
                    height: '100vh',
                    background: 'white',
                    left: '19.5%',
                }}
            >
                <div
                    className="Ss_sliderPlay"
                    style={{
                        background: '#fff',
                        width: '100vw',
                        height: '100vh',
                        position: 'relative',
                    }}
                >
                    {' '}
                    <Slider
                        {...settings}
                        style={{
                            top: '46%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <div>
                            <div>
                                <img
                                    className={SelectStyle.img}
                                    src={renderImage('SelectionPlay')}
                                />
                            </div>

                            <div
                                className={SelectStyle.Hel}
                                style={!isPCv1 ? { right: '72%' } : null}
                            >
                                <p className={SelectStyle.Hel_P}>
                                    In this mode, you can directly interact with
                                    the PlayComputer without having to program
                                    it.
                                </p>
                            </div>
                        </div>
                        {/* Learn */}
                        <div>
                            <img
                                className={SelectStyle.img}
                                src={renderImage('SelectionLearn')}
                            />

                            <div
                                className={SelectStyle.con}
                                style={!isPCv1 ? { right: '63.9%' } : null}
                            >
                                <p className={SelectStyle.con_P}>
                                    This is an unique platform to learn, to
                                    connect, to share & to socialise with
                                    everybody in the Tinkering field. On
                                    clicking this button, it will take you to a
                                    separate app/website- tinkerbunker.com
                                </p>
                            </div>
                        </div>
                        {/* code */}
                        <div>
                            <img
                                className={SelectStyle.img}
                                src={renderImage('SelectionCode')}
                            />

                            <div
                                className={SelectStyle.con1}
                                style={!isPCv1 ? { right: '55%' } : null}
                            >
                                <p className={SelectStyle.con_P1}>
                                    This mode is a sandbox mode for the user to
                                    explore the complete programming platform to
                                    create code for the Playcomputer. Here the
                                    user will get to explore both visual based &
                                    script based programming.
                                </p>
                            </div>
                        </div>
                        {/* appMode */}
                        <div>
                            <img
                                className={SelectStyle.img}
                                src={renderImage('SelectionAppMode')}
                            />

                            <div
                                className={SelectStyle.con2}
                                style={!isPCv1 ? { right: '43.3%' } : null}
                            >
                                <p className={SelectStyle.con_P2}>
                                    Using this feature, when you want to use the
                                    power of your smartphone to program & build
                                    AI based smart projects using the Play
                                    Computer
                                </p>
                            </div>
                        </div>
                        {isPCv1 && (
                            <div>
                                <img
                                    className={SelectStyle.img}
                                    src={renderImage('SelectionDataGraph')}
                                />

                                <div className={SelectStyle.con3}>
                                    <p className={SelectStyle.con_P3}>
                                        This is a Sensor data plotting feature.
                                        You can read the PeeCee's internal &
                                        external sensor's data. The Sensor's
                                        data is plotted either on a Linear Graph
                                        or as a Bar graph format.
                                    </p>
                                </div>
                            </div>
                        )}
                        {/* Data */}
                    </Slider>
                </div>{' '}
            </div>
        )
    }
}

export default SMSlider
