let connectedDevice = sessionStorage.getItem('connectedDevice')
const COMMANDS = {
    Humanoid: [
        'hello',
        'hai',
        'hi',
        'hey',
        'hay',
        // 'moveforward',
        'forward',
        // 'backward',
        'back',
        // 'movefront',
        // 'movebackward',
        // 'walkforward',
        // 'waltbackward',
        'turnleft',
        'turnright',
        'moveleft',
        'moveright',
        'sidekickleft',
        'slidekickright',
        'frontkickleft',
        'frontkickright',
        'wave',
        'attention',
        'salute',
        'slideleft',
        'slideright',
        'fight',
        'pushup',
        'dance',
        'stop',
        // 'stopdance',
        // 'stopthedance',
        'bow',
        'squat',
        'pushup',
    ],
    Ace: [
        'lightoff',
        'whitelight',
        'redlight',
        'greenlight',
        'bluelight',
        'disco',
        'smile',
    ],
    Hexapod: [
        'hello',
        'hai',
        'hi',
        'hey',
        'hay',
        'forward',
        'front',
        'back',
        // 'backward',
        'turnleft',
        'turnright',
        'wave',
        'attention',
        'slideleft',
        'slideright',
        'defence',
        'greet',
        'fight',
        'dance',
        'attack',
        'turnaround',
        // 'around',
        'stop',
    ],
}

const NEGATIONS = ['no', 'dont', "don't"]

const pickCommandFromSentence = (sentence, device) => {
    const keywords = []
    keywords.push(...COMMANDS[device], ...NEGATIONS)
    const validKeyWords = keywords.filter((command) =>
        sentence.includes(command)
    )

    let commands = validKeyWords.map((keyWord) => {
        if (
            ['hello', 'hai', 'hi', 'hay', 'hey', 'greet', 'wave'].includes(
                keyWord
            ) &&
            connectedDevice == 'Hexapod'
        )
            return 'greeting'
        if (
            ['around', 'turn around', 'turnaround'].includes(keyWord) &&
            connectedDevice == 'Hexapod'
        )
            return 'turnaround'
        else if (['hello', 'hai', 'hi', 'hay', 'hey'].includes(keyWord))
            return 'wave'

        return keyWord
    })

    // if encountered a negation the next command is ignored
    let encounteredNegation = false
    commands.map((command) => {
        if (NEGATIONS.includes(command)) encounteredNegation = true
    })
    if (encounteredNegation) commands = []

    commands = commands.map((command) => command.toUpperCase())
    return commands
}

const speechToCommand = (input, device) => {
    let sentence = input.replaceAll(' ', '').toLowerCase()
    return pickCommandFromSentence(sentence, device)
}

export default speechToCommand
