import React, { useState, useEffect, useLayoutEffect } from 'react'
import style from './Line.module.css'
import { useHistory, useLocation } from 'react-router'
import renderPrgImage from '../../../../source/programImg'
import unicodeToChar from '../../../../utils/unicodeToChar'
import { connect } from 'react-redux'
import stylee from '../Graph.module.css'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import LineGraphChart from './LineGraphChart'
import {
    continousPABytesReadToggle_v1,
    HUMANOID_PA_SEND_BYTES,
} from '../../../ReusableComponents/ByteTransfer'
import { startReadingBytes } from '../../../ReusableComponents/ByteTransfer/byteTransfer'
import DisplayComponent from '../TimeComponent/DisplayComponent'
import {
    sendBytes,
    readBytesPa,
} from '../../../ReusableComponents/ByteTransfer'
import { humanoidPABytesToSensorData } from '../../../ReusableComponents/ByteTransfer'
import {
    generateDeviceDataKey,
    robokiPABytesToSensorData,
} from '../../../ReusableComponents/ByteTransfer/utils'
import { graphSidebarData } from '../GraphsensorsPortData'
let time = 0
// let endTime = time
let startTime = 0
// let currentData = []
// let PAbytesData = []
let dataQues = []
let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
// const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'

function LineGraph(props) {
    let history = useHistory()
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    const reduxInternalaccessories =
        props.indexData.graphPlotStorage.internalaccessories
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const backBtn = async () => {
        time = 0
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        const intervalId = sessionStorage.getItem('readSetIntervalId')

        if (intervalId !== null) {
            // if (
            //     (deviceVersion?.includes('1.0.0') &&
            //         ['Ace'].includes(connectedDevice)) ||
            //     deviceVersion?.includes('2.0.0')
            // ) {
            props.worker.postMessage({
                type: 'write',
                value: 'M8',
            })
            // } else {
            //     await sendBytes(
            //         ['M'.charCodeAt(0), '8'.charCodeAt(0)],
            //         props.indexData.webSerial.port
            //     )
            // }
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
        }

        console.log('gskbytes', props.indexData.webSerial.port)

        history.push('/graphPlotter')
    }

    // const [isMic, setMic] = useState(false)
    // const [currentActiveData, setCurrentActiveData] = useState([])
    const [datas, setDatas] = useState([
        {
            pv: '',
            uv: 0,
            mic: 0,
            temp: 0,
            batt: 0,
            touch0: 0,
            touch2: 0,
            touch3: 0,
            acceX: 0,
            acceY: 0,
            acceZ: 0,
            gyroX: 0,
            gyroY: 0,
            gyroZ: 0,
            ultrasonic: 0,
            red: 0,
            green: 0,
            blue: 0,
            distance: 0,
            light: 0,
            gesture: 0,
            switch1: 0,
            switch2: 0,
            A1: 0,
            A2: 0,
            B1: 0,
            B2: 0,
            C1: 0,
            C2: 0,
            D1: 0,
            D2: 0,
            E1: 0,
            E2: 0,
            F1: 0,
            F2: 0,
        },
    ])
    // const [datasTmp, setDatasTmp] = useState([])
    const [isPauseBtn, setPauseBtn] = useState(false)
    const [isArrowBtn, setArrowBtn] = useState(false)
    // const [isConnectedPort, setConnectedPort] = useState('')
    // let currentData = []
    const [timer, setTimer] = useState({ ms: 0, s: 0, m: 0, h: 0 })
    const [interv, setInterv] = useState()
    // const [status, setStatus] = useState(0)
    // timer

    var updatedMs = timer.ms,
        updatedS = timer.s,
        updatedM = timer.m,
        updatedH = timer.h

    const pauseHandleClick = async () => {
        if (props.indexData.webSerial.isConnected) {
            handleReadByte_v1(props.indexData.webSerial.port)
            // if (connectedDevice == 'Ace') {
            //     deviceVersion?.includes('1.0.0')
            //         ? handleReadByte_v1(props.indexData.webSerial.port)
            //         : handleReadByte(props.indexData.webSerial.port)
            // } else {
            //     deviceVersion?.includes('2.0.0')
            //         ? handleReadByte_v1(props.indexData.webSerial.port)
            //         : handleReadByte(props.indexData.webSerial.port)
            // }
        }
        console.log(
            'WORKING',
            props.indexData.webSerial.isConnected,
            isPauseBtn
        )
        setPauseBtn(!isPauseBtn)
        //     handleReadByte_v1(props.indexData.webSerial.port)
        // }
    }
    const start = () => {
        console.log('STARTTTTTTTT')
        run()
        // setStatus(1)
        setInterv(setInterval(run, 10))
    }

    const run = () => {
        if (updatedM === 60) {
            updatedH++
            updatedM = 0
        }
        if (updatedS === 60) {
            updatedM++
            updatedS = 0
        }
        if (updatedMs === 100) {
            updatedS++
            updatedMs = 0
        }
        updatedMs++
        return setTimer({
            ms: updatedMs,
            s: updatedS,
            m: updatedM,
            h: updatedH,
        })
    }

    const stop = () => {
        console.log('STOPPPPPP')
        clearInterval(interv)
        // setStatus(2)
    }

    const reset = () => {
        clearInterval(interv)
        // setStatus(0)
        setTimer({ ms: 0, s: 0, m: 0, h: 0 })
    }

    const resume = () => start()

    const handleClick = () => {
        if (isArrowBtn == false) {
            console.log('false')
            // sessionStorage.setItem('ArrowBtn', false)
            setArrowBtn(true)
        } else {
            console.log('true')
            // sessionStorage.setItem('ArrowBtn', true)
            setArrowBtn(false)
        }
    }

    const MSBLSBToDecimal = (data) => {
        if (data.length !== 2) return
        const [MSBInDecimal, LSBInDecimal] = data
        const MSBInBinary = parseInt(MSBInDecimal).toString(2).padStart(8, 0)
        const LSBInBinary = parseInt(LSBInDecimal).toString(2).padStart(8, 0)
        const dataInBinary = MSBInBinary + LSBInBinary
        const dataInDecimal = parseInt(dataInBinary, 2)

        return dataInDecimal
    }
    const playComputerPABytesToSensorData = (receivedPABytes, selectedPort) => {
        if (selectedPort == 2)
            // A1
            return MSBLSBToDecimal([receivedPABytes[1], receivedPABytes[0]])
        else if (selectedPort == 3)
            // A2
            return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
        else if (selectedPort == 4)
            // B1
            return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
        else if (selectedPort == 5)
            // B2
            return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
        else if (selectedPort == 6)
            // C1
            return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
        else if (selectedPort == 7)
            // C2
            return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
        else if (selectedPort == 8)
            // D1
            return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
        else if (selectedPort == 9)
            // D2
            return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
        else if (selectedPort == 10)
            // E1
            return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
        else if (selectedPort == 11)
            // E2
            return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
        else if (selectedPort == 12)
            // F1
            return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
        else if (selectedPort == 13)
            // F2
            return MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]])
        else if (selectedPort == 14) {
            // Mic
            return MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]])
        } else if (selectedPort == 15)
            // Temperature
            return MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]])
        else if (selectedPort == 16)
            // Touch Pad 0
            return MSBLSBToDecimal([receivedPABytes[29], receivedPABytes[28]])
        else if (selectedPort == 17)
            // Touch Pad 1
            return MSBLSBToDecimal([receivedPABytes[31], receivedPABytes[30]])
        else if (selectedPort == 18)
            // Touch Pad 2
            return MSBLSBToDecimal([receivedPABytes[33], receivedPABytes[32]])
        else if (selectedPort == 19)
            // Touch Pad 3
            return MSBLSBToDecimal([receivedPABytes[35], receivedPABytes[34]])
        else if (selectedPort == '20L')
            // Light
            return receivedPABytes[36]
        else if (selectedPort == '20D')
            // Distance
            return receivedPABytes[37]
        else if (selectedPort == '20G') {
            // Gesture
            if (receivedPABytes[38] === '1') return 'Down'
            else if (receivedPABytes[38] === '2') return 'Up'
            else if (receivedPABytes[38] === '3') return 'Right'
            else if (receivedPABytes[38] === '4') return 'left'
            else return receivedPABytes[38]
        } else if (selectedPort == '20C')
            // Color [R, G, B]
            return [
                receivedPABytes[36],
                receivedPABytes[37],
                receivedPABytes[38],
            ]
        else if (selectedPort == '21X')
            // Accelerometer X
            return MSBLSBToDecimal([receivedPABytes[40], receivedPABytes[39]])
        else if (selectedPort == '21Y')
            // Accelerometer Y
            return MSBLSBToDecimal([receivedPABytes[42], receivedPABytes[41]])
        else if (selectedPort == '21Z')
            // Accelerometer Z
            return MSBLSBToDecimal([receivedPABytes[44], receivedPABytes[43]])
        else if (selectedPort == '21GX')
            // Gyro X
            return MSBLSBToDecimal([receivedPABytes[47], receivedPABytes[46]])
        else if (selectedPort == '21GY')
            // Gyro Y
            return MSBLSBToDecimal([receivedPABytes[49], receivedPABytes[48]])
        else if (selectedPort == '21GZ')
            // Gyro Z
            return MSBLSBToDecimal([receivedPABytes[51], receivedPABytes[50]])
        else if (selectedPort == 22)
            // Battery
            return receivedPABytes[45]

        return
    }

    const setPABytes_v0 = () => {
        let bytesData = Array(11).fill('O'.charCodeAt())

        bytesData.unshift('A'.charCodeAt())
        bytesData.unshift('P'.charCodeAt())

        let sessionData = JSON.parse(sessionStorage.getItem('graphPlotStorage'))
        console.log('session data', sessionData)
        let internalAccessories = sessionData.internalaccessories
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))

        if (internalAccessories.portsData[0].ports[0].isActive) {
            if (internalAccessories.portsData[0].ports[0].isDigital === true) {
                bytesData[2] = 'A'.charCodeAt()
            } else {
                bytesData[2] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[0].ports[1].isActive) {
            if (internalAccessories.portsData[0].ports[1].isDigital === true) {
                bytesData[3] = 'A'.charCodeAt()
            } else {
                bytesData[3] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[0].isActive) {
            if (internalAccessories.portsData[1].ports[0].isDigital === true) {
                bytesData[4] = 'A'.charCodeAt()
            } else {
                bytesData[4] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[1].isActive) {
            if (internalAccessories.portsData[1].ports[1].isDigital === true) {
                bytesData[5] = 'A'.charCodeAt()
            } else {
                bytesData[5] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[0].isActive) {
            if (internalAccessories.portsData[2].ports[0].isDigital === true) {
                bytesData[6] = 'A'.charCodeAt()
            } else {
                bytesData[6] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[1].isActive) {
            if (internalAccessories.portsData[2].ports[1].isDigital === true) {
                bytesData[7] = 'A'.charCodeAt()
            } else {
                bytesData[7] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[0].isActive) {
            if (internalAccessories.portsData[3].ports[0].isDigital === true) {
                bytesData[8] = 'A'.charCodeAt()
            } else {
                bytesData[8] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[1].isActive) {
            if (internalAccessories.portsData[3].ports[1].isDigital === true) {
                bytesData[9] = 'A'.charCodeAt()
            } else {
                bytesData[9] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[4].ports[0].isActive) {
            if (internalAccessories.portsData[4].ports[0].isDigital === true) {
                bytesData[10] = 'A'.charCodeAt()
            } else {
                bytesData[10] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[4].ports[1].isActive) {
            if (internalAccessories.portsData[4].ports[1].isDigital === true) {
                bytesData[11] = 'A'.charCodeAt()
            } else {
                bytesData[11] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[5].ports[0].isActive) {
            if (internalAccessories.portsData[5].ports[0].isDigital === true) {
                bytesData[12] = 'A'.charCodeAt()
            } else {
                bytesData[12] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[5].ports[1].isActive) {
            if (internalAccessories.portsData[5].ports[1].isDigital === true) {
                bytesData[13] = 'A'.charCodeAt()
            } else {
                bytesData[13] = 'I'.charCodeAt()
            }
        }

        if (sessionData.internalaccessories.isMic) {
            bytesData[11] = 'M'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTemperature) {
            bytesData[12] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchZero) {
            bytesData[2] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchOne) {
            bytesData[4] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchTwo) {
            bytesData[6] = 'T'.charCodeAt()
        }

        if (
            sessionData.internalaccessories.Four_in_one_sensor.isDistanceSensors
        ) {
            bytesData[10] = 'D'.charCodeAt()
        }
        if (
            sessionData.internalaccessories.Four_in_one_sensor.isGestureSensor
        ) {
            bytesData[10] = 'G'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isColorSensor) {
            bytesData[10] = 'C'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isLightSensor) {
            bytesData[10] = 'L'.charCodeAt()
        }
        var bytes = unicodeToChar(bytesData)
        console.log('gskbytes', bytes)
        return bytesData
    }

    // for Pc v1 PA Byets
    const setPABytes_PCv1 = () => {
        let sendBytes = Array(25).fill('0'.charCodeAt())

        sendBytes[0] = 'P'.charCodeAt()
        sendBytes[1] = 'A'.charCodeAt()
        sendBytes[23] = 'E'.charCodeAt()
        sendBytes[24] = 'R'.charCodeAt()

        // let internalAccessories = JSON.parse(
        //     sessionStorage.getItem('graphPlotStorage')
        // ).internalaccessories
        let internalAccessories =
            props.indexData.graphPlotStorage.internalaccessories
        console.log(
            'gsk sensor',
            internalAccessories.portsData[0].ports[0].isDigital
        )
        console.log('LAXMI123', internalAccessories.portsData.A1)

        if (internalAccessories.portsData[0].ports[0].isActive) {
            if (internalAccessories.portsData[0].ports[0].isDigital === true) {
                sendBytes[2] = 'A'.charCodeAt()
            } else {
                sendBytes[2] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[0].ports[1].isActive) {
            if (internalAccessories.portsData[0].ports[1].isDigital === true) {
                sendBytes[3] = 'A'.charCodeAt()
            } else {
                sendBytes[3] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[0].isActive) {
            if (internalAccessories.portsData[1].ports[0].isDigital === true) {
                sendBytes[4] = 'A'.charCodeAt()
            } else {
                sendBytes[4] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[1].isActive) {
            if (internalAccessories.portsData[1].ports[1].isDigital === true) {
                sendBytes[5] = 'A'.charCodeAt()
            } else {
                sendBytes[5] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[0].isActive) {
            if (internalAccessories.portsData[2].ports[0].isDigital === true) {
                sendBytes[6] = 'A'.charCodeAt()
            } else {
                sendBytes[6] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[1].isActive) {
            if (internalAccessories.portsData[2].ports[1].isDigital === true) {
                sendBytes[7] = 'A'.charCodeAt()
            } else {
                sendBytes[7] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[0].isActive) {
            if (internalAccessories.portsData[3].ports[0].isDigital === true) {
                sendBytes[8] = 'A'.charCodeAt()
            } else {
                sendBytes[8] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[1].isActive) {
            if (internalAccessories.portsData[3].ports[1].isDigital === true) {
                sendBytes[9] = 'A'.charCodeAt()
            } else {
                sendBytes[9] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[4].ports[0].isActive) {
            if (internalAccessories.portsData[4].ports[0].isDigital === true) {
                sendBytes[10] = 'A'.charCodeAt()
            } else {
                sendBytes[10] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[4].ports[1].isActive) {
            if (internalAccessories.portsData[4].ports[1].isDigital === true) {
                sendBytes[11] = 'A'.charCodeAt()
            } else {
                sendBytes[11] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[5].ports[0].isActive) {
            if (internalAccessories.portsData[5].ports[0].isDigital === true) {
                sendBytes[12] = 'A'.charCodeAt()
            } else {
                sendBytes[12] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[5].ports[1].isActive) {
            if (internalAccessories.portsData[5].ports[1].isDigital === true) {
                sendBytes[13] = 'A'.charCodeAt()
            } else {
                sendBytes[13] = 'I'.charCodeAt()
            }
        }

        if (internalAccessories.isMic === true) {
            sendBytes[14] = 'M'.charCodeAt()
        }
        if (internalAccessories.isTemperature === true) {
            sendBytes[15] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchZero === true) {
            sendBytes[16] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchOne === true) {
            sendBytes[17] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchTwo === true) {
            sendBytes[18] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchThree === true) {
            sendBytes[19] = 'T'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isColorSensor === true) {
            sendBytes[20] = 'C'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isGestureSensor === true) {
            sendBytes[20] = 'G'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isDistanceSensors === true) {
            sendBytes[20] = 'D'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isLightSensor === true) {
            sendBytes[20] = 'L'.charCodeAt()
        }
        if (internalAccessories.isAccelerometer === true) {
            sendBytes[21] = 'A'.charCodeAt()
        }
        if (internalAccessories.isBattery === true) {
            sendBytes[22] = 'B'.charCodeAt()
        }
        var bytes = unicodeToChar(sendBytes)
        console.log('gskbytes', bytes)
        return sendBytes
    }

    //for zing v2 and roboki PA Byets
    const setPABytes_zingV1V2 = () => {
        let sendBytes = []
        let internalAccessories =
            props.indexData.graphPlotStorage.internalaccessories

        if (
            deviceVersion[0] == '2' &&
            ['Humanoid', 'Hexapod'].includes(connectedDevice)
        ) {
            sendBytes = Array(13).fill('0'.charCodeAt())

            sendBytes[11] = 'E'.charCodeAt()
            sendBytes[12] = 'R'.charCodeAt()
        } else {
            sendBytes = Array(10).fill('0'.charCodeAt())

            sendBytes[8] = 'E'.charCodeAt()
            sendBytes[9] = 'R'.charCodeAt()

            if (internalAccessories.isSwitchOne === true) {
                sendBytes[6] = 'I'.charCodeAt()
            }
            if (internalAccessories.isSwitchTwo === true) {
                sendBytes[7] = 'I'.charCodeAt()
            }
        }
        sendBytes[0] = 'P'.charCodeAt()
        sendBytes[1] = 'A'.charCodeAt()

        if (internalAccessories.portsData[0].ports[0].isActive) {
            if (internalAccessories.portsData[0].ports[0].isDigital === true) {
                sendBytes[2] = 'A'.charCodeAt()
            } else {
                sendBytes[2] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[0].ports[1].isActive) {
            if (internalAccessories.portsData[0].ports[1].isDigital === true) {
                sendBytes[3] = 'A'.charCodeAt()
            } else {
                sendBytes[3] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[0].isActive) {
            if (internalAccessories.portsData[1].ports[0].isDigital === true) {
                sendBytes[4] = 'A'.charCodeAt()
            } else {
                sendBytes[4] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[1].isActive) {
            if (internalAccessories.portsData[1].ports[1].isDigital === true) {
                sendBytes[5] = 'A'.charCodeAt()
            } else {
                sendBytes[5] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[0].isActive) {
            if (internalAccessories.portsData[2].ports[0].isDigital === true) {
                sendBytes[6] = 'A'.charCodeAt()
            } else {
                sendBytes[6] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[1].isActive) {
            if (internalAccessories.portsData[2].ports[1].isDigital === true) {
                sendBytes[7] = 'A'.charCodeAt()
            } else {
                sendBytes[7] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.isUltrasonic === true) {
            sendBytes[8] = 'U'.charCodeAt()
        }
        if (internalAccessories.isAccelerometer === true) {
            sendBytes[9] = 'A'.charCodeAt()
        }
        if (internalAccessories.isBattery === true) {
            sendBytes[10] = 'B'.charCodeAt()
        }
        var bytes = unicodeToChar(sendBytes)
        console.log('gskbytes', bytes)
        return sendBytes
    }

    const setPABytes_zingV0 = () => {
        HUMANOID_PA_SEND_BYTES[10] = 'B'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[11] = 'A'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[12] = 'G'.charCodeAt(0)

        let bytesData = HUMANOID_PA_SEND_BYTES

        console.log(bytesData)

        let sessionData = JSON.parse(sessionStorage.getItem('graphPlotStorage'))
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))
        console.log('sessionData', sessionData)
        let internalAccessories = sessionData.internalaccessories
        console.log('internalAccessories', internalAccessories)

        if (internalAccessories.portsData[0].ports[0].isActive) {
            if (internalAccessories.portsData[0].ports[0].isDigital === true) {
                bytesData[2] = 'A'.charCodeAt()
            } else {
                bytesData[2] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[0].ports[1].isActive) {
            if (internalAccessories.portsData[0].ports[1].isDigital === true) {
                bytesData[3] = 'A'.charCodeAt()
            } else {
                bytesData[3] = 'I'.charCodeAt()
            }
        }

        if (internalAccessories.portsData[1].ports[0].isActive) {
            if (internalAccessories.portsData[1].ports[0].isDigital === true) {
                bytesData[4] = 'A'.charCodeAt()
            } else {
                bytesData[4] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[1].ports[1].isActive) {
            if (internalAccessories.portsData[1].ports[1].isDigital === true) {
                bytesData[5] = 'A'.charCodeAt()
            } else {
                bytesData[5] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[0].isActive) {
            if (internalAccessories.portsData[2].ports[0].isDigital === true) {
                bytesData[6] = 'A'.charCodeAt()
            } else {
                bytesData[6] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[2].ports[1].isActive) {
            if (internalAccessories.portsData[2].ports[1].isDigital === true) {
                bytesData[7] = 'A'.charCodeAt()
            } else {
                bytesData[7] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[0].isActive) {
            if (internalAccessories.portsData[3].ports[0].isDigital === true) {
                bytesData[8] = 'A'.charCodeAt()
            } else {
                bytesData[8] = 'I'.charCodeAt()
            }
        }
        if (internalAccessories.portsData[3].ports[1].isActive) {
            if (internalAccessories.portsData[3].ports[1].isDigital === true) {
                bytesData[9] = 'A'.charCodeAt()
            } else {
                bytesData[9] = 'I'.charCodeAt()
            }
        }
        bytesData[10] = 'B'.charCodeAt(0)
        bytesData[11] = 'A'.charCodeAt(0)
        bytesData[12] = 'G'.charCodeAt(0)

        var bytes = unicodeToChar(bytesData)
        console.log('gskbytes', bytesData)
        return bytesData
    }

    const setBytesFunc = {
        ACE1: setPABytes_PCv1,
        ACE0: setPABytes_v0,
        HUMANOID0: setPABytes_zingV0,
        HUMANOID1: setPABytes_zingV1V2,
        HUMANOID2: setPABytes_zingV1V2,
        HEXAPOD0: setPABytes_zingV0,
        HEXAPOD1: setPABytes_zingV1V2,
        HEXAPOD2: setPABytes_zingV1V2,
        ROBOKI1: setPABytes_zingV1V2,
    }

    const recievedBytes_v = (bytesRecieved) => {
        console.log(
            'outputttt',
            bytesRecieved,
            MSBLSBToDecimal([bytesRecieved[7], bytesRecieved[6]])
        )
        if (bytesRecieved === undefined) return
        let sensorData
        console.log('bytes recieved', bytesRecieved)
        if (connectedDevice == 'Ace') {
            deviceVersion?.startsWith('1')
                ? (sensorData = {
                      A1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          2
                      ),
                      A2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          3
                      ),
                      B1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          4
                      ),
                      B2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          5
                      ),
                      C1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          6
                      ),
                      C2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          7
                      ),
                      D1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          8
                      ),
                      D2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          9
                      ),
                      E1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          10
                      ),
                      E2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          11
                      ),
                      F1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          12
                      ),
                      F2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          13
                      ),
                      isMic: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          14
                      ),
                      isTemperature: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          15
                      ),

                      isTouchZero: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          16
                      ),
                      isTouchOne: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          17
                      ),

                      isTouchTwo: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          19
                      ),
                      isTouchThree: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          18
                      ),

                      isColorSensorRed: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[0],

                      isColorSensorGreen: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[1],

                      isColorSensorBlue: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[2],

                      isGestureSensor: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20G'
                      ),
                      isDistanceSensors: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20D'
                      ),
                      isLightSensor: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20L'
                      ),

                      isAccelerometerX:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21X'
                              )
                          ) - 32768,
                      isAccelerometerY:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21Y'
                              )
                          ) - 32768,
                      isAccelerometerZ:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21Z'
                              )
                          ) - 32768,

                      isGyroX:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21GX'
                              )
                          ) - 2500,

                      isGyroY:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21GY'
                              )
                          ) - 2500,
                      isGyroZ:
                          Number(
                              playComputerPABytesToSensorData(
                                  bytesRecieved.slice(2),
                                  '21GZ'
                              )
                          ) - 2500,
                      isBattery: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          22
                      ),
                  })
                : (sensorData = {
                      isTouchZero: MSBLSBToDecimal([
                          bytesRecieved[3],
                          bytesRecieved[2],
                      ]),
                      isTouchOne: MSBLSBToDecimal([
                          bytesRecieved[7],
                          bytesRecieved[6],
                      ]),
                      isTouchTwo: MSBLSBToDecimal([
                          bytesRecieved[11],
                          bytesRecieved[10],
                      ]),
                      A1: MSBLSBToDecimal([bytesRecieved[3], bytesRecieved[2]]),
                      A2: MSBLSBToDecimal([bytesRecieved[5], bytesRecieved[4]]),
                      B1: MSBLSBToDecimal([bytesRecieved[7], bytesRecieved[6]]),
                      B2: MSBLSBToDecimal([bytesRecieved[9], bytesRecieved[8]]),
                      C1: MSBLSBToDecimal([
                          bytesRecieved[11],
                          bytesRecieved[10],
                      ]),
                      C2: MSBLSBToDecimal([
                          bytesRecieved[13],
                          bytesRecieved[12],
                      ]),
                      F1: bytesRecieved[14],
                      F2: bytesRecieved[15],
                      isColorSensorRed: bytesRecieved[16],
                      isColorSensorGreen: bytesRecieved[17],
                      isColorSensorBlue: bytesRecieved[18],
                      isLightSensor: MSBLSBToDecimal([
                          bytesRecieved[17],
                          bytesRecieved[16],
                      ]),
                      isDistanceSensors: bytesRecieved[17],
                      isGestureSensor: bytesRecieved[18],
                      isMic: MSBLSBToDecimal([
                          bytesRecieved[20],
                          bytesRecieved[19],
                      ]),
                      isTemperature: MSBLSBToDecimal([
                          bytesRecieved[22],
                          bytesRecieved[21],
                      ]),
                  })
        } else if (connectedDevice == 'Humanoid') {
            sensorData = {
                isBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                isAccelerometerX: humanoidPABytesToSensorData(
                    bytesRecieved,
                    23
                ),
                isAccelerometerY: humanoidPABytesToSensorData(
                    bytesRecieved,
                    24
                ),
                isAccelerometerZ: humanoidPABytesToSensorData(
                    bytesRecieved,
                    25
                ),
                isGyroX: humanoidPABytesToSensorData(bytesRecieved, 26),
                isGyroY: humanoidPABytesToSensorData(bytesRecieved, 27),
                isGyroZ: humanoidPABytesToSensorData(bytesRecieved, 28),
                isUltrasonic: humanoidPABytesToSensorData(bytesRecieved, 22),
                A1: humanoidPABytesToSensorData(bytesRecieved, 31),
                A2: humanoidPABytesToSensorData(bytesRecieved, 32),
                B1: humanoidPABytesToSensorData(bytesRecieved, 33),
                B2: humanoidPABytesToSensorData(bytesRecieved, 34),
                C1: humanoidPABytesToSensorData(bytesRecieved, 35),
                C2: humanoidPABytesToSensorData(bytesRecieved, 36),
                D1: humanoidPABytesToSensorData(bytesRecieved, 37),
                D2: humanoidPABytesToSensorData(bytesRecieved, 38),
            }
        } else {
            bytesRecieved.splice(0, 2)
            sensorData = {
                isSwitchOne: robokiPABytesToSensorData(bytesRecieved, 23),
                isSwitchTwo: robokiPABytesToSensorData(bytesRecieved, 24),
                A1: robokiPABytesToSensorData(bytesRecieved, 2),
                A2: robokiPABytesToSensorData(bytesRecieved, 3),
                B1: robokiPABytesToSensorData(bytesRecieved, 6),
                B2: robokiPABytesToSensorData(bytesRecieved, 7),
            }
        }
        setSensorData(sensorData)
    }

    const setSensorData = async (sensorData) => {
        const times = props.indexData.graphPlotStorage.time
        time =
            Math.floor(
                (time +
                    times.ms / 1000 +
                    times.s +
                    times.m * 60 +
                    times.h * 60 * 60) *
                    100
            ) / 100
        if (connectedDevice == 'Ace') {
            deviceVersion?.startsWith('1')
                ? datas.push({
                      mic: sensorData.isMic,
                      temp: sensorData.isTemperature,
                      batt: sensorData.isBattery,
                      touch0: sensorData.isTouchZero,
                      touch1: sensorData.isTouchOne,
                      touch2: sensorData.isTouchTwo,
                      touch3: sensorData.isTouchThree,
                      acceX: sensorData.isAccelerometerX,
                      acceY: sensorData.isAccelerometerY,
                      acceZ: sensorData.isAccelerometerZ,
                      gyroX: sensorData.isGyroX,
                      gyroY: sensorData.isGyroY,
                      gyroZ: sensorData.isGyroZ,
                      red: sensorData.isColorSensorRed,
                      green: sensorData.isColorSensorGreen,
                      blue: sensorData.isColorSensorBlue,
                      distance: sensorData.isDistanceSensors,
                      light: sensorData.isLightSensor,
                      gesture: sensorData.isGestureSensor,
                      A1: sensorData.A1,
                      A2: sensorData.A2,
                      B1: sensorData.B1,
                      B2: sensorData.B2,
                      C1: sensorData.C1,
                      C2: sensorData.C2,
                      D1: sensorData.D1,
                      D2: sensorData.D2,
                      E1: sensorData.E1,
                      E2: sensorData.E2,
                      F1: sensorData.F1,
                      F2: sensorData.F2,
                      pv: time,
                  })
                : datas.push({
                      mic: sensorData.isMic,
                      temp: sensorData.isTemperature,
                      touch0: sensorData.isTouchZero,
                      touch1: sensorData.isTouchOne,
                      touch2: sensorData.isTouchTwo,
                      red: sensorData.isColorSensorRed,
                      green: sensorData.isColorSensorGreen,
                      blue: sensorData.isColorSensorBlue,
                      distance: sensorData.isDistanceSensors,
                      light: sensorData.isLightSensor,
                      gesture: sensorData.isGestureSensor,
                      A1: sensorData.A1,
                      A2: sensorData.A2,
                      B1: sensorData.B1,
                      B2: sensorData.B2,
                      C1: sensorData.C1,
                      C2: sensorData.C2,
                      F1: sensorData.F1,
                      F2: sensorData.F2,
                      pv: time,
                  })
        } else if (connectedDevice == 'Roboki') {
            datas.push({
                A1: sensorData.A1,
                A2: sensorData.A2,
                B1: sensorData.B1,
                B2: sensorData.B2,
                switch1: sensorData.isSwitchOne,
                switch2: sensorData.isSwitchTwo,
                pv: time,
            })
        } else {
            datas.push({
                batt: sensorData.isBattery,
                acceX: sensorData.isAccelerometerX,
                acceY: sensorData.isAccelerometerY,
                acceZ: sensorData.isAccelerometerZ,
                gyroX: sensorData.isGyroX,
                gyroY: sensorData.isGyroY,
                gyroZ: sensorData.isGyroZ,
                ultrasonic: sensorData.isUltrasonic,
                A1: sensorData.A1,
                A2: sensorData.A2,
                B1: sensorData.B1,
                B2: sensorData.B2,
                C1: sensorData.C1,
                C2: sensorData.C2,
                D1: sensorData.D1,
                D2: sensorData.D2,
                pv: time,
            })
        }
        // console.log('RECIVEDDATA', dataQues)
        // console.log('gsk light', sensorData.isLightSensor)
        setDatas([...datas])
    }

    useEffect(() => {
        dataQues = datas.slice(datas.length - 10, datas.length)

        // currentData = isPauseBtn == true ? datas : dataQues

        // setCurrentActiveData(() => {
        //     return isPauseBtn ? dataQues : datas
        // })
        // console.log('CURRENTDATA', currentData)
        // console.log('DATASS', datas)
        console.log('dataqu', dataQues)
    }, [datas])

    useEffect(() => {
        sessionStorage.removeItem('readSetIntervalId')
    }, [])

    useEffect(() => {
        console.log('pausebtn', isPauseBtn)
        if (isPauseBtn && props.indexData.webSerial.isConnected) {
            start()
        } else {
            setPauseBtn(false)
            const intervalId = sessionStorage.getItem('readSetIntervalId')
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            stop()
        }
    }, [isPauseBtn, props.indexData.webSerial.isConnected])

    const handleReadByte = async (port) => {
        const time = props.indexData.graphPlotStorage.time
        const intervalId = sessionStorage.getItem('readSetIntervalId')

        if (intervalId !== null) {
            setTimeout(() => {
                sendBytes(['M'.charCodeAt(0), '8'.charCodeAt(0)], port)
            }, 500)

            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        let counter =
            time.ms +
            time.s * 1000 +
            time.m * 60 * 1000 +
            time.h * 60 * 60 * 1000

        const newIntervalId = setInterval(async () => {
            let read
            if (connectedDevice == 'Ace') {
                await sendBytes(setPABytes_v0(), port)
                read = await readBytesPa(port, false, 26)
            } else {
                if (
                    deviceVersion[0] === '1' &&
                    ['Humanoid'].includes(connectedDevice)
                ) {
                    await sendBytes(setPABytes_zingV1V2(), port)
                    // read = await readBytesPa(port, false, 36)
                    read = await startReadingBytes(
                        port,
                        50,
                        false,
                        recievedBytes_v,
                        'dataMood'
                    )
                } else {
                    await sendBytes(setPABytes_zingV0(), port)
                    read = await readBytesPa(port, false, 36)
                }
            }
            recievedBytes_v(read)
        }, counter)
        sessionStorage.setItem('readSetIntervalId', newIntervalId)
        // while (true) {
        //     await sendBytes(setPABytes_v0(), port)
        //     let read = await readBytesPa(port, false)
        //     recievedBytes_v(read)

        //     await new Promise((resolve) => {
        //         let newIntervalId = setTimeout(
        //             resolve,
        //             time.ms +
        //                 time.s * 1000 +
        //                 time.m * 60 * 1000 +
        //                 time.h * 60 * 60 * 1000
        //         )
        //         sessionStorage.setItem('readSetIntervalId', newIntervalId)
        //     })

        //     if (isPauseBtn) break
        // }
    }

    props.worker.onmessage = (e) => {
        if (
            ['PAread', 'PAreadZH', 'RobokiPAread', 'PAreadPCv0'].includes(
                e.data.type
            )
        ) {
            console.log('gsk read is ', e.data.value)
            recievedBytes_v(e.data.value)
        }
    }
    const handleReadByte_v1 = async (port) => {
        // console.log('props time gsk', port)
        const time = props.indexData.graphPlotStorage.time
        const intervalId = sessionStorage.getItem('readSetIntervalId')
        // const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
        if (intervalId !== null) {
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        // const newIntervalId = setInterval(() => {
        //     continousPABytesReadToggle_v1(
        //         setPABytes_V1(),
        //         56,
        //         port,
        //         recievedBytes_v
        //     )
        // }, time.ms + time.s * 1000 + time.m * 60 * 1000 + time.h * 60 * 60 * 1000)
        const deviceName = generateDeviceDataKey(connectedDevice, deviceVersion)
        console.log(
            connectedDevice.toUpperCase(),
            deviceName,
            setBytesFunc[deviceName]
        )
        let sendBytes = setBytesFunc[deviceName]()
        // if (
        //     deviceVersion?.includes('1.0.0') &&
        //     ['Ace'].includes(connectedDevice)
        // ) {
        //     sendBytes = setPABytes_PCv1()
        // } else {
        //     sendBytes = setPABytes_zingV1V2()
        // }
        console.log(sendBytes)
        let counter =
            time.ms +
            time.s * 1000 +
            time.m * 60 * 1000 +
            time.h * 60 * 60 * 1000
        const newIntervalId = setInterval(async () => {
            props.worker.postMessage({
                type: 'writeArray',
                value: sendBytes,
            })
        }, counter)

        sessionStorage.setItem('readSetIntervalId', newIntervalId)
    }
    // if (isPauseBtn == true) {
    //     endTime = 0
    // }

    const renderSensors = (sensorData, ind) => {
        let reduxName = graphSidebarData[sensorData].reduxName
        if (
            [
                'distancesensor',
                'gesturesensor',
                'colorsensorRed',
                'colorsensorBlue',
                'colorsensorGreen',
                'lightsensor',
            ].includes(sensorData)
        ) {
            if (!reduxInternalaccessories.Four_in_one_sensor[reduxName]) return
        } else if (!reduxInternalaccessories[reduxName]) {
            return
        }

        return (
            <div
                key={ind}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    style={{
                        height: '2vh',
                        width: '2vw',
                        background: graphSidebarData[sensorData].bgColour,
                    }}
                ></div>

                <div
                    style={{
                        fontSize: '2.5vh',
                    }}
                >
                    <p
                        style={{
                            transform: 'translateY(37%)',
                        }}
                    >
                        {graphSidebarData[sensorData].sensors}
                    </p>
                </div>
            </div>
        )
    }

    const renderPortData = (portData) => {
        // console.log("portdata",portData)
        const activePorts = portData.ports.filter((port) => port.isActive)
        // console.log('gsk port active', activePorts)
        return activePorts.map((port) => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    style={{
                        height: '2vh',
                        width: '2vw',
                        background: `${port.isColor}`,
                    }}
                ></div>

                <div style={{ fontSize: '2.5vh' }}>
                    <p
                        style={{
                            transform: 'translateY(37%)',
                        }}
                    >
                        {port.portName}
                    </p>
                </div>
            </div>
        ))
    }

    return (
        <>
            <div className={style.Main_Container}>
                <div id={style.Navbar}>
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Sensors',
                                'Select Ports',
                                'Set Time',
                                'Set Graph',
                                'Plot',
                            ],
                            selected: 4,
                        }}
                        showSave={false}
                        showHelp={true}
                        graph={datas}
                        // handleHelp={helpBtn}
                    />
                </div>
                <div id={style.Body}>
                    {' '}
                    <div className={stylee.Main_Container}>
                        {/* sidebar */}
                        {isArrowBtn === false ? (
                            <>
                                {' '}
                                <div
                                    id={stylee.sidebar}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'smallleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                        position: 'relative',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <div
                                        style={{
                                            transform: 'translateX(16%)',
                                        }}
                                    >
                                        {/* render the enable internal sensors in sidebar */}
                                        {Object.keys(graphSidebarData).map(
                                            (accessoriesKey, ind) =>
                                                renderSensors(
                                                    accessoriesKey,
                                                    ind
                                                )
                                        )}

                                        {/* render the enable ports in sidebar */}
                                        {props.indexData.graphPlotStorage.internalaccessories.portsData.map(
                                            (portData) => (
                                                <div className="port-group">
                                                    {renderPortData(portData)}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                {/* sidebar arrow button */}
                                <div
                                    id={stylee.arrowBtn}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'CloseGraphSideBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '40.5% 13%',
                                        position: 'relative',
                                        transform: 'translate(0,43%)',
                                    }}
                                    onClick={handleClick}
                                ></div>
                            </>
                        ) : (
                            <div
                                id={stylee.arrowBtn}
                                style={{
                                    backgroundImage: `url("${renderPrgImage(
                                        'CloseGraphSideBar'
                                    )}")`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '40.5% 13%',
                                    position: 'relative',
                                    transform: 'translate(-300%, 43%)',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClick}
                            ></div>
                        )}

                        {/* graph */}
                        <div
                            id={stylee.body}
                            style={
                                isArrowBtn === false
                                    ? { transform: 'translate(-9%, 11%)' }
                                    : { transform: 'translate(-16%, 11%)' }
                            }
                        >
                            {history.location.pathname == '/barGraph' ? (
                                <div
                                    style={{
                                        transform: 'translate(833%, 10%)',
                                        width: '11%',
                                        padding: '1% 2%',
                                        background:
                                            'linear-gradient(to right, #f0eded,#f4f3f3)',
                                        borderRadius: '25px',
                                        border: '1px solid white',
                                    }}
                                >
                                    <DisplayComponent timer={timer} />
                                </div>
                            ) : null}

                            {/* {LineGraphChart(
                                // datas,
                                lastElement,
                                currentActiveData,
                                time,
                                endTime,
                                startTime,
                                isPauseBtn,
                                isConnectedPort.opened
                            )} */}
                            <LineGraphChart
                                currentActiveData={datas}
                                time={time}
                                startTime={startTime}
                                isPauseBtn={isPauseBtn}
                                bar={
                                    history.location.pathname == '/barGraph'
                                        ? true
                                        : false
                                }
                            />
                        </div>

                        {/* bottom */}

                        <div id={stylee.bottom}>
                            <div className={stylee.sub_Bottom}>
                                {!isPauseBtn ? (
                                    <img
                                        className={stylee.sub_Bottom1}
                                        src={renderPrgImage('play')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                ) : (
                                    <img
                                        className={stylee.sub_Bottom2}
                                        src={renderPrgImage('pause')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div id={style.bottom}>
                    <div>
                        <img
                            style={{
                                position: 'absolute',
                                left: '7%',
                                top: '92%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('backBtn')}
                            onClick={backBtn}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        indexData: state,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LineGraph)
