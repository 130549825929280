const {
    _0PORT,
} = require('../../flowchart_port/FlowchartConnections/Myflowchart/PortTypes')

function scale(number, inMin, inMax, outMin, outMax) {
    return (
        ((Math.abs(number) - inMin) * (outMax - outMin)) / (inMax - inMin) +
        outMin
    )
}
function scaleServo(number, inMin, inMax, outMin, outMax) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

module.exports = {
    A: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
        ultrasonic_sensor: function (value) {
            return value
        },
    },
    A1: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    A2: {
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        servo_extender: function (value) {
            return value
        },

        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    I2: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    I1: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    I: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    H2: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    H1: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    H: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    G2: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    G1: {
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    G: {
        '4_in_1_sensor': function (value) {
            return value
        },
        ultrasonic_sensor: function (value) {
            return value
        },
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    F2: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    F1: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    F: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    E2: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    E1: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    E: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    D2: {
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        mini_geared_motor: function (value) {
            return value
        },
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
        geared_motor: function (value) {
            value = scale(value, 0, 10, 0, 100)
            return value
        },
    },
    D1: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },

        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    D: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    C2: {
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        mini_geared_motor: function (value) {
            return value
        },
        geared_motor: function (value) {
            return value
        },
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    MOTOR1: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
    },
    MOTOR2: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
    },
    M1: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
    },
    M2: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
    },
    M3: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
    },
    M4: {
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        relay: function (value) {
            return value
        },
        electromagnet: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
    },
    C1: {
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    C: {
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    B2: {
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        geared_motor: function (value) {
            return value
        },
        stepper_motor: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    B1: {
        stepper_motor: function (value) {
            return value
        },
        mp3: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },

        geared_motor: function (value) {
            return value
        },
        mini_geared_motor: function (value) {
            return value
        },
        dc_motor: function (value) {
            return value
        },
        dynamex_motor: function (value) {
            return value
        },

        servo_extender: function (value) {
            return value
        },

        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    B: {
        servo_extender: function (value) {
            return value
        },
        mp3: function (value) {
            return value
        },
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
        dual_splitter: function (value) {
            return value
        },
        led: function (value) {
            return value
        },
        led_1c: function (value) {
            return value
        },
        laser: function (value) {
            return value
        },
        beeper: function (value) {
            return value
        },
        light_sensor: function (value) {
            return value
        },
        bend_sensor: function (value) {
            return value
        },
        gas_sensor: function (value) {
            return value
        },
        distance_sensor: function (value) {
            return value
        },
        sound_sensor: function (value) {
            return value
        },
        temperature_sensor: function (value) {
            return value
        },
        rain_sensor: function (value) {
            return value
        },
        rotational_sensor: function (value) {
            return value
        },
        accelerometer: function (value) {
            return value
        },
        solar_panel: function (value) {
            return value
        },
        humidity_sensor: function (value) {
            return value
        },
        hall_sensor: function (value) {
            return value
        },
        metal_detector: function (value) {
            return value
        },
        heartbeat_sensor: function (value) {
            return value
        },
        tact_switch: function (value) {
            return value
        },
        dual_switch: function (value) {
            return value
        },
        touch_sensor: function (value) {
            return value
        },
        pir_sensor: function (value) {
            return value
        },
    },
    P0: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    P1: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    P2: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    servo_motor1: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    servo_motor2: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_270: function (value) {
            return Math.round(0.667 * value)
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    O1: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    O2: {
        servo_motor: function (value) {
            return value
        },
        servo_motor_360: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    PW1: {
        PWM: function (value) {
            return value
        },
    },
    PW2: {
        PWM: function (value) {
            return value
        },
    },
    PW3: {
        PWM: function (value) {
            return value
        },
    },
    PW4: {
        PWM: function (value) {
            return value
        },
    },
    PW5: {
        PWM: function (value) {
            return value
        },
    },
    PW6: {
        PWM: function (value) {
            return value
        },
    },
    serial_servo1: {
        serial_servo_motor: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
    serial_servo2: {
        serial_servo_motor: function (value) {
            value = scaleServo(value, -100, 100, 0, 200)
            return value
        },
    },
}
