import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import MuscStyle from './MuscSlider.module.css'
import renderImage from '../../../source/importImg'
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

export class MuscSlider extends Component {
    render() {
        let isPCv1 = sessionStorage.getItem('deviceVersion')
        let connectedDevice = ['Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )
            ? true
            : false
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }
        return (
            <div>
                <div
                    className="container"
                    style={{
                        height: '100vh',
                        background: 'white',
                        left: '19.5%',
                    }}
                >
                    <div
                        className="Ss_sliderPlay"
                        style={{
                            background: '#fff',
                            width: '100vw',
                            height: '100vh',
                            position: 'relative',
                        }}
                    >
                        <Slider
                            {...settings}
                            style={{
                                top: '46%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <div>
                                <div>
                                    <img
                                        className={MuscStyle.img}
                                        src={
                                            isPCv1?.startsWith('1')
                                                ? renderImage('MusicH1')
                                                : renderImage('Musc1')
                                        }
                                    />
                                </div>
                                <div
                                    className={MuscStyle.Hel}
                                    style={
                                        connectedDevice
                                            ? {
                                                  right: '52%',
                                              }
                                            : { right: '70.2%' }
                                    }
                                >
                                    <p className={MuscStyle.Hel_P}>
                                        Help Button will explain all the
                                        features and functionality of the entire
                                        app for each screen
                                    </p>
                                </div>

                                <div
                                    className={MuscStyle.Rem}
                                    style={
                                        connectedDevice
                                            ? {
                                                  right: '51.1%',
                                              }
                                            : { right: '69.9%' }
                                    }
                                >
                                    <p className={MuscStyle.Rem_P}>
                                        When the app is connected to the Play
                                        Computer, the icon will be GREEN in
                                        colour, & when disconnected, the icon
                                        will turn RED. If the app gets
                                        disconnected from the Play computer,
                                        then on top of this button, it’ll
                                        attempt to connect automatically.
                                    </p>
                                </div>
                            </div>

                            {connectedDevice ? (
                                <div>
                                    <div>
                                        <img
                                            className={MuscStyle.img}
                                            src={
                                                isPCv1?.startsWith('1')
                                                    ? renderImage('MusicH2')
                                                    : renderImage('Musc2')
                                            }
                                        />
                                    </div>
                                    <div
                                        className={MuscStyle.con}
                                        style={
                                            connectedDevice
                                                ? {
                                                      right: '36.5%',
                                                  }
                                                : { right: '60.7%' }
                                        }
                                    >
                                        <p className={MuscStyle.con_P}>
                                            User needs to press the keys on the
                                            piano to make the zing play the
                                            respective action.
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        <img
                                            className={MuscStyle.img}
                                            src={
                                                isPCv1?.startsWith('1')
                                                    ? renderImage('MusicH2')
                                                    : renderImage('Musc2')
                                            }
                                        />
                                    </div>
                                    <div
                                        className={MuscStyle.con}
                                        style={
                                            connectedDevice
                                                ? {
                                                      right: '36.5%',
                                                  }
                                                : { right: '60.7%' }
                                        }
                                    >
                                        <p className={MuscStyle.con_P}>
                                            In Playcomputer tone mode, user
                                            needs to press the keys on the piano
                                            to make the playcomputer play the
                                            respective tones.
                                        </p>
                                    </div>
                                </div>
                            )}

                            {!connectedDevice && (
                                <div>
                                    <div>
                                        <img
                                            className={MuscStyle.img}
                                            src={
                                                isPCv1?.startsWith('1')
                                                    ? renderImage('MusicH3')
                                                    : renderImage('Musc3')
                                            }
                                        />
                                    </div>
                                    <div className={MuscStyle.Mus}>
                                        <p className={MuscStyle.Mus_P}>
                                            Toggle switch to switch between
                                            Playcomputer playing tones or app
                                            playing tones.
                                        </p>
                                    </div>
                                </div>
                            )}

                            {!connectedDevice && (
                                <div>
                                    <div>
                                        <img
                                            className={MuscStyle.img}
                                            src={
                                                isPCv1?.startsWith('1')
                                                    ? renderImage('MusicH4')
                                                    : renderImage('Musc4')
                                            }
                                        />
                                    </div>
                                    <div className={MuscStyle.Cam}>
                                        <p className={MuscStyle.Cam_P}>
                                            • In app tone mode, the user touches
                                            the playcomputer’s touch pads & the
                                            app will play the corresponding
                                            tones.
                                            <br />• The user needs to touch the
                                            3 touch pads on the playcomputer’s
                                            edge connector.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default MuscSlider
