import React, { useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import NavBar from '../NavBar'
import { useHistory } from 'react-router'
import * as cocoSsd from '@tensorflow-models/coco-ssd'
import selectBarfive from '../../../Assets/Bisoft_UI/AppMode/selectBarfive.png'
import pause from '../../../Assets/Bisoft_UI/Buttons/Active with shadow/pause_btn@3x.png'
import backY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import play from '../../../Assets/Bisoft_UI/Buttons/Active with shadow/play-run@2x.png'
import * as faceapi from '@vladmandic/face-api/dist/face-api.esm-nobundle.js'
import cameraP5 from '../../../Assets/Bisoft_UI/AppMode/camera(speech).png'
import popupCardfive from '../../../Assets/Bisoft_UI/AppMode/popupCardfive.png'
import popupCardone from '../../../Assets/Bisoft_UI/AppMode/popupCardone.png'
import projectbig from '../../../Assets/Bisoft_UI/AppMode/projectBig.png'
import musicIcon from '../../../Assets/Bisoft_UI/AppMode/musicIcon.png'
import close from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import styles from '../Appmode.module.css'
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition'
import {
    readBytesAppMode,
    sendBytes,
} from '../../ReusableComponents/ByteTransfer/byteTransfer'
import { connect } from 'react-redux'
import closeBtn from '../../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../../source/programImg'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

let optionsSSDMobileNet
const maxResults = 5
let videoLoaded = false
let send = false
let cocossdModel = null

const ObjectDetection = (props) => {
    const enable = JSON.parse(sessionStorage.getItem('enable'))
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const history = useHistory()
    const videoHeight = 360
    const videoWidth = 400
    const allDetails = JSON.parse(sessionStorage.getItem('allDetails'))
    const videoRef = React.useRef()
    const canvasRef = React.useRef()
    const readBytesInterval = React.useRef()
    const { getByID } = useIndexedDB('ProgramData')
    // const { getAll } = useIndexedDB("SavedFaces");
    // const [imageDetails, setImageDetails] = useState("");
    const [isPlay, setPlay] = useState(false)
    const [display, setDisplay] = useState(true)
    const [image, setImage] = useState(popupCardfive)
    const [popup, setPopup] = useState(false)
    const [text, setText] = useState('value')
    const [size, setSize] = useState(0)
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [detected, setDetected] = useState()
    const [results, setResults] = useState()
    const [stateload, setStateload] = useState(false)
    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    // let faceMatcher;
    const { interimTranscript } = useSpeechRecognition()
    const startListening = () =>
        SpeechRecognition.startListening({ continuous: true })

    useEffect(() => {
        if (
            // enable.s2.face === true ||
            enable.s2.emotion === true
        ) {
            const loadModels = async () => {
                const MODEL_URL = process.env.PUBLIC_URL + '/models'

                Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                    faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                    faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                    faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
                    faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
                    (optionsSSDMobileNet = new faceapi.SsdMobilenetv1Options({
                        minConfidence: 0.2,
                        maxResults,
                    })),
                ]).then((value) => {
                    setStateload(true)
                })
            }
            loadModels()
        }
    }, [])

    useEffect(() => {
        if (enable.s2.object === true) {
            const load = async () => {
                await cocoSsd
                    .load()
                    .then((model) => {
                        cocossdModel = model
                    })
                    .then((value) => {
                        setStateload(true)
                    })
            }
            load()
        }
    }, [])

    // useEffect(() => {
    //   getAll().then((result) => {
    //     setImageDetails(result);
    //     setDataLoaded(true);
    //   });
    // }, []);

    useEffect(() => {
        if (enable.s1.mic === true && isPlay === true) {
            setTimeout(() => {
                setResults('listening...')
            }, 1000)
        }
    }, [results, isPlay])

    useEffect(() => {
        if (isPlay === true) {
            var videodata = document.getElementById('video')
            videodata.addEventListener('loadeddata', () => {
                videoLoaded = true
            })
        }
        if (
            enable.s1.pc === true &&
            isPlay === true &&
            !(deviceVersion?.startsWith('1') && connectedDevice == 'Ace') &&
            !(
                ['Humanoid', 'Hexapod'].includes(connectedDevice) &&
                deviceVersion?.startsWith('2')
            ) &&
            connectedDevice !== 'Roboki'
        ) {
            readBytesInterval.current = setInterval(async () => {
                let bytes
                if (send === true) {
                    bytes = await readBytesAppMode(props.webSerial.port, '')
                    send = false
                    if (bytes !== undefined && bytes.split('\n').length >= 2) {
                        let length = bytes.split('\n').length
                        bytes = bytes.split('\n')[length - 1]
                    }
                    if (
                        bytes !== undefined &&
                        2 < bytes.length <= 5 &&
                        bytes.slice(0, 2) === 'US'
                    ) {
                        Object.keys(allDetails).map((value) => {
                            if (allDetails[value].usbValue === bytes.slice(2)) {
                                if (allDetails[value].displayText !== null) {
                                    setText(allDetails[value].displayText)
                                }
                                if (allDetails[value].image !== null) {
                                    getByID(allDetails[value].id).then(
                                        (event) => {
                                            setImage(event.imageURL)
                                        }
                                    )
                                }
                                if (allDetails[value].sliderOption === 'TX1') {
                                    sendBytes(
                                        [
                                            85,
                                            83,
                                            Number(
                                                allDetails[value].sliderValue
                                            ),
                                        ],
                                        props.webSerial.port
                                    )
                                    send = true
                                }
                                setSize(allDetails[value].selectedSize)
                            }
                        })
                    }
                }
            }, 5000)
        }

        return () => {
            props.worker.onmessage = (e) => {
                if (e.data.type === 'Appread') {
                    if (isPlay) return
                    console.log('gsk value', e.data.value)
                    let bytes = e.data.value
                    Object.keys(allDetails).map((value) => {
                        if (allDetails[value].usbValue == bytes[3]) {
                            if (allDetails[value].displayText !== null) {
                                setText(allDetails[value].displayText)
                            }
                            if (allDetails[value].image !== null) {
                                getByID(allDetails[value].id).then((event) => {
                                    setImage(event.imageURL)
                                })
                            }
                            if (allDetails[value].sliderOption === 'TX1') {
                                props.worker.postMessage({
                                    type: 'writeArray',
                                    value: [
                                        85,
                                        83,
                                        Number(allDetails[value].sliderValue),
                                    ],
                                })
                                send = true
                            }
                            setSize(allDetails[value].selectedSize)
                        }
                    })
                }
            }
        }
    }, [isPlay])

    useEffect(() => {
        if (interimTranscript !== '') {
            setResults(interimTranscript)
            Object.entries(allDetails).map((i) => {
                if (i[1].speechText !== null && i[1].speechText !== '') {
                    if (
                        i[1].speechText.toLowerCase() ===
                        interimTranscript.trim().toLowerCase()
                    ) {
                        if (allDetails[i[1].id].displayText !== null) {
                            setText(allDetails[i[1].id].displayText)
                        }
                        if (allDetails[i[1].id].image !== null) {
                            getByID(allDetails[i[1].id].id).then((event) => {
                                setImage(event.imageURL)
                            })
                        }
                        if (
                            enable.s1.pc === true &&
                            allDetails[i[1].id].sliderOption === 'TX1'
                        ) {
                            if (
                                deviceVersion?.startsWith('2') ||
                                (deviceVersion?.startsWith('1') &&
                                    ['Ace'].includes(connectedDevice)) ||
                                connectedDevice == 'Roboki'
                            ) {
                                props.worker.postMessage({
                                    type: 'writeArray',
                                    value: [
                                        85,
                                        83,
                                        Number(allDetails[i[1].id].sliderValue),
                                    ],
                                })
                            } else {
                                sendBytes(
                                    [
                                        85,
                                        83,
                                        Number(allDetails[i[1].id].sliderValue),
                                    ],
                                    props.webSerial.port
                                )
                            }
                            send = true
                        }
                        setSize(allDetails[i[1].id].selectedSize)
                    }
                }
                return null
            })
        }
        //  else {
        //     setImage(popupCardfive)
        //     setText('value')
        // }
    }, [interimTranscript])

    const Popup = (props) => {
        return props.trigger ? (
            <div className={styles.popup}>
                <div className={styles.popupInner}>
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            bottom: '70vh',
                            right: '-1.2vw',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setPopup(false)
                            props.set(true)
                        }}
                    />
                </div>
                {props.displayImage !== popupCardfive ? (
                    <div style={{ zIndex: '3' }}>
                        <img
                            style={{ width: '100%', maxWidth: '60vw' }}
                            src={props.displayImage}
                            height="350vh"
                            width="350vw"
                            alt="displayImage"
                            draggable="false"
                        />
                        <p
                            style={{
                                fontSize: `${2 * Number(props.font)}vh`,
                                zIndex: '4',
                                textAlign: 'center',
                            }}
                        >
                            {props.displayText}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </div>
        ) : (
            ''
        )
    }

    const handleVideoOnPlay = () => {
        setInterval(async () => {
            if (
                videoRef.current !== null &&
                // dataLoaded === true &&
                videoLoaded === true
            ) {
                if (
                    // enable.s2.face === true ||
                    enable.s2.emotion === true
                ) {
                    // canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
                    //   videoRef.current
                    // );

                    const displaySize = {
                        width: videoWidth,
                        height: videoHeight,
                    }
                    // faceapi.matchDimensions(canvasRef.current, displaySize);
                    const detections = await faceapi
                        .detectAllFaces(videoRef.current, optionsSSDMobileNet)
                        // .withFaceLandmarks()
                        .withFaceExpressions()
                    // .withFaceDescriptors();
                    const resizedDetections = faceapi.resizeResults(
                        detections,
                        displaySize
                    )
                    // canvasRef &&
                    //   canvasRef.current &&
                    //   canvasRef.current
                    //     .getContext("2d")
                    //     .clearRect(0, 0, videoWidth, videoHeight);
                    // canvasRef &&
                    //   canvasRef.current &&
                    //   faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
                    // canvasRef &&
                    //   canvasRef.current &&
                    //   faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);

                    // if (enable.s2.emotion === true) {
                    //   canvasRef &&
                    //     canvasRef.current &&
                    //     faceapi.draw.drawFaceExpressions(
                    //       canvasRef.current,
                    //       resizedDetections
                    //     );
                    // }
                    // if (enable.s2.face === true) {
                    //   const labeledFaceDescriptors = await loadLabledImages();
                    //   faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6);
                    //   const results = resizedDetections.map((d) =>
                    //     faceMatcher.findBestMatch(d.descriptor)
                    //   );
                    //   results.forEach((result, i) => {
                    //     // const box = resizedDetections[i].detection.box;
                    //     // const drawBox = new faceapi.draw.DrawBox(box, {
                    //     //   label: result.toString(),
                    //     // });
                    //     // drawBox.draw(canvasRef.current);
                    //     Object.keys(allDetails).map((key) => {
                    //       if (
                    //         allDetails[key].selectedObject ===
                    //         result.toString().split(" ")[0]
                    //       ) {
                    //         setDetected(result.toString().split(" ")[0].toUpperCase());
                    //         if (allDetails[key].displayText !== null) {
                    //           setText(allDetails[key].displayText);
                    //         }
                    //         if (allDetails[key].image !== null) {
                    //           getByID(allDetails[key].id).then((event) => {
                    //             setImage(event.imageURL);
                    //           });
                    //         }
                    //         if (
                    //           enable.s1.pc === true &&
                    //           allDetails[key].sliderOption === "TX1"
                    //         ) {
                    //           sendBytes(
                    //             [85, 83, Number(allDetails[key].sliderValue)],
                    //             props.webSerial.port
                    //           );
                    //           send = true;
                    //         }
                    //         setSize(allDetails[key].selectedSize);
                    //       } else {
                    //         setDetected("");
                    //         setImage(popupCardfive);
                    //         setText("value");
                    //       }
                    //       return "";
                    //     });
                    //   });
                    // } else
                    if (enable.s2.emotion === true) {
                        if (resizedDetections.length !== 0) {
                            let expression = resizedDetections[0].expressions
                            const getKeyByValue = (expression, value) => {
                                return Object.keys(expression).find(
                                    (key) => expression[key] === value
                                )
                            }
                            var arr = Object.keys(expression).map(function (
                                key
                            ) {
                                return expression[key]
                            })
                            var max = Math.max.apply(null, arr)
                            if (max > 0.6) {
                                let detectedexpression = getKeyByValue(
                                    expression,
                                    max
                                )
                                Object.keys(allDetails).map((key) => {
                                    if (
                                        allDetails[key].selectedObject !==
                                        (null && 'none')
                                    ) {
                                        if (
                                            allDetails[
                                                key
                                            ].selectedObject.toLowerCase() ===
                                            detectedexpression
                                        ) {
                                            setDetected(
                                                detectedexpression.toUpperCase() +
                                                    ' is detected'
                                            )
                                            if (
                                                allDetails[key].displayText !==
                                                null
                                            ) {
                                                setText(
                                                    allDetails[key].displayText
                                                )
                                            }
                                            if (
                                                allDetails[key].image !== null
                                            ) {
                                                getByID(
                                                    allDetails[key].id
                                                ).then((event) => {
                                                    setImage(event.imageURL)
                                                })
                                            }
                                            if (
                                                enable.s1.pc === true &&
                                                allDetails[key].sliderOption ===
                                                    'TX1'
                                            ) {
                                                try {
                                                    if (
                                                        deviceVersion?.includes(
                                                            '2.0.0'
                                                        ) ||
                                                        (deviceVersion?.includes(
                                                            '1.0.0'
                                                        ) &&
                                                            ['Ace'].includes(
                                                                connectedDevice
                                                            )) |
                                                            (connectedDevice ==
                                                                'Roboki')
                                                    ) {
                                                        props.worker.postMessage(
                                                            {
                                                                type: 'writeArray',
                                                                value: [
                                                                    85,
                                                                    83,
                                                                    Number(
                                                                        allDetails[
                                                                            key
                                                                        ]
                                                                            .sliderValue
                                                                    ),
                                                                ],
                                                            }
                                                        )
                                                    } else {
                                                        sendBytes(
                                                            [
                                                                85,
                                                                83,
                                                                Number(
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .sliderValue
                                                                ),
                                                            ],
                                                            props.webSerial.port
                                                        )
                                                    }
                                                    send = true
                                                } catch {
                                                    console.log('Hi')
                                                }
                                            }
                                            setSize(
                                                allDetails[key].selectedSize
                                            )
                                        }
                                    }
                                    return ''
                                })
                            }
                        }
                        //  else {
                        //     setDetected()
                        //     setImage(popupCardfive)
                        //     setText('value')
                        // }
                    }
                } else {
                    if (enable.s2.object === true) {
                        if (cocossdModel) {
                            cocossdModel
                                .detect(videoRef.current)
                                .then((results) => {
                                    if (results.length !== 0) {
                                        //     setDetected()
                                        //     setText('value')
                                        //     setImage(popupCardfive)
                                        // } else {
                                        results.find((result) => {
                                            if (result.score > 0.6) {
                                                Object.keys(allDetails).map(
                                                    (key) => {
                                                        if (
                                                            allDetails[key]
                                                                .selectedObject !==
                                                            (null && 'none')
                                                        ) {
                                                            if (
                                                                allDetails[key]
                                                                    .selectedObject ===
                                                                result.class
                                                            ) {
                                                                setDetected(
                                                                    result.class.toUpperCase() +
                                                                        ' is detected'
                                                                )
                                                                if (
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .displayText !==
                                                                    null
                                                                ) {
                                                                    setText(
                                                                        allDetails[
                                                                            key
                                                                        ]
                                                                            .displayText
                                                                    )
                                                                }
                                                                if (
                                                                    allDetails[
                                                                        key
                                                                    ].image !==
                                                                    null
                                                                ) {
                                                                    getByID(
                                                                        allDetails[
                                                                            key
                                                                        ].id
                                                                    ).then(
                                                                        (
                                                                            event
                                                                        ) => {
                                                                            setImage(
                                                                                event.imageURL
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                                if (
                                                                    enable.s1
                                                                        .pc ===
                                                                        true &&
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .sliderOption ===
                                                                        'TX1'
                                                                ) {
                                                                    if (
                                                                        deviceVersion?.startsWith(
                                                                            '2'
                                                                        ) ||
                                                                        (deviceVersion?.startsWith(
                                                                            '1'
                                                                        ) &&
                                                                            [
                                                                                'Ace',
                                                                            ].includes(
                                                                                connectedDevice
                                                                            )) ||
                                                                        connectedDevice ==
                                                                            'Roboki'
                                                                    ) {
                                                                        props.worker.postMessage(
                                                                            {
                                                                                type: 'writeArray',
                                                                                value: [
                                                                                    85,
                                                                                    83,
                                                                                    Number(
                                                                                        allDetails[
                                                                                            key
                                                                                        ]
                                                                                            .sliderValue
                                                                                    ),
                                                                                ],
                                                                            }
                                                                        )
                                                                    } else {
                                                                        sendBytes(
                                                                            [
                                                                                85,
                                                                                83,
                                                                                Number(
                                                                                    allDetails[
                                                                                        key
                                                                                    ]
                                                                                        .sliderValue
                                                                                ),
                                                                            ],
                                                                            props
                                                                                .webSerial
                                                                                .port
                                                                        )
                                                                    }
                                                                    send = true
                                                                }
                                                                setSize(
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .selectedSize
                                                                )
                                                            } else {
                                                                setDetected(
                                                                    result.class.toUpperCase() +
                                                                        ' not detected'
                                                                )
                                                                if (
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .displayText !==
                                                                    null
                                                                ) {
                                                                    setText('')
                                                                }
                                                                if (
                                                                    allDetails[
                                                                        key
                                                                    ].image !==
                                                                    null
                                                                ) {
                                                                    getByID(
                                                                        allDetails[
                                                                            key
                                                                        ].id
                                                                    ).then(
                                                                        (
                                                                            event
                                                                        ) => {
                                                                            setImage(
                                                                                popupCardfive
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                                if (
                                                                    enable.s1
                                                                        .pc ===
                                                                        true &&
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .sliderOption ===
                                                                        'TX1'
                                                                ) {
                                                                    if (
                                                                        deviceVersion?.startsWith(
                                                                            '2'
                                                                        ) ||
                                                                        (deviceVersion?.startsWith(
                                                                            '1'
                                                                        ) &&
                                                                            [
                                                                                'Ace',
                                                                            ].includes(
                                                                                connectedDevice
                                                                            )) ||
                                                                        connectedDevice ==
                                                                            'Roboki'
                                                                    ) {
                                                                        props.worker.postMessage(
                                                                            {
                                                                                type: 'writeArray',
                                                                                value: [
                                                                                    85,
                                                                                    83,
                                                                                    0,
                                                                                ],
                                                                            }
                                                                        )
                                                                    } else {
                                                                        sendBytes(
                                                                            [
                                                                                85,
                                                                                83,
                                                                                0,
                                                                            ],
                                                                            props
                                                                                .webSerial
                                                                                .port
                                                                        )
                                                                    }
                                                                    send = true
                                                                }
                                                                setSize(
                                                                    allDetails[
                                                                        key
                                                                    ]
                                                                        .selectedSize
                                                                )
                                                            }
                                                        }
                                                        return ''
                                                    }
                                                )
                                            }
                                            return ''
                                        })
                                    }
                                })
                        }
                    }
                }
            }
        }, 1000)
    }

    // const loadLabledImages = () => {
    //   const labels = [];
    //   Object.entries(allDetails).map((name) => {
    //     if (name[1].selectedObject !== null) {
    //       labels.push(name[1].selectedObject);
    //     }
    //     return "";
    //   });
    //   return Promise.all(
    //     labels.map(async (label) => {
    //       const descriptions = [];
    //       const imgURL = imageDetails.find(
    //         (value) => value.id === label
    //       ).imageUrl;
    //       for (let i = 1; i < 2; i++) {
    //         const img = await faceapi.fetchImage(imgURL);
    //         const detections = await faceapi
    //           .detectSingleFace(img)
    //           .withFaceLandmarks()
    //           .withFaceDescriptor();
    //         descriptions.push(detections.descriptor);
    //       }

    //       return new faceapi.LabeledFaceDescriptors(label, descriptions);
    //     })
    //   );
    // };

    const enableCam = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: false, video: { width: 300 } })
            .then((stream) => {
                let video = videoRef.current
                video.srcObject = stream
                video.play()
                if (enable.s1.mic === true) {
                    startListening()
                }
            })
            .catch((err) => {
                console.error('error:', err)
            })
    }

    const disableCam = () => {
        videoLoaded = false
        videoRef.current.pause()
        videoRef.current.srcObject.getTracks()[0].stop()
        if (enable.s1.mic === true) {
            SpeechRecognition.stopListening()
        }
        setTimeout(() => {
            setText('value')
            setDetected('')
            setImage(popupCardfive)
        }, 1500)
    }

    const onStart = () => {
        if (enable.s1.pc !== true) {
            setPlay(true)
            enableCam()
        } else {
            if (props.webSerial.port !== undefined) {
                setPlay(true)
                enableCam()
            } else {
                window.alert('Please connect your device')
            }
        }
    }
    return (
        <div>
            {/* NavBar */}
            <NavBar
                selectbar={selectBarfive}
                selectbarwidth="90%"
                headers={[
                    { name: 'Enable', color: 'white' },
                    { name: 'Control', color: 'white' },
                    { name: 'Run', color: 'white' },
                ]}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
            >
                <div style={{ position: 'relative' }}>
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '39vh',
                            left: '18vw',
                            width: '23.5vw',
                            height: '62vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={cameraP5}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '39vh',
                            left: '18vw',
                            width: '6vw',
                            // height: "12vh",
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '50.5vh',
                            left: '51.2vw',
                            width: '26.8vw',
                            height: '39vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={popupCardone}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '50.3vw',
                            width: '34vw',
                            height: '33vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={musicIcon}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '45.3vw',
                            width: '7vw',
                            // height: "10vh",
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={projectbig}
                        draggable="false"
                        alt="projectbig"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '55.8vh',
                            left: '51.4vw',
                            width: '24vw',
                            height: '23vh',
                            borderWidth: 2,
                            borderColor: 'black',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '22vh',
                            right: '25vw',
                        }}
                    >
                        <label>
                            <select
                                className={styles.selectBox}
                                style={{
                                    position: 'absolute',
                                    right: '14vw',
                                    top: '16vh',
                                    width: '19.4vw',
                                    height: '6.2vh',
                                    font: 'Halcyon Regular',
                                    fontFamily: 'Halcyon Regular',
                                    fontSize: '15px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <option>Text </option>
                            </select>
                        </label>
                    </div>
                    {/* Element to Move Dynamically */}
                    <h2
                        style={{
                            position: 'relative',
                            top: '53vh',
                            left: '2vh',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '15px',
                            textAlign: 'center',
                        }}
                    >
                        <strong>{text}</strong>
                    </h2>
                    {display === true && image !== null ? (
                        <img
                            src={image}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '39vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setDisplay(false)
                                setPopup(true)
                            }}
                        />
                    ) : (
                        <img
                            src={popupCardfive}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '39vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    <Popup
                        trigger={popup}
                        displayImage={image}
                        displayText={text}
                        font={size}
                        set={setDisplay}
                    />
                    {isPlay === true ? (
                        <video
                            id="video"
                            style={{
                                position: 'absolute',
                                zIndex: '2',
                                left: '18.1%',
                                top: '41vh',
                                transform: 'translate(-50%,-50%)',
                                borderRadius: '10px',
                            }}
                            ref={videoRef}
                            onPlay={handleVideoOnPlay}
                            width="20%"
                        />
                    ) : (
                        <p
                            style={{
                                position: 'absolute',
                                left: '18%',
                                top: '45vh',
                                transform: 'translate(-50%,0%)',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                            }}
                        >
                            Camera Inactive
                        </p>
                    )}
                    <p
                        style={{
                            position: 'relative',
                            bottom: '-60vh',
                            right: '-3.5vw',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '15px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            wordWrap: 'break-word',
                        }}
                    >
                        <strong>{detected}</strong>
                    </p>
                    <canvas
                        ref={canvasRef}
                        style={{
                            zIndex: '2',
                            position: 'absolute',
                            marginLeft: '4vw',
                            marginTop: '18vh',
                            borderRadius: '10px',
                        }}
                    />
                </div>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        if (isPlay) {
                            setPlay(false)
                            disableCam()
                            if (enable.s1.pc === true) {
                                clearInterval(readBytesInterval.current)
                            }
                            history.push('/programmingPage')
                        } else {
                            history.push('/programmingPage')
                        }
                    }}
                />
                {stateload === true ? (
                    isPlay === false ? (
                        <img
                            id="image"
                            src={play}
                            draggable="false"
                            alt="play"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '93vh',
                                left: '95.8vw',
                                width: '4vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                onStart()
                            }}
                        />
                    ) : (
                        <>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '10%',
                                    font: 'Halcyon Regular',
                                    fontFamily: 'Halcyon Regular',
                                    fontSize: '18px',
                                    left: '52%',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                {results}
                            </p>
                            <img
                                src={pause}
                                draggable="false"
                                alt="pause"
                                style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '93vh',
                                    left: '95.8vw',
                                    width: '4vw',
                                    cursor: 'pointer',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                onClick={() => {
                                    setPlay(false)
                                    disableCam()
                                    if (enable.s1.pc === true) {
                                        clearInterval(readBytesInterval.current)
                                    }
                                }}
                            />
                        </>
                    )
                ) : (
                    <p
                        style={{
                            position: 'absolute',
                            bottom: '8%',
                            marginLeft: '38.5%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '18px',
                            textAlign: 'center',
                        }}
                    >
                        Please wait while we are loading the assets...
                    </p>
                )}
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '18.4%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Camera scene is the output window of what
                                    the Smartphone's camera will see & detect
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH1')}
                                style={{ width: '100%' }}
                            />
                            {/* <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '41.5%',
                                    left: '23.5%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div> */}
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH2')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '60.5%',
                                    left: '30.7%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH3')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '55%',
                                    left: '39.2%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    The image/video output space, shows the
                                    images if any of the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH4')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '47.6%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to run the app mode.
                                    The respective action will be executed, when
                                    the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH5')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '54.7%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to stop the app mode
                                    code execution
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    // console.log("mapStateToProps", state);

    return state
}
export default connect(mapStateToProps)(ObjectDetection)
