import { useState, useRef, useEffect } from 'react'
import renderPrgImage from '../../../../../source/programImg'
import { style } from './ports.module.css'
import styles from './ports.module.css'
import Toggle from './Toggle'
import { portsData } from '../../GraphPlotStorage'
import { connect } from 'react-redux'
import { GraphPlotStorage } from '../../GraphPlotStorage'
import { generateDeviceDataKey } from '../../../../ReusableComponents/ByteTransfer/utils'

function Ports(props) {
    const [data, setData] = useState(
        props.indexData.graphPlotStorage.internalaccessories.portsData
    )
    const [disabledMsg, setDisabledMsg] = useState(false)
    useEffect(() => {
        if (!disabledMsg) return

        const timeout = setTimeout(() => {
            setDisabledMsg(false)
        }, 1500)
        return () => {
            clearTimeout(timeout)
        }
    }, [disabledMsg])

    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    // const zincVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
    const deviceName = generateDeviceDataKey(connectedDevice, deviceVersion)
    let disabledPortsPermanent = {
        ACE0: ['E1', 'E2', 'D1', 'D2'],
        ACE1: ['D1', 'D2'],
        HUMANOID0: [],
        HUMANOID1: ['D1', 'D2', 'B1', 'B2'],
        HUMANOID2: ['D1', 'D2', 'B1', 'B2'],
        HEXAPOD1: ['D1', 'D2', 'B1', 'B2'],
        HEXAPOD2: ['D1', 'D2', 'B1', 'B2'],
        ROBOKI1: [],
    }

    const handleImage = (portName, isActive) => {
        let newData = [...data]
        let disabledPortsConditional = {
            A1: 'isTouchZero',
            B1: 'isTouchOne',
            C1: 'isTouchTwo',
            A2: 'isTouchZero',
            B2: 'isTouchOne',
            C2: 'isTouchTwo',
        }

        // if (portName == 'D1' || portName == 'D2') {
        //     if(connectedDevice=="Humanoid" && zingVe)
        //     setDisabledMsg(true)
        //     return
        // }

        if (connectedDevice == 'Ace') {
            Object.values(newData).forEach((value, index) => {
                if (!disabledPortsPermanent[deviceName].includes(portName)) {
                    if (value.ports[0].portName == portName) {
                        // value.ports[0].isActive = !value.ports[0].isActive

                        if (
                            ['A1', 'B1', 'C1'].includes(portName) &&
                            !sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1')
                        ) {
                            value.ports[0].isActive =
                                props.indexData.graphPlotStorage
                                    .internalaccessories[
                                    disabledPortsConditional[portName]
                                ] == false && !value.ports[0].isActive

                            // toast msg
                            if (
                                props.indexData.graphPlotStorage
                                    .internalaccessories[
                                    disabledPortsConditional[portName]
                                ]
                            ) {
                                props.setToastMsg(
                                    `Disable ${disabledPortsConditional[portName]} to enable ${portName}`
                                )
                                props.setToastActive(true)
                                props.disableToast()
                            }
                        } else {
                            value.ports[0].isActive = !value.ports[0].isActive
                        }
                        sessionStorage.setItem(
                            'portsData',
                            JSON.stringify(portsData)
                        )
                    }

                    if (value.ports[1].portName == portName) {
                        if (
                            ['A2', 'B2', 'C2'].includes(portName) &&
                            !sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1')
                        ) {
                            value.ports[1].isActive =
                                props.indexData.graphPlotStorage
                                    .internalaccessories[
                                    disabledPortsConditional[portName]
                                ] == false && !value.ports[1].isActive

                            // toast msg
                            if (
                                props.indexData.graphPlotStorage
                                    .internalaccessories[
                                    disabledPortsConditional[portName]
                                ]
                            ) {
                                props.setToastMsg(
                                    `Disable ${disabledPortsConditional[portName]} to enable ${portName}`
                                )
                                props.setToastActive(true)
                                props.disableToast()
                            }
                        } else {
                            value.ports[1].isActive = !value.ports[1].isActive
                        }
                        sessionStorage.setItem(
                            'portsData',
                            JSON.stringify(portsData)
                        )
                        // sessionStorage.setItem('portsData', JSON.stringify(portsData))
                    }
                } else {
                    props.setToastMsg('Ports Disabled')
                    props.setToastActive(true)
                    props.disableToast()
                }
            })
        } else {
            Object.values(newData).forEach((value, index) => {
                if (
                    !disabledPortsPermanent[deviceName].includes(portName) &&
                    ['Humanoid', 'Hexapod', 'Roboki'].includes(connectedDevice)
                ) {
                    if (value.ports[0].portName == portName) {
                        value.ports[0].isActive = !value.ports[0].isActive
                        sessionStorage.setItem(
                            'portsData',
                            JSON.stringify(portsData)
                        )
                        if (value.ports[0].isActive)
                            props.setToastMsg(` Enabled port ${portName}`)
                        else props.setToastMsg(` Disabled port ${portName}`)
                        props.setToastActive(true)
                        props.disableToast()
                    }
                    if (value.ports[1].portName == portName) {
                        value.ports[1].isActive = !value.ports[1].isActive
                        sessionStorage.setItem(
                            'portsData',
                            JSON.stringify(portsData)
                        )
                        if (value.ports[1].isActive)
                            props.setToastMsg(` Enabled port ${portName}`)
                        else props.setToastMsg(` Disabled port ${portName}`)
                        props.setToastActive(true)
                        props.disableToast()
                    }
                } else {
                    props.setToastMsg('Ports Disabled')
                    props.setToastActive(true)
                    props.disableToast()
                }
            })
        }

        props.graphPlotStorage({ portsData: newData })
        setData(newData)
    }

    function nextButtonStatus() {
        let portStatus = data.find((val) => val.ports[0].isActive)

        let sensorStatus = [
            ...Object.values(
                props.indexData.graphPlotStorage.internalaccessories[
                    'Four_in_one_sensor'
                ]
            ),
            ...Object.values(
                props.indexData.graphPlotStorage.internalaccessories
            ).slice(1),
        ].find((sensorValue) => sensorValue === true)

        if (portStatus || sensorStatus) props.setIsActiveNextBtn(true)
        else props.setIsActiveNextBtn(false)
    }

    useEffect(() => {
        // console.log("data",Object.values(props.indexData.graphPlotStorage.internalaccessories))
        nextButtonStatus()
    })

    const handleToggle = (portName) => {
        let newData = [...data]
        // console.log('pppppppppppppppppp', portName)

        Object.values(newData).forEach((value, index) => {
            if (value.ports[0].portName == portName) {
                value.ports[0].isDigital = !value.ports[0].isDigital
                sessionStorage.setItem('portsData', JSON.stringify(portsData))
            }
            if (value.ports[1].portName == portName) {
                value.ports[1].isDigital = !value.ports[1].isDigital
                sessionStorage.setItem('portsData', JSON.stringify(portsData))
            }
        })
        console.log('handletoggle')
        props.graphPlotStorage({ portsData: newData })
        // setToggleD(!toggleD)
        setData(newData)
    }

    const checkdisabled = (portName) => {
        return disabledPortsPermanent[deviceName].includes(portName)
            ? 'default'
            : 'pointer'
    }

    //in Humanoid port "F and E" not present
    //in Roboki only "A and B " port present filter out
    if (
        connectedDevice == 'Humanoid' &&
        (props.data.key == 'F' || props.data.key == 'E')
    ) {
        return <></>
    } else if (
        connectedDevice == 'Roboki' &&
        ['E', 'F', 'C', 'D'].includes(props.data.key)
    ) {
        return <></>
    }
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${renderPrgImage('PortPopUp')})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                    // border: '1px solid red',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        width: '37vh',
                        // border: '1px solid blue',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        display: 'grid',
                        gridTemplateColumns: '30% 70%',
                        gridTemplateRows: '50% 50%',
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        {props.data.ports[0].isActive == true ? (
                            <>
                                <img
                                    style={{
                                        width: '65%',
                                        cursor: checkdisabled(
                                            props.data.ports[0].portName
                                        ),
                                    }}
                                    src={renderPrgImage('orangeBtn')}
                                    onClick={() =>
                                        handleImage(
                                            props.data.ports[0].portName
                                        )
                                    }
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(101%,-168%)',
                                        fontSize: '2vh',
                                        pointerEvents: 'none',
                                        color: 'white',
                                    }}
                                >
                                    {props.data.ports[0].portName}
                                </div>
                            </>
                        ) : (
                            <>
                                <img
                                    style={{
                                        width: '65%',
                                        cursor: checkdisabled(
                                            props.data.ports[0].portName
                                        ),
                                    }}
                                    src={renderPrgImage('whiteBtn')}
                                    onClick={() =>
                                        handleImage(
                                            props.data.ports[0].portName
                                        )
                                    }
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(101%,-168%)',
                                        fontSize: '2vh',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {props.data.ports[0].portName}
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            cursor: checkdisabled(props.data.ports[0].portName),
                        }}
                    >
                        {' '}
                        <Toggle
                            portName={props.data.ports[0].portName}
                            handleToggle={handleToggle}
                            isDigital={props.data.ports[0].isDigital}
                            isActive={props.data.ports[0].isActive}
                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        {props.data.ports[1].isActive == true ? (
                            <>
                                <img
                                    style={{
                                        width: '65%',
                                        color: 'white',
                                        cursor: checkdisabled(
                                            props.data.ports[0].portName
                                        ),
                                    }}
                                    src={renderPrgImage('orangeBtn')}
                                    onClick={() =>
                                        handleImage(
                                            props.data.ports[1].portName
                                        )
                                    }
                                />

                                <div
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(101%,-168%)',
                                        fontSize: '2vh',
                                        pointerEvents: 'none',
                                        color: 'white',
                                    }}
                                >
                                    {props.data.ports[1].portName}
                                </div>
                            </>
                        ) : (
                            <>
                                <img
                                    style={{
                                        width: '65%',
                                        cursor: checkdisabled(
                                            props.data.ports[0].portName
                                        ),
                                    }}
                                    src={renderPrgImage('whiteBtn')}
                                    onClick={() =>
                                        handleImage(
                                            props.data.ports[1].portName
                                        )
                                    }
                                />

                                <div
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(101%,-168%)',
                                        fontSize: '2vh',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {props.data.ports[1].portName}
                                </div>
                            </>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: checkdisabled(props.data.ports[0].portName),
                        }}
                    >
                        <Toggle
                            portName={props.data.ports[1].portName}
                            handleToggle={handleToggle}
                            isDigital={props.data.ports[1].isDigital}
                            isActive={props.data.ports[1].isActive}
                        />
                    </div>
                </div>
            </div>

            {disabledMsg && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '5%',
                        minWidth: '150px',
                        marginLeft: '-125px',
                        backgroundColor: '#d2d2d2',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '25px',
                        fontFamily: 'Halcyon_Regular',
                        padding: '16px',
                        left: '50%',
                        fontSize: '20px',
                        zIndex: 1,
                    }}
                >
                    Port D is disabled
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },

        // webSerialConnect: (data) => {
        //     dispatch(webSerialConnect(data))
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Ports)
