import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import {
    deviceDetails,
    deviceMismatch,
    isConnecting,
    showModal,
    webSerialConnect,
    webSerialDisconnect,
} from '../../redux/actions'
import {
    connectSerialDevice,
    connectSerialDeviceInitial,
    connectUSBDevice,
    openSerialReadComPort,
    openUSBReadComPort,
    closeReadComPort,
    connectSerialDeviceIntermediate,
    connectSerialDeviceOnRefresh,
} from './SerialUSBConnect'
import { resolve } from 'path'

function WebSerialConnection({
    webSerial,
    webSerialConnect,
    webSerialDisconnect,
    showModal,
    worker,
    device,
    deviceDetails,
    deviceMismatch,
    isConnecting,
}) {
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    // const zingVersion =
    //     sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const history = useHistory()
    // Event listeners for (dis)connect ports
    // Navigator's connect and disconnect events are fired when a device is connected to / disconnected from the User System
    // and the appropriate callbacks are triggered.
    //
    // window's focus and blur events are fired when an user comes in / goes out of focus from the CURRENT TAB.
    // Using these two event we get to know if the user is viewing the current tab or not.
    useEffect(() => {
        try {
            navigator.serial.addEventListener('connect', connect)
            navigator.serial.addEventListener('disconnect', disConnect)

            window.addEventListener('load', connectOnRefresh)
            window.addEventListener('focus', windowOnFocus)
            window.addEventListener('blur', windowOnBlur)
            window.addEventListener('beforeunload', () => {
                localStorage.removeItem('plodeDeviceIsConnected')
            })

            return () => {
                navigator.serial.removeEventListener('connect', connect)
                navigator.serial.removeEventListener('disconnect', disConnect)

                window.removeEventListener('load', connectOnRefresh)
                window.removeEventListener('focus', windowOnFocus)
                window.removeEventListener('blur', windowOnBlur)
            }
        } catch (e) {}
    }, [])

    const windowOnFocus = () => {
        console.log('window on focus', window.location.pathname)
        sessionStorage.setItem('isWindowOnFocus', true)
        let path = window.location.pathname
        // if (path === '/deviceSelection') connect(event)
        //connect();
    }

    const windowOnBlur = () => {
        console.log('window out of focus')
        sessionStorage.setItem('isWindowOnFocus', false)
    }

    // Re-establishes connection on reloads
    const connectOnRefresh = async () => {
        let path = window.location.pathname
        if (path.includes('login') || path === '/') return
        if (sessionStorage.getItem('isDeviceConnected') === 'false') return

        let res = await connectSerialDeviceOnRefresh(
            { isAutomatic: true },
            worker
        )
        await new Promise((resolve) => setTimeout(resolve, 1000))

        if (!res) return
        if (res.isPortOpen) {
            webSerialConnect({ port: res.port })
        }
    }

    const connect = async (event) => {
        try {
            let path = window.location.pathname
            if (window.location.pathname === '/deviceSelection') {
                sessionStorage.setItem('autoCunnection', true)
            }
            if (
                path.includes('login') ||
                path === '/' ||
                path.includes('/kitSelection')
            )
                return
            // await new Promise(resolve => setTimeout(resolve, 200))
            // we establish connection on only that tab which the user is currently viewing.
            const isWindowOnFocus =
                sessionStorage.getItem('isWindowOnFocus') === 'true'
            if (!isWindowOnFocus) return

            let port = null

            port = event.target

            let res
            console.log('trigger autoconnects')
            if (path.includes('deviceSelection')) {
                res = await connectSerialDeviceInitial(
                    { isAutomatic: true },
                    worker
                )
            } else {
                console.log('here')
                res = await connectSerialDeviceIntermediate(
                    { isAutomatic: true },
                    worker
                )
            }

            console.log('ressssds', res)
            // const res = await openSerialReadComPort(port, worker)
            // if (sessionStorage.getItem('deviceVersion') === '1.0.03')
            //     await new Promise((resolve) => setTimeout(resolve, 2300))
            if (res && res.isPortOpen) {
                deviceDetails({ connectedDevice, deviceVersion }) //zingVersion
                webSerialConnect({ port: res.port })
            }
            if (res && !res.isPortOpen) {
                console.log(res)
                if (res.matchedObj_with_Version) {
                    deviceMismatch({
                        deviceMismatched: res.matchedObj_with_Version,
                    })
                    webSerialConnect({ port: res.port })
                } else {
                    deviceMismatch({
                        deviceMismatched: res.allowedDeviceObj,
                    })
                    webSerialConnect({ port: res.port })
                }
            }

            var user = 0
            sessionStorage.setItem('user', JSON.stringify(user))
        } catch (err) {
            console.log('err in connect', err)
            isConnecting(false)
        }

        // window.location.reload();
    }

    worker.onmessage = (e) => {
        if (e.data.type == 'connected' && !e.data.value) {
            webSerialDisconnect()
            console.log('Port disconnected :(')
            var user = 0
            sessionStorage.setItem('user', JSON.stringify(user))
        }
    }
    // Closing the port and Updating the redux.
    const disConnect = async (event) => {
        sessionStorage.setItem('isPlay', false)
        const port = event.target
        console.log('worker', port, worker)
        await closeReadComPort(port, worker)
        webSerialDisconnect()
        isConnecting(false)
        console.log('Port disconnected :(')
        var user = 0
        sessionStorage.setItem('user', JSON.stringify(user))
        // window.location.reload();
    }

    return null
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        webSerialDisconnect: () => {
            dispatch(webSerialDisconnect())
        },

        showModal: (data) => {
            dispatch(showModal(data))
        },
        deviceDetails: (data) => {
            dispatch(deviceDetails(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
        isConnecting: (data) => {
            dispatch({ type: 'CONNECTING', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSerialConnection)
